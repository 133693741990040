import React from "react";
import ChartLine from "./line";

const spanStyle = {
    position: "absolute",
    fontSize: "16px",
    marginTop: "-15px",
    left: "76px",
    color: "#888888",
    fontWeight: "bold",
    textTransform: "uppercase",
};

const container = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
};

export const CompareGraph = (props) => {

    const { cumalative_loss_gain, existing_veg, total_veg, name, lines, mode, polygonArea, regionArea } = props

    let data = {
        "cumulative_loss_gain": cumalative_loss_gain,
        "existing_veg": existing_veg,
        "total_veg": total_veg,
    }

    if (mode === "polygon_area") {
        data = {
            "cumulative_loss_gain": data["cumulative_loss_gain"].map(el => {

                let keys = Object.keys(el)

                let mappedData = {...el}

                keys.forEach(i => {
                    if (i !== "year") {
                        mappedData[i.toString()] = 100*mappedData[i.toString()]/polygonArea
                    }
                })

                return mappedData
            }),
            "existing_veg": data["existing_veg"].map(el => {
                let keys = Object.keys(el)

                let mappedData = {...el}

                keys.forEach(i => {
                    if (i !== "year") {
                        mappedData[i.toString()] = 100*mappedData[i.toString()]/polygonArea
                    }
                })

                return mappedData
            }),
            "total_veg": data.total_veg.map(el => {
                let keys = Object.keys(el)

                let mappedData = {...el}

                keys.forEach(i => {
                    if (i !== "year") {
                        mappedData[i.toString()] = 100*mappedData[i.toString()]/polygonArea
                    }
                })

                return mappedData
            })
        }
    }

    if (mode === "total_area") {
        data = {
            "cumulative_loss_gain": data["cumulative_loss_gain"].map(el => {

                let keys = Object.keys(el)

                let mappedData = {...el}

                keys.forEach(i => {
                    if (i !== "year") {
                        mappedData[i.toString()] = 100*mappedData[i.toString()]/regionArea
                    }
                })

                return mappedData
            }),
            "existing_veg": data["existing_veg"].map(el => {
                let keys = Object.keys(el)

                let mappedData = {...el}

                keys.forEach(i => {
                    if (i !== "year") {
                        mappedData[i.toString()] = 100*mappedData[i.toString()]/regionArea
                    }
                })

                return mappedData
            }),
            "total_veg": data.total_veg.map(el => {
                let keys = Object.keys(el)

                let mappedData = {...el}

                keys.forEach(i => {
                    if (i !== "year") {
                        mappedData[i.toString()] = 100*mappedData[i.toString()]/regionArea
                    }
                })

                return mappedData
            })
        }
    }

    return (
        <div style={container}>
            <div style={{ marginTop: 70 }}>
                <span style={spanStyle}>Cumulative losses gains</span>
                <ChartLine data={data["cumulative_loss_gain"]} name={name} lines={lines} mode={mode}/>
            </div>

            <div style={{ marginTop: 30 }}>
                <span style={spanStyle}>Baseline vegetation</span>
                <ChartLine data={data["existing_veg"]} name={name} lines={lines} mode={mode}/>
            </div>

            <div style={{ marginTop: 30 }}>
                <span style={spanStyle}>Total vegetation</span>
                <ChartLine data={data["total_veg"]} name={name} lines={lines} mode={mode}/>
            </div>
        </div>
    );
};
