import clsx from "clsx";
import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Graph } from "../GraphComponents/graph.tsx";

import { Button, CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { CompareGraph } from "../GraphComponents/CompareGraph.jsx";
import { IndividualGraph } from "../GraphComponents/IndividualGraph.jsx";
import PDFCompareComponent from "./PDFCompareComponent.jsx";
import PDFComponent from "./PDFComponent.tsx";
import PDFIndividual from "./PDFIndividual.tsx";
import PDFAiSummaryComponent from "./PDFAiSummaryComponent.jsx";
import { generateCSVZip } from "./createCSV.ts";

import AnalyticsService from "../../services/analytics-service.js";

import { a11yProps, parseRawCharts, parsePlotlyCharts, getFilters, getIndCsv, getTotalArea } from "../../helpers/right-sidebar/index.ts";

// const drawerWidth = 1200; // 520 old value

const useStyles = (drawerWidth) =>
    makeStyles((theme) => ({
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: "90%",
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginRight: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            position: "absolute",
            top: 50,
            right: 10,
            zIndex: 1,
            color: "white",
            borderColor: "white",
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            marginLeft: -drawerWidth,
        },
        drawerPaper: {
            width: drawerWidth,
            color: "white",
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            background: "white",
            color: "rgb(65,65,65)",
            padding: theme.spacing(3, 0),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            position: "absolute",
            flexDirection: "column",
            overflowX: "hidden",
            justifyContent: "flex-end",
            fontWeight: "700",
            width: "100%",
        },
        drawerHeaderTitle: {
            color: "rgb(255,3,3)",
            fontWeight: "700",
        },
        active: {
            background: "white !important",
            color: "rgb(252,66,54)",
        },
        paper: {
            borderRadius: "0 !important",
            boxShadow: "none",
        },
        popoverList: {
            padding: theme.spacing(2, 4),
        },
        drawerIcon: {
            position: "absolute",
            zIndex: 100,
            left: "8px",
            top: "84px",
        },
        drawerContent: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
        },
        drawerPrintPdf: {
            margin: "40px 0 0 0",
            display: "flex",
            justifyContent: "center",
        },
        analyseButton: {
            border: "none",
            color: "white",
            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            padding: "6px 16px",
            fontSize: "0.875rem",
            minWidth: "64px",
            boxSizing: "border-box",
            transition:
                "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "500",
            lineHeight: "1.75",
            borderRadius: "4px",
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
            background: "#FC4236",
            "&:hover": {
                background: "#FC4236",
                cursor: "pointer",
            },
            "&:disabled": {
                cursor: "default",
                color: "rgba(0, 0, 0, 0.26)",
                boxShadow: "none",
                backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            "&:active": {
                boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            },
        },
    }))();

// type TTabPanelProps = {
//     children: React.ReactNode;
//     index: number;
//     value: number;
// };

// type TRightSidebarProps = {
//     region: string;
//     open: boolean;
//     handleDrawerOpen: () => void;
//     handleDrawerClose: () => void;
// }

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {value === index && (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const COLORS = ["#5E8F66", "#BA456A", "#3499CF", "#A39B92", "#EF463B", "#8A2040"];

const RightSidebar = React.memo(({ region, open, handleDrawerOpen, handleDrawerClose }) => {
    const theme = useTheme();
    const componentRef = useRef();
    const PDFRef = useRef();
    const AiSummaryPDFRef = useRef();

    const [tab, setTab] = useState(0);

    // THE MODE WILL DETERMINE HOW THE CHART WILL BE SHOWN, MODE CAN BE CHANGED IN THE DROPDOWN
    const [yAxisMode, setYAxisMode] = useState("polygon_area");

    const polygons = useSelector((state) => state.trees.chartsForPDF);
    const treesCharts = useSelector((state) => state.trees.charts);
    const isCompare = useSelector((state) => state.trees.isCompare);
    const compareGraph = useSelector((state) => state.trees.compareGraph);
    const treeInfo = useSelector((state) => state.trees.treeInfo);
    const additionalChartsData = useSelector((state) => state.trees.additionalData);
    const filters = getFilters(useSelector((state) => state.filters));
    const regionInfo = useSelector((state) => state.regions.regionInfo);

    const classes = useStyles(treeInfo ? 850 : 1200);

    const user = useSelector((state) => state.user.user);

    const chartsForPDF = useSelector((state) => state.trees.chartsForPDF);

    let { dataPie, dataLine, dataBar } = parseRawCharts(treesCharts);

    const {
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataVegetationRemovals,
        dataTreeHeights,
        dataWaterfallFoliage,
        dataProfitAndLoss,
        dataProfitAndLossTotal,
        dataChangeInCanopy,
        status,
        lastYear,
        currentCanopy,
        startingCanopy,
        contribution,
        summary,
        changeRatio,
        firstYear,
    } = parsePlotlyCharts(additionalChartsData);

    let agregateGraph = {
        dataPie,
        dataLine,
        dataBar,
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataVegetationRemovals,
        dataTreeHeights,
        dataWaterfallFoliage,
        dataProfitAndLoss,
        dataProfitAndLossTotal,
        dataChangeInCanopy,
    };

    let keys = [];
    let yearsDiap = `All`;
    if (dataLine && dataLine.length) {
        yearsDiap = `${dataLine[0].name} - ${dataLine[dataLine.length - 1].name}`;
    }

    let csvData = [];
    if (treeInfo) {
        csvData = getIndCsv(treeInfo.graph, treeInfo.veg_id);
    }

    if (compareGraph) {
        keys = [];
        //keys = массив линий key - название линии, stroke - цвет линии
        Object.keys(compareGraph.existing_veg[0]).forEach((key, ind) => {
            if (key === "name" || key === "year") {
                return;
            }
            keys.push({
                key,
                stroke: COLORS[ind % COLORS.length],
            });
        });
    }

    const checkIfAllGraps = () => {
        if (
            (dataScatter,
            dataLossGains,
            dataTotalArea,
            dataVegetationRemovals,
            dataTreeHeights,
            dataWaterfallFoliage,
            dataProfitAndLoss,
            dataProfitAndLossTotal,
            dataChangeInCanopy,
            status,
            lastYear,
            currentCanopy,
            startingCanopy,
            contribution,
            summary,
            changeRatio,
            firstYear)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const checkIfAllCompareGraphs = () => {
        if (compareGraph) {
            if (compareGraph.cumalative_loss_gain && compareGraph.existing_veg && compareGraph.total_veg) {
                return true;
            }
        } else {
            return false;
        }
    };

    const handleGenerateCSV = () => {
        generateCSVZip({
            dataLossGains,
            dataScatter,
            dataTotalArea,
            dataTreeHeights,
            dataPie,
            dataChangeInCanopy,
            dataChart: dataLine,
            dataProfitAndLoss,
            dataProfitAndLossTotal,
            dataVegetationRemovals,
            dataWaterfallFoliage,
        });

        AnalyticsService.track("generate_csv", {
            distinct_id: user.id,
            login: user.login,
            role: user.role === 2 ? "admin" : "user",
            region,
        });
    };

    const handleCSV = () => {
        AnalyticsService.track("generate_csv", {
            distinct_id: user.id,
            login: user.login,
            role: user.role === 2 ? "admin" : "user",
            region,
        });
    };

    const handlePDF = () => {
        AnalyticsService.track("generate_pdf", {
            distinct_id: user.id,
            login: user.login,
            role: user.role === 2 ? "admin" : "user",
            region,
        });
    };

    return (
        <>
            {(Boolean(treesCharts) || Boolean(treeInfo)) && (
                <Button onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.hide)} variant="outlined">
                    <ChevronLeftIcon style={{ transform: "scale(1.5)" }} />
                </Button>
            )}

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                // style={{width: "100%"}}
            >
                <div className={classes.drawerHeader}>
                    <IconButton className={classes.drawerIcon} onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>

                    <div className={classes.drawerContent}>
                        {!treeInfo && (
                            <AppBar position="static" color="default" classes={{ root: classes.appBar }}>
                                <Tabs
                                    value={tab}
                                    onChange={(e, v) => setTab(v)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    {isCompare ? <Tab label="Compare" {...a11yProps(1)} /> : <Tab label="Aggregate" {...a11yProps(0)} />}
                                </Tabs>
                            </AppBar>
                        )}

                        <div ref={componentRef}>
                            {treeInfo ? (
                                <IndividualGraph {...treeInfo} />
                            ) : (
                                <>
                                    {isCompare === false && (
                                        <TabPanel value={tab} index={0}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Select
                                                    value={yAxisMode}
                                                    onChange={(e) => {
                                                        setYAxisMode(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={"polygon_area"}>% of Polygon Area</MenuItem>
                                                    <MenuItem value={"sqm"}>Square Meters</MenuItem>
                                                    <MenuItem value={"total_area"}>% of Total Region Area</MenuItem>
                                                </Select>
                                            </div>

                                            {treesCharts && checkIfAllGraps() === true ? (
                                                <Graph
                                                    dataChart={dataLine}
                                                    dataPie={dataPie}
                                                    dataBar={dataBar}
                                                    dataScatter={dataScatter}
                                                    dataLossGains={dataLossGains}
                                                    dataTotalArea={dataTotalArea}
                                                    dataVegetationRemovals={dataVegetationRemovals}
                                                    dataTreeHeights={dataTreeHeights}
                                                    dataWaterfallFoliage={dataWaterfallFoliage}
                                                    dataProfitAndLoss={dataProfitAndLoss}
                                                    dataProfitAndLossTotal={dataProfitAndLossTotal}
                                                    dataChangeInCanopy={dataChangeInCanopy}
                                                    status={status}
                                                    lastYear={lastYear}
                                                    currentCanopy={currentCanopy}
                                                    startingCanopy={startingCanopy}
                                                    contribution={contribution}
                                                    mode={yAxisMode}
                                                    polygonArea={polygons && getTotalArea(polygons)}
                                                    regionArea={regionInfo.area.value}
                                                    polygon={polygons}
                                                    summary={summary}
                                                    changeRatio={changeRatio}
                                                    firstYear={firstYear}
                                                />
                                            ) : (
                                                <CircularProgress style={{ marginTop: "30px" }} />
                                            )}
                                        </TabPanel>
                                    )}

                                    {isCompare && (
                                        <TabPanel value={tab} index={0}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Select
                                                    value={yAxisMode}
                                                    onChange={(e) => {
                                                        setYAxisMode(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={"polygon_area"}>% of Polygon Area</MenuItem>
                                                    <MenuItem value={"sqm"}>Square Meters</MenuItem>
                                                    <MenuItem value={"total_area"}>% of Total Region Area</MenuItem>
                                                </Select>
                                            </div>
                                            {compareGraph && checkIfAllCompareGraphs() === true ? (
                                                <CompareGraph
                                                    cumalative_loss_gain={compareGraph.cumalative_loss_gain}
                                                    existing_veg={compareGraph.existing_veg}
                                                    total_veg={compareGraph.total_veg}
                                                    name={"year"}
                                                    lines={keys}
                                                    polygonArea={polygons && getTotalArea(polygons)}
                                                    mode={yAxisMode}
                                                    regionArea={regionInfo.area.value}
                                                />
                                            ) : (
                                                <CircularProgress style={{ marginTop: "30px" }} />
                                            )}
                                        </TabPanel>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={classes.drawerPrintPdf}>
                        {!treeInfo ? (
                            <>
                                <ReactToPrint
                                    trigger={() => (
                                        <button id="print_butt" className={classes.analyseButton} color="secondary" variant="contained">
                                            Print to PDF
                                        </button>
                                    )}
                                    content={() => {
                                        handlePDF();
                                        return PDFRef.current;
                                    }}
                                />

                                {tab === 0 && isCompare === false && (
                                    <Button onClick={handleGenerateCSV} style={{ marginLeft: 20 }} color="primary" variant="contained">
                                        Export CSV
                                    </Button>
                                )}

                                {tab === 0 && isCompare === false && (
                                    <ReactToPrint
                                        trigger={() => (
                                            <button
                                                id="print_butt_ai"
                                                disabled={summary ? false : true}
                                                className={classes.analyseButton}
                                                color="secondary"
                                                variant="contained"
                                                style={{ marginLeft: 20 }}
                                            >
                                                Print AI summary
                                            </button>
                                        )}
                                        content={() => {
                                            // handlePDF();
                                            return AiSummaryPDFRef.current;
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <ReactToPrint
                                    trigger={() => (
                                        <button id="print_butt_ind" className={classes.analyseButton} color="secondary" variant="contained">
                                            Print to PDF
                                        </button>
                                    )}
                                    content={() => {
                                        handlePDF();
                                        return PDFRef.current;
                                    }}
                                />
                                <CSVLink data={csvData} filename={`${region}_${treeInfo.veg_id}.csv`}>
                                    <Button onClick={handleCSV} style={{ marginLeft: 20 }} color="primary" variant="contained">
                                        Export CSV
                                    </Button>
                                </CSVLink>
                            </>
                        )}
                        {!treeInfo ? (
                            chartsForPDF?.length > 1 && checkIfAllCompareGraphs() === true && isCompare && compareGraph ? (
                                <PDFCompareComponent
                                    ref={PDFRef}
                                    years={yearsDiap}
                                    compareGraph={compareGraph}
                                    keys={keys}
                                    agregateGraph={agregateGraph}
                                    region={region}
                                    filters={filters}
                                    parseRawCharts={parseRawCharts}
                                    chartsForPDF={chartsForPDF}
                                    mode={yAxisMode}
                                    polygonArea={polygons && getTotalArea(polygons)}
                                    regionArea={regionInfo && regionInfo.area.value}
                                />
                            ) : (
                                <>
                                    {checkIfAllGraps() === true && isCompare === false && (
                                        <PDFComponent
                                            agregateGraph={agregateGraph}
                                            years={yearsDiap}
                                            ref={PDFRef}
                                            region={region}
                                            filters={filters}
                                            chartsForPDF={chartsForPDF}
                                            mode={yAxisMode}
                                            polygonArea={polygons && getTotalArea(polygons)}
                                            regionArea={regionInfo && regionInfo.area.value}
                                        />
                                    )}

                                    {chartsForPDF && summary && isCompare === false && (
                                        <PDFAiSummaryComponent
                                            ref={AiSummaryPDFRef}
                                            summary={summary}
                                            polygonArea={polygons && getTotalArea(polygons)}
                                            regionArea={regionInfo && regionInfo.area.value}
                                            region={region}
                                            years={yearsDiap}
                                            filters={filters}
                                            chartsForPDF={chartsForPDF}
                                        />
                                    )}
                                </>
                            )
                        ) : (
                            treeInfo && (
                                <PDFIndividual treeInfo={treeInfo} region={region} filters={filters} chartsForPDF={chartsForPDF} ref={PDFRef} />
                            )
                        )}
                    </div>
                </div>
            </Drawer>
        </>
    );
});

export default RightSidebar;
