import { RENDER_STATE } from "react-map-gl-draw";

export function getEditHandleStyle({ feature, state }) {
    switch (state) {
        case RENDER_STATE.SELECTED:
        case RENDER_STATE.HOVERED:
        case RENDER_STATE.UNCOMMITTED:
            return {
                fill: "rgb(251, 176, 59)",
                fillOpacity: 1,
                stroke: "rgb(255, 255, 255)",
                strokeWidth: 2,
                r: 7,
            };

        default:
            if (feature.properties.hasOwnProperty("isVisible") && !feature.properties.isVisible) {
                return {
                    fillOpacity: 0,
                    strokeWidth: 0,
                    r: 0,
                };
            } else {
                return {
                    fill: "rgb(251, 176, 59)",
                    fillOpacity: 1,
                    stroke: "rgb(255, 255, 255)",
                    strokeWidth: 2,
                    r: 5,
                };
            }
    }
}

export function getFeatureStyle({ feature, state }) {
    switch (state) {
        case RENDER_STATE.UNCOMMITTED:
        case RENDER_STATE.CLOSING:
        case RENDER_STATE.SELECTED:
        case RENDER_STATE.HOVERED:
            if (feature.properties.hasOwnProperty("isVisible") && !feature.properties.isVisible) {
                return {
                    fillOpacity: 0,
                    strokeWidth: 0,
                };
            } else {
                return {
                    stroke: "rgb(251, 176, 59)",
                    strokeWidth: 2,
                    fill: "rgb(251, 176, 59)",
                    fillOpacity: 0.2,
                    strokeDasharray: "4,2",
                };
            }

        default:
            if (!feature.properties.isVisible) {
                return {
                    fillOpacity: 0,
                    strokeWidth: 0,
                };
            } else if (feature.properties.isSelected) {
                return {
                    stroke: "rgb(251, 176, 59)",
                    strokeWidth: 2,
                    fill: "rgb(251, 176, 59)",
                    fillOpacity: 0.2,
                    strokeDasharray: "4,2",
                };
            } else if (feature.properties.isActive) {
                return {
                    fillOpacity: 0,
                    stroke: "rgb(231,159,14, 0.7)",
                    strokeWidth: 2,
                };
            }
            return {
                fillOpacity: 0,
                stroke: "rgb(231,159,14, 0.7)",
                strokeWidth: 2,
            };
    }
}
