import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { LoginForm, Table } from "../../Modules";
import { register, getUsers, deleteUser } from "../../Redux/UserReducer.ts";
import RegionComponent from "../../Components/RegionComponent";
import UsersAnalytics from "./UsersAnalytics.tsx";
import { useTypedSelector } from "../../hooks/useTypedSelector.ts";
import { Link } from "react-router-dom";

const headCells = [
    { id: "login", numeric: false, disablePadding: true, label: "Login" },
    { id: "password", numeric: false, disablePadding: true, label: "Password" },
    { id: "role", numeric: true, disablePadding: false, label: "Role" },
    { id: "access_regions", label: "Permissions" },
    { id: "createdAt", numeric: true, disablePadding: false, label: "Created" },
];

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    paper: {
        maxWidth: 460,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
    },
}));

const getParsedDate = (time: string) => {
    let date = new Date(time);
    let y = date.getFullYear(),
        mo: string | number = date.getMonth() + 1,
        d: string | number = date.getDate(),
        h: string | number = date.getHours(),
        m: string | number = date.getMinutes();

    if (mo < 10) {
        mo = "0" + mo;
    }
    if (d < 10) {
        d = "0" + d;
    }
    if (h < 10) {
        h = "0" + h;
    }
    if (m < 10) {
        m = "0" + m;
    }

    return `${d}.${mo}.${y} ${h}:${m}`;
};

const ChangeModeButtonsData = [
    {
        mode: "regions",
        title: "Regions",
    },
    {
        mode: "users",
        title: "Users",
    },
    {
        mode: "analytics",
        title: "Analytics",
    },
];

type TMode = "regions" | "users" | "analytics";

const AnalystProfile = () => {
    const classes = useStyles();
    const { user, users, countUsers } = useTypedSelector((state) => state.user);
    const [displayMode, setDisplayMode] = useState<TMode>("regions");
    const onSubmit = (login: string, pass: string) => {
        dispatch(register(login, pass));
    };
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    let data: any[] = [];

    users.forEach((u: any) =>
        data.push({
            ...u,
            role: u.role === 2 ? "Admin" : "User",
            createdAt: getParsedDate(u.createdAt),
        })
    );

    useEffect(() => {
        const mode = window.location.pathname.split("/profile/")[1];
        if (mode === "regions") {
            setDisplayMode("regions");
        }
        if (mode === "users") {
            setDisplayMode("users");
        }
        if (mode === "analytics") {
            setDisplayMode("analytics");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    return (
        <div style={{ overflow: "hidden" }}>
            {user.role === 2 && (
                <Container maxWidth="sm" component="main" className={classes.heroContent}>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        {ChangeModeButtonsData.map((button) => {
                            return (
                                <Link to={`/profile/${button.mode}`} key={button.mode}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={displayMode === button.mode}
                                        // onClick={() => setDisplayMode(button.mode as TMode)}
                                        style={{ width: "200px", height: "100px" }}
                                        // key={button.mode}
                                    >
                                        {button.title}
                                    </Button>
                                </Link>
                            );
                        })}
                    </ButtonGroup>
                </Container>
            )}

            {displayMode === "regions" && user.role === 2 && (
                <Container maxWidth="sm" component="main" className={classes.heroContent}>
                    <RegionComponent />
                </Container>
            )}

            {displayMode === "users" && user.role === 2 && (
                <Container maxWidth="lg">
                    <Table
                        headCells={headCells}
                        rows={data as any}
                        page={page}
                        setPage={setPage}
                        rowsLength={countUsers}
                        getUsers={getUsers}
                        deleteUser={deleteUser}
                        tableName="Analysts"
                        meta={undefined}
                    />
                </Container>
            )}

            {displayMode === "users" && user.role === 2 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography component="h1" variant="h5" align="center">
                            Register user
                        </Typography>
                        <LoginForm name="Register" onSubmit={onSubmit} />
                    </Paper>
                </div>
            )}
            {displayMode === "analytics" && user.role === 2 && <UsersAnalytics />}
        </div>
    );
};

export default AnalystProfile;
