import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import case_study from "../assets/img/Case_Study.jpg";

const DoubleBlock = () => {
    return (
        <Main container>
            <LeftBlock item xs={12} md={6}>
                <h1>On-demand insights</h1>
                <p>Each subscription includes multi-user access and unlimited requests from your Tree Ledger database</p>
            </LeftBlock>

            <RightBlock item xs={12} md={6}>
                <img src={case_study} alt="case study" />
            </RightBlock>
        </Main>
    );
};

const Main = styled(Grid)`
    margin: 60px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const LeftBlock = styled(Grid)`
    padding: 50px;
    font-size: 17px;
    h1 {
        font-weight: 1000;
        font-size: 45px;
        margin: 0;
        @media (max-width: 400px) {
            font-size: 38px;
        }
    }
    p {
        font-size: 21px;
        @media (max-width: 400px) {
            font-size: 18px;
            line-height: 32px;
        }
    }
    @media (max-width: 942px) {
        padding: 40px 0;
    }
`;

const RightBlock = styled(Grid)`
    padding: 0 40px 0 40px;

    & img {
        width: 100%;
        max-height: 400px;
        aspect-ratio: 21/9;
    }
    @media (max-width: 942px) {
        padding: 0;
    }
`;

export default DoubleBlock;
