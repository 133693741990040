import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useRef, useState } from "react";
import MapGL, { Marker, NavigationControl, Popup } from "react-map-gl";
import { useDispatch } from "react-redux";
import { updateRegion } from "../Redux/RegionReducer";
import { MAPBOX_TOKEN } from "../config";

const useStyles = makeStyles(() => ({
    main: {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
    },
}));

const navStyle = {
    bottom: 30,
    right: 0,
    padding: "10px",
};

const Pin = () => {
    const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

    return (
        <svg
            height={20}
            viewBox="0 0 24 24"
            style={{
                cursor: "pointer",
                fill: "#d00",
                stroke: "none",
                transform: `translate(${-20 / 2}px,${-20}px)`,
            }}
            onClick={() => 1}
        >
            <path d={ICON} />
        </svg>
    );
};

const RegionMapSelect = ({ regionId, position = { latitude: 0, longitude: 0 } }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [viewport, setViewport] = useState({
        longitude: position.longitude || 145,
        latitude: position.latitude || -37.78,
        zoom: position.longitude ? 10 : 0,
    });
    const [isFetching, setIsFetching] = useState(false);
    const [placeInfo, setPlaceInfo] = useState(position.latitude ? position : null);

    const mapRef = useRef(null);

    const handleClick = (e) => {
        if (e.target.tagName === "BUTTON" || e.target.tagName === "SPAN") {
            return;
        }
        setPlaceInfo({
            longitude: e.lngLat[0],
            latitude: e.lngLat[1],
        });
    };

    const handleSelectPosition = async () => {
        setIsFetching(true);
        await dispatch(
            updateRegion(regionId, {
                position: placeInfo,
            })
        );
        setIsFetching(false);
    };

    return (
        <div className={classes.main}>
            <MapGL
                {...viewport}
                width={`96%`}
                height="300px"
                mapStyle="mapbox://styles/mapbox/dark-v10?optimize=true"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                onViewportChange={setViewport}
                ref={mapRef}
                onClick={handleClick}
            >
                {placeInfo && (
                    <>
                        <Marker longitude={placeInfo.longitude} latitude={placeInfo.latitude}>
                            <Pin />
                        </Marker>

                        <Popup
                            tipSize={5}
                            anchor="top"
                            longitude={placeInfo.longitude}
                            latitude={placeInfo.latitude}
                            closeOnClick={false}
                            onClose={setPlaceInfo}
                        >
                            <div>
                                <div>Longitude: {placeInfo.longitude.toFixed(6)}</div>
                                <div>Latitude: {placeInfo.latitude.toFixed(6)}</div>
                                {isFetching ? (
                                    <CircularProgress style={{ width: "30px", height: "30px" }} />
                                ) : (
                                    position.longitude !== placeInfo.longitude && (
                                        <Button
                                            onClick={handleSelectPosition}
                                            style={{ marginTop: "10px" }}
                                            color="secondary"
                                            variant="contained"
                                            size="small"
                                        >
                                            Select
                                        </Button>
                                    )
                                )}
                            </div>
                        </Popup>
                    </>
                )}

                <NavigationControl style={navStyle} />
            </MapGL>
        </div>
    );
};

export default RegionMapSelect;
