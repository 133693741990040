import React, { useState } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { Button, Fade, Menu, MenuItem } from "@material-ui/core";

import DialogSession from "./DialogSession";
import LeftSidebarMenu from "./LeftSidebarMenu";
import { logout } from "../../Redux/UserReducer.ts";

import logo from "../../assets/static/logo.png";

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        position: "absolute",
        top: 50,
        left: 10,
        zIndex: 1,
        color: "white",
        borderColor: "white",
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: "#F1F1F1",
        border: "none",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        background: "rgb(252,66,54)",
        color: "white",
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "center",
        fontWeight: "700",
        textDecoration: "none",
        height: "auto",
    },
    drawerHeaderTitle: {
        width: "100%",
        "& img": {
            width: "100%",
        },
    },
    header_plus: {
        color: "#BF0000",
        position: "absolute",
        top: "29px",
        right: "19px",
    },
    drawerDesc: {
        color: "rgb(252,66,54)",
        fontWeight: "700",
        fontSize: "11px",
        padding: "10px 6px",
        background: "white",
    },
    sessions: {
        position: "absolute",
        bottom: "10px",
        width: "100%",
    },
    sessions__text: {
        width: "100%",
        textAlign: "center",
        fontSize: "13px",
        fontWeight: "500",
        padding: "2px 0",
    },
    badge: {
        position: "absolute",
        right: "15px",
        top: "1px",
        width: "18px",
        fontSize: "12px",
        color: "white",
        fontWeight: "400 !important",
        background: "rgb(252,66,54)",
        borderRadius: "50%",
        textAlign: "center",
    },
}));

const LeftSidebar = React.memo(({ editor, open, region, handleDrawerOpen, handleDrawerClose, handleRightDrawerOpen, setViewport }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const sessions = useSelector((state) => state.sessions.mySessions);
    const user = useSelector((state) => state.user.user);
    const polygons = Boolean(useSelector((state) => state.filters.polygonsLoaded));
    const regionInfo = useSelector((state) => state.regions.regionInfo);

    if (!polygons || !regionInfo) {
        return null;
    }

    return (
        <>
            <Button onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.hide)} variant="outlined">
                <ChevronRightIcon style={{ transform: "scale(1.5)" }} />
            </Button>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {/* заглушка от утечки памяти. (нужно переписывать на mapbox-gl) */}
                <a href="/" className={classes.drawerHeader}>
                    <div className={classes.drawerHeaderTitle}>
                        <img src={logo} alt="logo" />
                    </div>
                </a>

                <div className={classes.drawerDesc}>
                    <span>Live Explorer + Analytics Finder</span>
                </div>

                <LeftSidebarMenu
                    region={region}
                    handleDrawerClose={handleDrawerClose}
                    handleRightDrawerOpen={handleRightDrawerOpen}
                    setViewport={setViewport}
                    setModalOpen={setModalOpen}
                    editor={editor}
                />

                <div className={classes.sessions}>
                    <List>
                        <ListItem button onClick={() => setModalOpen("load")}>
                            <div className={classes.sessions__text}>LOAD</div>
                            <div className={classes.badge}>{sessions.length}</div>
                        </ListItem>

                        <ListItem button onClick={() => setModalOpen("save")}>
                            <div className={classes.sessions__text}>SAVE</div>
                        </ListItem>

                        {/*
                            <ListItem button>
                                <div className={classes.sessions__text}>HELP</div>             
                            </ListItem>  
                            */}
                    </List>

                    <Menu
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        anchorEl={anchorEl}
                        keepMounted
                        open={menuOpen}
                        onClose={() => setAnchorEl(null)}
                        TransitionComponent={Fade}
                    >
                        {user.role === 2 ? <MenuItem onClick={() => (window.location.href = "/profile")}>Profile</MenuItem> : null}
                        <MenuItem
                            onClick={() => {
                                setAnchorEl(null);
                                dispatch(logout());
                            }}
                        >
                            Sign out
                        </MenuItem>
                    </Menu>
                </div>
            </Drawer>
            <DialogSession regionId={regionInfo.id} open={modalOpen} setOpen={setModalOpen} sessions={sessions} editor={editor} />
        </>
    );
});

export default LeftSidebar;
