import React from "react";
import { Layer, Source } from "react-map-gl";

const CustomSource = ({ id, isFiltered, data, styles, hide = false }) => {
    if (hide) {
        return null;
    }
    if (!isFiltered) {
        delete styles.filter;
    }
    return (
        <Source id={id} type="geojson" data={data}>
            {!hide && <Layer {...styles} />}
        </Source>
    );
};

export default CustomSource;
