import React from "react";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, Label, ComposedChart, Line } from "recharts";

export default function NetGraph(props) {

    const { data, polygonArea, mode, regionArea } = props

    if (!data) {
        return null;
    }
    // let sum = 0;

    // data.forEach((el) => {
    //     Object.keys(el).forEach((key) => {
    //         if (key === "name") {
    //             return;
    //         }
    //         el[key] = Math.ceil(el[key]);
    //     });
    //     sum += el["Gain"] + el["Loss"];
    //     el["Net"] = sum;
    // });

    let formatedData = data

    if (mode === "polygon_area") {
            formatedData = data.map(el => {
                return {
                    ...el,
                    Gain: (el.Gain/polygonArea)*100,
                    Loss: (el.Loss/polygonArea)*100,
                    Net: (el.Net/polygonArea)*100
                }
            })
    }

    if (mode === "total_area") {
        formatedData = data.map(el => {
            return {
                ...el,
                Gain: (el.Gain/regionArea)*100,
                Loss: (el.Loss/regionArea)*100,
                Net: (el.Net/regionArea)*100
            }
        })
    }

    return (
        <div>
            {data && (
                <ComposedChart
                    width={560}
                    height={500}
                    data={formatedData}
                    stackOffset="sign"
                    margin={{
                        top: 30,
                        right: 10,
                        left: 0,
                        bottom: 55,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis tick={{ fontSize: 12 }} dataKey="name" />
                    <YAxis tick={{ fontSize: 12 }}>
                        <Label
                            content={() => (
                                <>
                                    <text
                                        rotate={0}
                                        x={-155}
                                        y={10}
                                        textAnchor="middle"
                                        style={{ fill: "#3B3B3B", fontSize: 14, transform: "rotate(-90deg)" }}
                                    >
                                        {mode === "sqm" ? "sqm" : "%"}
                                    </text>
                                </>
                            )}
                        />
                    </YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ position: "relative", marginTop: "5px", fontSize: "18px" }} />
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar name="Gains" dataKey="Gain" fill="#6FAC46" />
                    <Bar name="Losses" dataKey="Loss" fill="#C4DFB3" />
                    <Line isAnimationActive={false} type="monotone" dataKey="Net" stroke="#AEAAAA" strokeWidth={2} />
                </ComposedChart>
            )}
        </div>
    );
}
