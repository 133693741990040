import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Main = styled.div`
    margin: 60px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const FeatureMain = styled(Grid)`
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 0px;
`;

const LeftFeatureBlock = styled(Grid)`
    & img {
        float: right;
        width: 100px;
        @media (max-width: 600px) {
            float: left;
        }
    }
`;

const RightFeatureBlock = styled(Grid)`
    font-weight: 500;
    padding-top: 8px;
    padding-left: 20px;
    & h2 {
        color: #ef463b;
        font-size: 28px;
        margin: 0;
    }
    & p {
        font-size: 17px;
        letter-spacing: 0.5px;
        max-width: 450px;
        @media (max-width: 400px) {
            line-height: 24px;
        }
    }
`;

const Feature = ({ title, icon, description }) => {
    return (
        <FeatureMain container>
            <LeftFeatureBlock item xs={12} sm={4}>
                <img src={icon} alt={title} />
            </LeftFeatureBlock>
            <RightFeatureBlock item xs={12} sm={7}>
                <h2>[ {title} ]</h2>
                <p>{description}</p>
            </RightFeatureBlock>
        </FeatureMain>
    );
};

const FeatureList = ({ items }) => {
    if (!items.length) {
        return null;
    }

    return (
        <Main>
            {items.map((item) => (
                <Feature key={item.title} {...item} />
            ))}
        </Main>
    );
};

export default FeatureList;
