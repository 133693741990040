import React from "react";
import styled from "styled-components";

import logo from "../assets/img/logo_2.png";

const Main = styled.div`
    width: 100%;
    height: 100px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img``;

const Header = () => {
    return (
        <Main>
            <a href="https://ppdataanalytics.com/">
                <Logo src={logo} alt="logo" width="160" />
            </a>
        </Main>
    );
};

export default Header;
