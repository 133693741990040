import Plot from "react-plotly.js";

const TreeHeightsChart = (props) => {
    const { data, preview = false, mode, polygonArea, regionArea } = props;

    const modifiedYAxisTitle = (() => {
        if (mode === "polygon_area") {
            return "% of Polygon Area";
        } else if (mode === "total_area") {
            return "% of Region Area";
        } else {
            return "sqm";
        }
    })();

    const modifiedData = (() => {
        if (mode === "polygon_area") {
            return data.shape_area.map((el) => (el / polygonArea) * 100);
        } else if (mode === "total_area") {
            return data.shape_area.map((el) => (el / regionArea) * 100);
        } else {
            return data.shape_area;
        }
    })();

    const modifiedTextArea = (() => {
        if (mode === "polygon_area") {
            return modifiedData.map((el) => el.toFixed(1) + "%");
        } else if (mode === "total_area") {
            return modifiedData.map((el) => el.toFixed(1) + "%");
        } else {
            return data.text_area;
        }
    })();

    const layout = {
        xaxis: {
            tickformat: ".0f",
            tickangle: 90,
            title: modifiedYAxisTitle,
            showline: false,
            showticklabels: false,
            zeroline: false,
        },
        yaxis: { title: "Height Above Ground", showline: false, showgrid: false,  zeroline: false },
        width: (preview === true && 500) || 850,
        height: (preview === true && 350) || 600,
        font: {
            size: 14,
        },
    };

    return (
        <Plot
            data={[
                {
                    x: modifiedData,
                    y: data.li_class,
                    type: "bar",
                    marker: { color: "rgb(37, 150, 190)" },
                    orientation: "h",
                    text: modifiedTextArea,
                },
            ]}
            layout={layout}
            config={{ displaylogo: false }}
        />
    );
};

export default TreeHeightsChart;
