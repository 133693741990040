import { APP_INITIALIZED } from "./types";

let initialState = {
    isInitialized: false,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_INITIALIZED: {
            return {
                ...state,
                isInitialized: action.bool,
            };
        }
        default:
            return state;
    }
};

export const initializeApp = (bool) => ({ type: APP_INITIALIZED, bool });
export default appReducer;
