import React from "react";
import Plot from "react-plotly.js";

const WaterfallFoliage: React.FC<{
    data: {
        p1: number[];
        text: string[];
        base: number;
        x: string[][];
        measure: string[];
        y: (number | "NaN")[];
    };
    mode: "polygon_area" | "total_area" | "sqm";
    polygonArea: number;
    regionArea: number;
    preview: boolean;
    forPDF: boolean;
}> = ({ data, mode, polygonArea, regionArea, preview = false, forPDF = false }) => {
    const modifiedBase = (() => {
        if (mode === "polygon_area") {
            return ((data.base / polygonArea) * 100).toFixed(2);
        } else if (mode === "total_area") {
            return ((data.base / regionArea) * 100).toFixed(2);
        } else {
            return data.base.toFixed(2);
        }
    })();

    const modifiedWaterfallData = (() => {
        if (mode === "polygon_area") {
            return data.y.map((el) => (el !== "NaN" ? ((el / polygonArea) * 100).toFixed(2) : el));
        } else if (mode === "total_area") {
            return data.y.map((el) => (el !== "NaN" ? ((el / regionArea) * 100).toFixed(2) : el));
        } else {
            return data.y.map((el) => (+el).toFixed(2));
        }
    })();

    const modifiedYAxisTitle = (() => {
        if (mode === "polygon_area") {
            return "% of Polygon Area";
        } else if (mode === "total_area") {
            return "% of Region Area";
        } else {
            return "sqm";
        }
    })();

    const modifiedWaterfallText = (() => {
        if (mode === "polygon_area") {
            let text = [...data.text];
            text[text.length - 1] = `<b>${((100 * (data.p1[data.p1.length - 1] - data.p1[0])) / polygonArea).toFixed(2)} %</b>`;

            return text;
        } else if (mode === "total_area") {
            let text = [...data.text];
            text[text.length - 1] = `<b>${((100 * (data.p1[data.p1.length - 1] - data.p1[0])) / regionArea).toFixed(2)} %</b>`;

            return text;
        } else {
            let text = [...data.text];
            text[text.length - 1] = `<b>${(data.p1[data.p1.length - 1] - data.p1[0]).toFixed(2)} sqm</b>`;
            return text;
        }
    })();

    const waterfallData = [
        {
            x: data.x,
            y: modifiedWaterfallData,
            measure: data.measure,
            base: modifiedBase,
            text: modifiedWaterfallText,
            textangle: "auto",
            textposition: "inside",
            type: "waterfall",
            textfont: {
                size: [...new Array(data.text.length - 1).fill(14), 24],
            },
            decreasing: {
                marker: { color: "rgb(227, 66, 52)", line: { color: "red", width: 2 } },
            },
            increasing: {
                marker: { color: "rgb(0, 117, 94)", line: { color: "green", width: 2 } },
            },
            totals: {
                marker: { color: "deep sky blue", line: { color: "blue", width: 1 } },
            },
            showLegend: false,
        },
    ];

    const layout = {
        yaxis: {
            title: modifiedYAxisTitle,
        },
        xaxis: {
            automargin: true,
        },
        font: {
            size: 14,
        },
        width: (preview === true && 570) || (forPDF === true && 890) || 890,
        height: (preview === true && 300) || 750,
        showlegend: false,
        automargin: true,
    };

    return (
        <div>
            <Plot data={waterfallData as any} layout={layout} config={{ displaylogo: false }} />
        </div>
    );
};

export default WaterfallFoliage;
