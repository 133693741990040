import area from "@turf/area";
import centroid from "@turf/centroid";
import React from "react";

import AiIcon from "../../assets/static/ai.svg";
import UserIcon from "../../assets/static/user.svg";

const messageWrapperStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
};

const messageStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "100px",
    color: "#FFFFFF",
    fontSize: "28px",
    boxSizing: "border-box",
    padding: "40px",
    gap: "30px",
};

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

const PDFAiSummaryComponent = React.forwardRef((props, ref) => {
    const { summary, region, years, filters, chartsForPDF } = props;

    const uniqueGraphs = summary.map((el) => el.graph_type).filter(onlyUnique);

    // useEffect(() => {
    //     document.querySelector(`#print_butt_ai`).setAttribute("disabled", "disabled");
    // }, []);

    //pdf_print_block нужен для показа компонента только на генерации PDF

    return (
        <div
            className="pdf_print_block"
            style={{
                /*width: "80%", margin: "60px auto" */
                background: "rgb(72,68,84)",
                color: "#FFFFFF",
            }}
            ref={ref}
        >
            <div
                style={{
                    /*width: "80%", margin: "60px auto" */
                    background: "rgb(72,68,84)",
                }}
            >
                <div style={{ boxSizing: "border-box", padding: "40px" }}>
                    <strong>
                        <h3>YOUR AI CONVERSATION REPORT</h3>
                    </strong>
                    <p>
                        Conversation Made {new Date().toDateString()} from <b>{region}</b> using LEAF
                    </p>
                    <p>AI Summarized Data of Polython</p>
                    <p>
                        <b>Name:</b> {chartsForPDF.map((c) => c.title).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Location:</b> {chartsForPDF.map((c) => `[${centroid(c.elem).geometry.coordinates}]`).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Area:</b>{" "}
                        {chartsForPDF.map((c, ind) => (
                            <span key={ind} style={{ marginLeft: 5 }}>
                                {Math.round(area(c.elem))} m<sup>2</sup>
                            </span>
                        ))}{" "}
                    </p>

                    <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                            <b>Filters:</b>
                        </div>
                        <div>
                            <div>
                                Heights:{" "}
                                {typeof filters.height === "string"
                                    ? filters.height
                                    : `${filters.height[0] === -1 ? 0 : filters.height[0]} - ${filters.height[1]} m`}
                            </div>
                            <div>Years: {years}</div>
                            <div>Land use: {filters.zones.join(", ")}</div>
                        </div>
                    </div>

                    <hr></hr>
                </div>

                <div>
                    {uniqueGraphs.map((el) => {
                        return (
                            <div key={el}>
                                {/* <strong>
                                    <h3>{el}</h3>
                                </strong> */}

                                {summary.map((message) => {
                                    return message.graph_type === el ? (
                                        <div id={Math.random()} style={messageWrapperStyle} key={() => Math.random()}>
                                            <div style={{ ...messageStyle, background: "rgba(52,53,65,255)" }}>
                                                <img src={UserIcon} width="36" alt="user" />
                                                <p>{message.question}</p>
                                            </div>
                                            <div style={{ ...messageStyle, background: "rgb(72,68,84)" }}>
                                                <img src={AiIcon} width="36" alt="ai" />

                                                <p>{message.text}</p>
                                            </div>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        );
                    })}
                </div>

                {/* <div style={{ position: "fixed", bottom: 0 }}>
                    Commercial-in-confidence | Player Piano Data Analytics | E: lab@ppdataanalytics.com | W: https://ppdataanalytics.com
                </div> */}
            </div>
        </div>
    );
});

export default PDFAiSummaryComponent;
