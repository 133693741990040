import { createTheme } from "@material-ui/core";

const theme = createTheme({
    palette: {
        error: {
            main: "#F45D3E",
            contrastText: "#fff",
        },
        secondary: {
            main: "#FC4236",
            contrastText: "#fff",
        },
        transparent: {
            default: "#fff",
        },
        text: {
            primary: "#000000",
        },
    },
});

export default theme;
