import * as React from "react";
import area from "@turf/area";
import { useDispatch, useSelector } from "react-redux";
import { getTrees } from "../../Redux/TreesReducer";
import { Button, IconButton, TextField } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import Edit from "../../assets/static/edit.svg";
import Save from "../../assets/static/save.svg";

const styles = {
    fontSize: "16px",
    lineHeight: "18px",
    minWidth: "180px",
    maxWidth: "230px",
    margin: "10px",
    borderRadius: "3px",
    outline: "none",
    cursor: "auto",
};

const flexDiv = {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
};

const analyseButton = {
    background: "#FC4236",
    "&:hover": {
        background: "#FC4236",
    },
};

const removeButton = {
    background: "#797979",
    "&:hover": {
        background: "#797979",
    },
};

function ControlPanel(props) {
    const dispatch = useDispatch();

    const polygon = props.polygon;
    const polygonArea = polygon && area(polygon);

    const [fakeProgress, setFakeProgress] = React.useState(0);
    const [edit, setEdit] = React.useState(false);
    const [editText, setEditText] = React.useState(polygon.properties.title || "Shape " + props.shapeTitle);

    const fetchingProgress = useSelector((state) => state.trees.fetchingProgress);

    if (props.isFetching) {
        if (fetchingProgress <= fakeProgress) {
            setTimeout(() => setFakeProgress(fakeProgress + 1), 750);
        }
    }

    const handleGetTrees = async () => {
        let result = await dispatch(getTrees(polygon));
        props.onHandleEnd(result);
    };

    const handleChangeShapeName = async () => {
        polygon.properties.title = editText;
        setEdit(false);
    };

    if (!polygonArea) {
        return "";
    }

    return (
        <div style={styles}>
            {!edit ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h3 style={{ wordBreak: "break-word" }}>{polygon.properties.title}</h3>
                    <IconButton size="small" onClick={() => setEdit(true)}>
                        {/* <Icon style={{ color: "orange", fontSize: "inherit" }}>edit</Icon> */}
                        <img src={Edit} width="18" alt="edit" />
                    </IconButton>
                </div>
            ) : (
                <div style={{ display: "flex" }}>
                    <TextField value={editText} onChange={(e) => setEditText(e.target.value)} />
                    <IconButton onClick={handleChangeShapeName} variant="contained" color="primary" size="small">
                        {/* <Icon>save</Icon>' */}
                        <img src={Save} width="18" alt="save" />
                    </IconButton>
                </div>
            )}

            {polygon && (
                <>
                    <span>
                        Area: {polygonArea.toFixed(2)} m<sup>2</sup>
                    </span>
                    <br />
                    {polygon.properties.radius && (
                        <>
                            <span>Radius: {polygon.properties.radius} m</span>
                            <br />
                        </>
                    )}

                    <div style={flexDiv}>
                        {props.isFetching ? (
                            <LinearProgress
                                style={{ height: "12px", width: "100%" }}
                                variant="determinate"
                                value={fetchingProgress > fakeProgress ? fetchingProgress : fakeProgress}
                            />
                        ) : (
                            <>
                                <Button style={removeButton} onClick={props.onHandleDelete} size="small" variant="contained" color="primary">
                                    Remove
                                </Button>
                                <Button style={analyseButton} onClick={handleGetTrees} size="small" variant="contained" color="primary">
                                    Analyse
                                </Button>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default React.memo(ControlPanel);
