/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Collapse, List, ListItem, ListItemText, TextField, Select, MenuItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRegion, downloadFile, getRegions, removeRegion, updateRegion, uploadFile, fillRegionDatabase } from "../Redux/RegionReducer";

// import { NavLink } from "react-router-dom";
import RegionMapSelect from "./RegionMapSelect";

import Document from "../assets/static/document.svg";

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const replaceWithUnderscores = (str) => {
    return str.replace(/\s/g, "_");
};

const FILE_TYPES = [
    {
        title: "Polygons",
        field: "polygons",
        color: "#68A673",
    },
    {
        title: "Gains",
        field: "gains",
        color: "#2EC7E0",
    },
    {
        title: "Losses",
        field: "losses",
        color: "#FC4236",
    },
    {
        title: "Foliage gain",
        field: "foliage_gain",
        color: "#2EC7E0",
    },
    {
        title: "Foliage loss",
        field: "foliage_loss",
        color: "#FC4236",
    },
];

const getFileName = (region, field) => {
    if (field === "polygons") {
        return region;
    }
    return region + "-" + field;
};

const RegionComponent = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRegions());
    }, [dispatch]);

    const regions = useSelector((state) => state.regions.regions);
    const uploadProgress = useSelector((state) => state.regions.uploadProgress);
    const files = useSelector((state) => state.regions.files);

    const [selectedItem, setSelectedItem] = React.useState(null);
    const [selectedUploadItem, setSelectedUploadItem] = React.useState(-1);
    const [selectedDownloadingItem, setSelectedDownloadingItem] = React.useState(-1);

    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalRegion, setModalRegion] = React.useState(null);
    const [fieldEdit, setFieldEdit] = React.useState(null);

    const [fieldEditText, setFieldEditText] = React.useState("");
    const [inputRegionTitle, setInputRegionTitle] = React.useState("");

    const [regionArea, setRegionArea] = React.useState(1);

    const [selectedFile, setSelectedFile] = React.useState({});

    const handleAddRegion = () => {
        dispatch(addRegion(inputRegionTitle));
        setInputRegionTitle("");
        setSelectedItem(null);
    };

    const handleUploadFile = async (e, region, field, index) => {
        setSelectedUploadItem(index);
        let file = e.target.files[0];

        await dispatch(uploadFile(region.id, getFileName(region.title, field), file));
        setSelectedUploadItem(-1);
    };

    const handleDownloadFile = async (region, field, index) => {
        try {
            setSelectedDownloadingItem(index);
            const regionData = regions.find((reg) => reg?.title === region);
            const fieldData = regionData[field];
            if (fieldData && fieldData.path) {
                await dispatch(downloadFile(fieldData.path));
            }
            setSelectedDownloadingItem(-1);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveRegion = (region) => {
        setModalOpen(true);
        setModalRegion(region);
    };

    const handleRemoveRegionConfirm = () => {
        if (selectedRegion.isUpdating === true) {
            return;
        }
        dispatch(removeRegion(modalRegion.id));
        setModalRegion(null);
        setModalOpen(false);
    };

    const handleRegionClick = (regionId) => {
        if (selectedItem === regionId) {
            setSelectedItem(null);
        } else {
            setSelectedItem(regionId);
        }
        setFieldEditText("");
        setFieldEdit(null);
    };

    const selectedRegion = regions.find((r) => r.id === selectedItem);

    const handleEditLense = (field) => {
        setFieldEditText(selectedRegion[field].label);
        setFieldEdit(field);
    };

    const handleUpdateLabel = (isSuccess) => {
        if (isSuccess) {
            const data = {
                [fieldEdit]: {
                    ...selectedRegion[fieldEdit],
                    label: fieldEditText,
                },
            };
            dispatch(updateRegion(selectedRegion.id, data));
        }
        setFieldEditText("");
        setFieldEdit(null);
    };

    const handleUpdateArea = () => {
        if (!isNaN(+regionArea)) {
            const data = {
                area: {
                    value: regionArea,
                },
            };
            dispatch(updateRegion(selectedRegion.id, data));
        } else {
            window.alert("Area must be a number");
        }
    };

    useEffect(() => {
        if (selectedRegion) {
            setRegionArea(selectedRegion.area.value);
            if (files) {
                const file = files.find((f) => f.regionName === replaceWithUnderscores(selectedRegion.title));
                if (file) {
                    setSelectedFile(file);
                }
            }
        }
    }, [selectedRegion]);

    const handleSelectFile = (event) => {
        const fileName = event.target.value;

        const file = files.find((f) => f.path === fileName);

        setSelectedFile(file);
    };

    useEffect(() => {
        let requestInterval = setInterval(() => {
            if (selectedRegion) {
                dispatch(getRegions());
            }
        }, 5000);
        return () => {
            clearInterval(requestInterval);
        };
    }, [selectedRegion, dispatch]);

    const handleDatabaseUpdate = async () => {
        if (selectedFile.name && selectedFile.path && selectedRegion.id && selectedRegion.title) {
            dispatch(fillRegionDatabase(selectedFile.name, selectedFile.path, selectedRegion.id, selectedRegion.title));
        }
        dispatch(getRegions());
    };

    return (
        <>
            <Card style={{ margin: "0 0 50px" }}>
                <h3 style={{ margin: "20px 10px" }}>Regions</h3>
                <List style={{ padding: "0" }}>
                    {regions.length ? (
                        regions.map((region) => (
                            <div key={region.id}>
                                <ListItem selected={selectedItem === region.id} button onClick={() => handleRegionClick(region.id)}>
                                    <ListItemText>{capitalize(region.title)}</ListItemText>
                                    {region.id === selectedItem ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={selectedItem === region.id}>
                                    <List>
                                        {FILE_TYPES.map((item, counter) => (
                                            <ListItem key={counter} style={{ display: "flex", justifyContent: "space-between" }}>
                                                {selectedUploadItem === counter ? (
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={uploadProgress}
                                                        style={{ width: "100%", padding: "5px 0" }}
                                                    />
                                                ) : fieldEdit === item.field ? (
                                                    <div>
                                                        <div style={{ padding: "0", display: "flex", justifyContent: "center", gap: "20px" }}>
                                                            <TextField
                                                                label="label"
                                                                variant="outlined"
                                                                size="small"
                                                                value={fieldEditText}
                                                                onChange={(e) => setFieldEditText(e.target.value)}
                                                            />
                                                            <Button onClick={() => handleUpdateLabel(false)} variant="contained" size="small">
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                onClick={() => handleUpdateLabel(true)}
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            {selectedRegion && selectedRegion[item.field].label}
                                                            {region[item.field] && region[item.field].path ? (
                                                                <Button
                                                                    onClick={() => handleDownloadFile(region.title, item.field, counter)}
                                                                    style={{ marginLeft: "15px" }}
                                                                    disabled={selectedRegion && selectedRegion.isUpdating === true}
                                                                >
                                                                    {selectedDownloadingItem === counter ? (
                                                                        <CircularProgress />
                                                                    ) : (
                                                                        <>
                                                                            {/* <Icon style={{ color: item.color }}>description_outlined</Icon> */}
                                                                            <img src={Document} width="24" alt="doc" />
                                                                            <span style={{ fontSize: "15px" }}>
                                                                                [{(region[item.field].size / (1024 * 1024)).toFixed(2)} MB]
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </Button>
                                                            ) : (
                                                                // <Icon style={{ color: "grey" }}>description_outlined</Icon>
                                                                <img src={Document} width="24" alt="doc" />
                                                            )}
                                                        </div>

                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                style={{ marginRight: "20px" }}
                                                                onClick={() => handleEditLense(item.field)}
                                                                disabled={selectedRegion && selectedRegion.isUpdating === true}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                disabled={uploadProgress !== -1 && uploadProgress !== 100}
                                                                onChange={(e) => handleUploadFile(e, region, item.field, counter)}
                                                                component="label"
                                                                style={{ float: "right" }}
                                                            >
                                                                Upload <input type="file" hidden />
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </ListItem>
                                        ))}
                                        {/* <div style={{ width: "100%" }}>
                                            <NavLink to={`/upload/${region.title}`}>
                                                <Button variant="contained" style={{ width: "100%" }} color="primary">
                                                    Upload full region
                                                </Button>
                                            </NavLink>
                                        </div> */}
                                    </List>

                                    {selectedItem === region.id && <RegionMapSelect regionId={region.id} position={region.position} />}

                                    <div style={{ margin: "10px", display: "flex", widt: "100%", justifyContent: "space-between" }}>
                                        <div style={{ gap: "10px", display: "flex" }}>
                                            <Select value={selectedFile.path ? selectedFile.path : ""} onChange={handleSelectFile}>
                                                {files &&
                                                    files.map((f, id) => {
                                                        return (
                                                            <MenuItem key={f.path} value={f.path}>
                                                                {f.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleDatabaseUpdate}
                                                disabled={selectedRegion && selectedRegion.isUpdating === true}
                                            >
                                                Update Region Data
                                            </Button>
                                        </div>
                                    </div>

                                    {selectedRegion && selectedRegion.isUpdating === true && (
                                        <h1 style={{ color: "green" }}>Region is currently updating.</h1>
                                    )}

                                    <div style={{ margin: "10px", display: "flex", widt: "100%", justifyContent: "space-between" }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleRemoveRegion(region)}
                                            disabled={selectedRegion && selectedRegion.isUpdating === true}
                                        >
                                            Remove {region.title}
                                        </Button>
                                        <div style={{ gap: "10px", display: "flex" }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleUpdateArea()}
                                                disabled={selectedRegion && selectedRegion.isUpdating === true}
                                            >
                                                Update Region Area
                                            </Button>
                                            <input value={regionArea} onChange={(e) => setRegionArea(e.target.value)} />
                                        </div>
                                    </div>
                                    <hr style={{ borderColor: "rgb(205,205,205,0.1)" }}></hr>
                                </Collapse>
                            </div>
                        ))
                    ) : (
                        <CircularProgress style={{ marginLeft: "20px" }} />
                    )}
                    <hr></hr>
                    <div style={{ marginTop: "-10px" }}>
                        <ListItem
                            selected={selectedItem === -100}
                            button
                            onClick={() => (selectedItem === -100 ? setSelectedItem(null) : setSelectedItem(-100))}
                        >
                            <ListItemText>Add New Region</ListItemText>
                            {-100 === selectedItem ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={selectedItem === -100}>
                            <div style={{ padding: "30px", display: "flex", justifyContent: "center", gap: "20px" }}>
                                <TextField
                                    label="title"
                                    variant="outlined"
                                    size="small"
                                    value={inputRegionTitle}
                                    onChange={(e) => setInputRegionTitle(e.target.value)}
                                />
                                <Button onClick={handleAddRegion} variant="contained" color="primary" size="small">
                                    Add
                                </Button>
                            </div>
                        </Collapse>
                    </div>
                </List>
            </Card>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                {modalRegion && (
                    <>
                        <DialogTitle>Confirm to remove "{capitalize(modalRegion.title)}" region</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                if you select "remove", you will lose all data assigned to the region, do you want to continue?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setModalOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleRemoveRegionConfirm}
                                variant="contained"
                                color="secondary"
                                disabled={selectedRegion && selectedRegion.isUpdating === true}
                            >
                                Remove
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default RegionComponent;
