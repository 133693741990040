import React from "react";
import { Layer, Source } from "react-map-gl";
import { API_BASE_URL } from "../../config";

export const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "polygons",
    paint: {
        "circle-color": "#51bbd6",
        "circle-radius": 750,
    },
};

export const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "polygons",
    layout: {
        "text-field": "HELLO",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
    },
};

const SourcePolygons = ({ id, regionInfo, styles, hide = false }) => {
    if (hide) {
        return null;
    }

    const chunks = regionInfo?.polygons?.path || [];

    return (
        <>
            {chunks.map((path, index) => (
                <Source key={path} id={`${id}-${index}`} type="geojson" data={`${API_BASE_URL}/region/${regionInfo.id}?pathId=${index}`} buffer={0}>
                    {!hide && <Layer {...styles} id={`${id}-${index}`} />}
                </Source>
            ))}
        </>
    );
};

export default SourcePolygons;
