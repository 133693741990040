import React, { useEffect } from "react";

import area from "@turf/area";
import bbox from "@turf/bbox";
import centroid from "@turf/centroid";
import { featureCollection } from "@turf/helpers";
import { Layer, Source, StaticMap, WebMercatorViewport } from "react-map-gl";
import { MAPBOX_TOKEN } from "../../config";
import ChartLineForPDF from "../GraphComponents/chartLineForPDF";
import NetGraph from "../GraphComponents/netGraph";
import ChartPie from "../GraphComponents/pie";

const treeStyle = () => ({
    id: `poly`,
    type: "line",
    paint: {
        "line-color": "#FFC414",
        "line-width": 2.5,
    },
});

const PDFCompareComponent = React.forwardRef((props, ref) => {
    const { years, compareGraph, keys, agregateGraph, region, filters, chartsForPDF, mode, polygonArea } = props;

    useEffect(() => {
        document.querySelector(`#print_butt`).setAttribute("disabled", "disabled");
    }, []);

    if (!chartsForPDF || compareGraph) {
        let but = document.querySelector(`#print_butt`);
        if (but && !but.hasAttribute("disabled")) {
            but.setAttribute("disabled", "disabled");
        }
        return null;
    }

    let features = [];
    chartsForPDF.forEach((el) => {
        features.push(el.elem);
    });

    let collection = featureCollection(features);
    let boundBox = bbox(collection);

    let cornersLongLat = [
        [boundBox[0], boundBox[1]],
        [boundBox[2], boundBox[3]],
    ];

    let viewport = new WebMercatorViewport({ width: 400, height: 300 }).fitBounds(cornersLongLat, { padding: 40 });
    let { longitude, latitude, zoom } = viewport;

    //pdf_print_block нужен для показа компонента только на генерации PDF
    return (
        <div className="pdf_print_block" style={{ width: "80%", margin: "60px auto" }} ref={ref}>
            <div>
                <div>
                    <h3>CANOPY REPORT</h3>
                    <p>
                        Data Extracted {new Date().toDateString()} from <b>{region}</b> using LEAF
                    </p>
                    <p>
                        <b>Name:</b> {chartsForPDF.map((c) => c.title).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Location:</b> {chartsForPDF.map((c) => `[${centroid(c.elem).geometry.coordinates}]`).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Area:</b>{" "}
                        {chartsForPDF.map((c) => (
                            <span style={{ marginLeft: 5 }}>
                                {Math.round(area(c.elem))} m<sup>2</sup>
                            </span>
                        ))}{" "}
                    </p>

                    <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                            <b>Filters:</b>
                        </div>
                        <div>
                            <div>
                                Heights:{" "}
                                {typeof filters.height === "string"
                                    ? filters.height
                                    : `${filters.height[0] === -1 ? 0 : filters.height[0]} - ${filters.height[1]} m`}
                            </div>
                            <div>Years: {years}</div>
                            <div>Land use: {filters.zones.join(", ")}</div>
                        </div>
                    </div>

                    <hr></hr>
                </div>

                <div>
                    <h3>Map</h3>
                    <div style={{ width: "534px", height: "400px" }}>
                        <img id={"poly_img"} src={null} alt={"map"} style={{ width: "100%", height: "100%" }} />
                        <StaticMap
                            width="100%"
                            height="100%"
                            visible={false}
                            attributionControl={false}
                            preserveDrawingBuffer={true}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            latitude={latitude}
                            longitude={longitude}
                            zoom={zoom}
                            onLoad={(e) => {
                                document.querySelector(`#print_butt`).removeAttribute("disabled");
                                document.querySelector(`#poly_img`).src = e.target.getCanvas().toDataURL();
                            }}
                        >
                            {collection && (
                                <Source id={"poly"} type="geojson" data={collection}>
                                    <Layer {...treeStyle(1)} />
                                </Source>
                            )}
                        </StaticMap>
                    </div>
                </div>

                <div className="page-break" />

                {agregateGraph && (
                    <div>
                        <h3 style={{ marginTop: "80px" }}>DATA INSIGHTS</h3>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                padding: "20px 0 50px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Canopy Aggregated by Land Use Types</div>
                            <ChartPie data={agregateGraph.dataPie} large />
                        </div>

                        <div className="page-break" />

                        <h3 style={{ marginTop: "80px" }}>Total Urban Forest Cover</h3>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Aggregated Urban Forest Cover</div>

                            {
                                <ChartLineForPDF
                                    data={agregateGraph.dataLine}
                                    area
                                    lines={[
                                        {
                                            key: "total",
                                            label: "Total vegetation",
                                            stroke: "#72D09C",
                                            help: "All observed vegetation including losses and gains.",
                                        },
                                        {
                                            key: "existing",
                                            label: "Baseline vegetation",
                                            stroke: "#68A673",
                                            help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                        },
                                    ]}
                                />
                            }
                        </div>

                        <div className="page-break" />

                        <h3 style={{ marginTop: "80px" }}>Net Loss v Gain</h3>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Aggregated Net Loss v Gain</div>
                            <NetGraph data={agregateGraph.dataBar} polygonArea={polygonArea} mode={mode} />
                        </div>

                        <div className="page-break" />

                        <h3 style={{ marginTop: "80px" }}>Compare graph</h3>
                        <div
                            style={{
                                width: "100%",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div style={{ marginTop: 10 }}>
                                <span style={{ fontSize: 16 }}>Cumulative losses gains</span>
                                <ChartLineForPDF data={compareGraph.cumalative_loss_gain} name={"year"} lines={keys} />
                            </div>

                            <div style={{ marginTop: 30 }}>
                                <span style={{ fontSize: 16 }}>Baseline vegetation</span>
                                <ChartLineForPDF data={compareGraph.existing_veg} name={"year"} lines={keys} />
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div style={{ marginTop: 30 }}>
                                <span style={{ fontSize: 16 }}>Total vegetation</span>
                                <ChartLineForPDF data={compareGraph.total_veg} name={"year"} lines={keys} />
                            </div>
                        </div>
                    </div>
                )}

                <div className="page-footer">
                    Commercial-in-confidence | Player Piano Data Analytics | E: lab@ppdataanalytics.com | W: https://ppdataanalytics.com
                </div>
            </div>
        </div>
    );
});

export default PDFCompareComponent;
