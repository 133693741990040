import axios from "axios";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import App from "./App/App";
import store from "./Redux/Store.ts";
import "./helpers/zod-helper";
import "./index.css";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./theme/theme";

let lightSchemeIcon = document.querySelector("link#light-scheme-icon");
let darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

function onUpdate() {
    if (matcher.matches) {
        if (lightSchemeIcon) {
            lightSchemeIcon.remove();
            document.head.append(darkSchemeIcon);
        }
    } else {
        if (darkSchemeIcon) {
            document.head.append(lightSchemeIcon);
            darkSchemeIcon.remove();
        }
    }
}

let matcher = window.matchMedia("(prefers-color-scheme: dark)");
matcher.addListener(onUpdate);
onUpdate();

const cookies = new Cookies();

let token = cookies.get("auth_token");
if (token) {
    window.localStorage.setItem("auth_token", token);
    cookies.remove("auth_token");
} else {
    token = window.localStorage.getItem("auth_token");
}

axios.interceptors.request.use((config) => {
    if (token) {
        config.headers.Authorization = "bearer " + token;
    }
    return config;
});

// String.prototype.capitalize = function () {
//     return this.charAt(0).toUpperCase() + this.slice(1);
// };

// const capitalize = (str) => {
//     return str.charAt(0).toUpperCase() + str.slice(1)
// }


const Main = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default Main;
