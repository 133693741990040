import { HIDE_SNACKBAR, SHOW_SNACKBAR, TOGGLE_SNACKBAR } from "./types";

let initialState = {
    isShow: false,
    title: "",
    variant: null,
    duration: 3000,
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SNACKBAR:
            return { ...state, isShow: true, title: action.title, variant: action.variant, duration: action.duration };
        case HIDE_SNACKBAR:
            return { ...state, isShow: false };
        case TOGGLE_SNACKBAR:
            return { ...state, isShow: !state.isShow };
        default:
            return state;
    }
};

export const showSnackbar = (title, variant, duration = 3000) => ({ type: SHOW_SNACKBAR, title, variant, duration });
export const hideSnackbar = () => ({ type: HIDE_SNACKBAR });
export const toggleSnackbar = () => ({ type: TOGGLE_SNACKBAR });

export default snackbarReducer;
