export const timeDifference = (dateArg: string) => {
    const current = new Date();
    const past = new Date(dateArg);

    const secondsPast = (current.getTime() - past.getTime()) / 1000;

    if (secondsPast < 60 * 60) {
        return `${Math.round(secondsPast / 60)} minutes ago`;
    }

    if (secondsPast < 60 * 60 * 24) {
        return `${Math.round(secondsPast / 60 / 60)} hours ago`;
    }

    if (secondsPast < 60 * 60 * 24 * 7) {
        return `${Math.round(secondsPast / 60 / 60 / 24)} days ago`;
    }

    return "More than 1 week ago";
};
