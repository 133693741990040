import z from "zod";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type && issue.received === "undefined") {
        return { message: "This field is required" };
    }
    if (issue.code === z.ZodIssueCode.too_small) {
        if (issue.type === "string") {
            return { message: `Min length - ${issue.minimum}` };
        }
        if (issue.type === "array") {
            return { message: `Min length - ${issue.minimum}` };
        }
    }
    if (issue.code === z.ZodIssueCode.invalid_string && issue.validation === "email") {
        return { message: "Invalid email" };
    }
    return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
