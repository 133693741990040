import React from "react";
import { Collapse, FormControlLabel, Switch, IconButton, MenuItem, Select, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const InputColor = ({ color, setColor }) => {
    const handleColorChange = (ev) => {
        setColor(ev.target.value);
    };

    return (
        <label>
            <div className="color_container" style={{ borderBottomColor: color }}>
                <span style={{ color }}>{color}</span>
                <input type="color" onBlur={handleColorChange} defaultValue={color} />
            </div>
        </label>
    );
};

const FileInfo = ({
    title,
    setTitle,
    color,
    setColor,
    fields,
    selects,
    handleRemoveFile,
    file,
    data,
    isConfigured,
    setIsConfigured,
    isHexagon,
    setIsHexagon,
    fromHexColor,
    toHexColor,
    setFromHexColor,
    setToHexColor,
    selectedFieldsHex,
    setSelectedFieldsHex,
    selectedFields,
    setSelectedFields,
}) => {
    const classes = useStyles();

    const handleSelect = (e) => {
        setSelectedFields({ ...selectedFields, [e.target.name]: e.target.value });
    };

    const handleHexSelect = (e) => {
        setSelectedFieldsHex({ ...selectedFieldsHex, [e.target.name]: e.target.value });
    };

    if (!file || !data) {
        return null;
    }
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontSize: "16px", fontWeight: "600" }}>{file.name}</div>
                <IconButton classes={{ root: classes.iconHover }} onClick={handleRemoveFile}>
                    <DeleteIcon style={{ fontSize: "20px" }} />
                </IconButton>
            </div>

            <div style={{ margin: "15px 0", display: "flex", alignItems: "end", justifyContent: "space-between" }}>
                <TextField size="small" label="lense title" value={title} onChange={(e) => setTitle(e.target.value)} />

                <InputColor color={color} setColor={setColor} />
            </div>

            <FormControlLabel
                control={<Switch value={isConfigured} onChange={(e) => setIsConfigured(e.target.checked)} color="primary" />}
                label="Configure for filtering"
            />
            <Collapse in={isConfigured}>
                <div>
                    {selects.map((s) => (
                        <div key={s.name} className={classes.fieldRow}>
                            <div className={classes.field__label}>{s.label} field</div>
                            <Select
                                style={{ minWidth: "110px" }}
                                value={selectedFields[s.name] || "none"}
                                onChange={handleSelect}
                                name={s.name}
                                label={s.label}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="none">
                                    <em style={{ color: "grey" }}>None</em>
                                </MenuItem>
                                {fields[s.name].map((el, ind) => (
                                    <MenuItem key={ind} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    ))}
                </div>
            </Collapse>

            <FormControlLabel
                control={<Switch value={isHexagon} onChange={(e) => setIsHexagon(e.target.checked)} color="primary" />}
                label="Configure for hexagon"
            />
            <Collapse in={isHexagon}>
                <div style={{ marginTop: 20 }}>
                    <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                        <div className={classes.field__label}>Calc field</div>

                        <Select
                            style={{ minWidth: "110px" }}
                            value={selectedFieldsHex["hex"] || "none"}
                            onChange={handleHexSelect}
                            name={"hex"}
                            label={"Hex"}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value="none">
                                <em style={{ color: "grey" }}>None</em>
                            </MenuItem>
                            {fields["height"].map((el, ind) => (
                                <MenuItem key={ind} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div style={{ marginTop: 20, display: "flex", alignItems: "center", gap: 30 }}>
                        Min:
                        <InputColor color={fromHexColor} setColor={setFromHexColor} />
                    </div>

                    <div style={{ marginTop: 20, display: "flex", alignItems: "center", gap: 30 }}>
                        Max:
                        <InputColor color={toHexColor} setColor={setToHexColor} />
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    fieldRow: {
        minWidth: "250px",
        margin: "24px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    field__label: {
        fontSize: "17px",
        fontWeight: "600",
    },
    iconHover: {
        backgroundColor: "transparent !important",
    },
}));

export default FileInfo;
