import jszip from "jszip";
import { getCsvBlob } from "../../helpers/right-sidebar/index.ts";

export type createCSVData = {
    mode: "polygon_area" | "total_area" | "sqm";
    region: string;
    isCompare: boolean;
    area: {
        value: number;
    };
    dataScatter: {
        x: number[];
        y: number[];
    };
    dataLossGains: {
        time_period: string[];
        plantings: number[];
        removals: number[];
        net: number[];
        x: number[];
    };
    dataTotalArea: {
        year: number[];
        type2: string[];
        landuse: string[];
        shape_area: number[];
        text_area: string[];
    };
    dataVegetationRemovals: {
        year: number[];
        type2: string[];
        landuse: string[];
        shape_area: number[];
        text_area: string[];
    };
    dataTreeHeights: {
        li_class: string[];
        shape_area: number[];
        prank: number[];
        text_area: string[];
    };
    dataWaterfallFoliage: {
        x: string[][];
        measure: string[];
        y: (number | "NaN")[];
        base: number;
        text: string[];
        p1: number[];
    };
    dataProfitAndLoss: {
        NewPlanting: {
            x: number[];
            y: string[];
            text: string[];
        };
        FoliageGain: {
            x: number[];
            y: string[];
            base: number[];
            text: string[];
        };
        Net: {
            x: number[];
            y: string[];
            text: string[];
        };
        Retained: {
            x: number[];
            y: string[];
            text: string[];
        };
        TreeRemoval: {
            x: number[];
            y: string[];
            text: string[];
        };
        FoliageLoss: {
            x: number[];
            y: string[];
            text: string[];
        };
        domain: number[];
    };
    dataProfitAndLossTotal: {
        NewPlanting: {
            x: number;
            y: string;
            text: string;
        };
        FoliageGain: {
            x: number;
            y: string;
            text: string;
        };
        Net: {
            x: number;
            y: string;
            text: string;
        };
        Retained: {
            x: number;
            y: string;
            text: string;
        };
        TreeRemoval: {
            x: number;
            y: string;
            text: string;
        };
        FoliageLoss: {
            x: number;
            y: string;
            text: string;
        };
    };
    dataChart: {
        name: string;
        existing: number;
        total: number;
    }[];
    dataPie: {
        name: string;
        value: number;
    }[];
    dataChangeInCanopy: {
        NewPlanting: {
            x: number[];
            y: string[];
            text: string[];
        };
        FoliageGain: {
            x: number[];
            y: string[];
            base: number[];
            text: string[];
        };
        Net: {
            x: number[];
            y: string[];
            text: string[];
        };
        Retained: {
            x: number[];
            y: string[];
            text: string[];
        };
        TreeRemoval: {
            x: number[];
            y: string[];
            text: string[];
        };
        FoliageLoss: {
            x: number[];
            y: string[];
            text: string[];
        };
        domain: number[];
    };
};

export const generateCSVZip = ({
    dataLossGains,
    dataScatter,
    dataTotalArea,
    dataTreeHeights,
    dataPie,
    dataChangeInCanopy,
    dataChart,
    dataProfitAndLoss,
    dataProfitAndLossTotal,
    dataVegetationRemovals,
    dataWaterfallFoliage,
}: createCSVData) => {
    const scatter = [];

    scatter.push(["Year", ...dataScatter.x]);
    scatter.push(["Area (sqm)", ...dataScatter.y]);

    const totalCanopyProfitAndLoss = [];

    totalCanopyProfitAndLoss.push(["New Plantings (sqm)", dataProfitAndLossTotal.NewPlanting.x]);
    totalCanopyProfitAndLoss.push(["Foliage Gain (sqm)", dataProfitAndLossTotal.FoliageGain.x]);
    totalCanopyProfitAndLoss.push(["Net (sqm)", dataProfitAndLossTotal.Net.x]);
    totalCanopyProfitAndLoss.push(["Retained (sqm)", dataProfitAndLossTotal.Retained.text]);
    totalCanopyProfitAndLoss.push(["Tree Removals (sqm)", dataProfitAndLossTotal.TreeRemoval.x]);

    const yearlyCanopyProfitAndLoss = [];

    yearlyCanopyProfitAndLoss.push(["Year", ...dataChangeInCanopy.NewPlanting.y]);
    yearlyCanopyProfitAndLoss.push(["New Plantings (sqm)", ...dataChangeInCanopy.NewPlanting.x]);
    yearlyCanopyProfitAndLoss.push(["Foliage Gain (sqm)", ...dataChangeInCanopy.FoliageGain.x]);
    yearlyCanopyProfitAndLoss.push(["Net (sqm)", ...dataChangeInCanopy.Net.x]);
    yearlyCanopyProfitAndLoss.push(["Retained (sqm)", ...dataChangeInCanopy.Retained.text]);

    const canopyProfitAndLossByLanduse = [];

    canopyProfitAndLossByLanduse.push(["Landuse", ...dataProfitAndLoss.NewPlanting.text]);
    canopyProfitAndLossByLanduse.push(["New Plantings (sqm)", ...dataProfitAndLoss.NewPlanting.x]);
    canopyProfitAndLossByLanduse.push(["Foliage Gain (sqm)", ...dataProfitAndLoss.FoliageGain.x]);
    canopyProfitAndLossByLanduse.push(["Net (sqm)", ...dataProfitAndLoss.Net.x]);
    canopyProfitAndLossByLanduse.push(["Retained (sqm)", ...dataProfitAndLoss.Retained.text]);

    const waterfallFoliage = [];

    waterfallFoliage.push(["Time Period", ...dataWaterfallFoliage.x[0]]);
    waterfallFoliage.push(["Type", ...dataWaterfallFoliage.x[1]]);
    waterfallFoliage.push(["Area (sqm)", ...dataWaterfallFoliage.y]);
    waterfallFoliage.push(["Status", ...dataWaterfallFoliage.text]);
    waterfallFoliage.push(["Measure", ...dataWaterfallFoliage.measure]);
    waterfallFoliage.push(["Base", dataWaterfallFoliage.base]);

    const plantingsVsRemovals = [];

    plantingsVsRemovals.push(["Year", ...dataLossGains.time_period]);
    plantingsVsRemovals.push(["Plantings", ...dataLossGains.plantings]);
    plantingsVsRemovals.push(["Removals", ...dataLossGains.removals]);
    plantingsVsRemovals.push(["Net", ...dataLossGains.net]);

    const canopyGainedDueToPlantings = [];

    canopyGainedDueToPlantings.push(["Year", ...dataTotalArea.year]);
    canopyGainedDueToPlantings.push(["Landuse", ...dataTotalArea.landuse]);
    canopyGainedDueToPlantings.push(["Type", ...dataTotalArea.type2]);
    canopyGainedDueToPlantings.push(["Area (sqm)", ...dataTotalArea.shape_area]);
    canopyGainedDueToPlantings.push(["Area of the second type (sqm)", ...dataTotalArea.text_area]);

    const canopyLostDueToRemovals = [];

    canopyLostDueToRemovals.push(["Year", ...dataVegetationRemovals.year]);
    canopyLostDueToRemovals.push(["Landuse", ...dataVegetationRemovals.landuse]);
    canopyLostDueToRemovals.push(["Type", ...dataVegetationRemovals.type2]);
    canopyLostDueToRemovals.push(["Area (sqm)", ...dataVegetationRemovals.shape_area]);
    canopyLostDueToRemovals.push(["Area of the second type (sqm)", ...dataVegetationRemovals.text_area]);

    const treeHeights = [];

    treeHeights.push(["Tree height", ...dataTreeHeights.li_class]);
    treeHeights.push(["Area (sqm)", ...dataTreeHeights.shape_area]);
    treeHeights.push(["Prank", ...dataTreeHeights.prank]);

    const pie = [];

    pie.push(["Land Use", ...dataPie.map((d) => d.name)]);
    pie.push(["Area (sqm)", ...dataPie.map((d) => d.value)]);

    const canopyCover = [];

    canopyCover.push(["Year", ...dataChart.map((d) => d.name)]);
    canopyCover.push(["Existing (sqm)", ...dataChart.map((d) => d.existing)]);
    canopyCover.push(["Total (sqm)", ...dataChart.map((d) => d.total)]);

    const scatterBlob = getCsvBlob(scatter);
    const totalCanopyProfitAndLossBlob = getCsvBlob(totalCanopyProfitAndLoss);
    const yearlyCanopyProfitAndLossBlob = getCsvBlob(yearlyCanopyProfitAndLoss);
    const canopyProfitAndLossByLanduseBlob = getCsvBlob(canopyProfitAndLossByLanduse);
    const waterfallFoliageBlob = getCsvBlob(waterfallFoliage);
    const plantingsVsRemovalsBlob = getCsvBlob(plantingsVsRemovals);
    const canopyGainedDueToPlantingsBlob = getCsvBlob(canopyGainedDueToPlantings);
    const canopyLostDueToRemovalsBlob = getCsvBlob(canopyLostDueToRemovals);
    const treeHeightsBlob = getCsvBlob(treeHeights);
    const pieBlob = getCsvBlob(pie);
    const canopyCoverBlob = getCsvBlob(canopyCover);

    const zip = jszip();

    zip.file("scatter.csv", scatterBlob);
    zip.file("total_canopy_profit_and_loss.csv", totalCanopyProfitAndLossBlob);
    zip.file("yearly_canopy_profit_and_loss.csv", yearlyCanopyProfitAndLossBlob);
    zip.file("canopy_profit_and_loss_by_landuse.csv", canopyProfitAndLossByLanduseBlob);
    zip.file("waterfall_foliage.csv", waterfallFoliageBlob);
    zip.file("plantings_vs_removals.csv", plantingsVsRemovalsBlob);
    zip.file("canopy_gained_due_to_plantings.csv", canopyGainedDueToPlantingsBlob);
    zip.file("canopy_lost_due_to_removals.csv", canopyLostDueToRemovalsBlob);
    zip.file("tree_heights.csv", treeHeightsBlob);
    zip.file("pie.csv", pieBlob);
    zip.file("canopy_cover.csv", canopyCoverBlob);

    zip.generateAsync({
        type: "base64",
        compression: "DEFLATE",
        compressionOptions: {
            level: 4,
        },
    }).then(async (content) => {
        var link = document.createElement("a");
        link.href = "data:application/zip;base64," + content;
        link.download = "leaf_report.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
};
