import area from "@turf/area";
import bbox from "@turf/bbox";
import centroid from "@turf/centroid";
import { featureCollection } from "@turf/helpers";
import React, { useEffect } from "react";
import { Layer, Source, StaticMap, WebMercatorViewport } from "react-map-gl";
import { MAPBOX_TOKEN } from "../../config";
import ChartLineForPDF from "../GraphComponents/chartLineForPDF";
import ChartPie from "../GraphComponents/pie";

import ScatterChart from "../GraphComponents/scatterGraph";
import LossGainsChart from "../GraphComponents/lossGainsChart";
import TotalAreaChart from "../GraphComponents/totalAreaChart";
// import vegetationRemovalsChart from "../GraphComponents/vegetationRemovalsChart";
import TreeHeightsChart from "../GraphComponents/treeHeightsChart";
import VegetationRemovalsChart from "../GraphComponents/vegetationRemovalsChart";
import WaterfallFoliage from "../GraphComponents/waterfallFoliageChart";
import ProfitAndLoss from "../GraphComponents/profitAndLossChart";
import ProfitAndLossTotal from "../GraphComponents/profitAndLossTotalChart";
import ChangeInCanopy from "../GraphComponents/changeInCanopyChart";
import { TPDFComponentProps } from "./types";

const treeStyle = (): {
    id: string;
    type: "symbol" | "line" | "circle" | "fill" | "fill-extrusion" | "raster" | "background" | "heatmap" | "hillshade" | "sky" | "custom";
    paint: {
        [key: string]: number | string;
    };
} => ({
    id: `poly`,
    type: "line",
    paint: {
        "line-color": "#FFC414",
        "line-width": 2.5,
    },
});

const PDFComponent = React.forwardRef((props: TPDFComponentProps, ref: any) => {
    const { years, agregateGraph, region, filters, chartsForPDF, mode, polygonArea, regionArea } = props;

    useEffect(() => {
        document.querySelector(`#print_butt`)?.setAttribute("disabled", "disabled");
    }, []);

    if (!chartsForPDF) {
        let but = document.querySelector(`#print_butt`);
        if (but && !but.hasAttribute("disabled")) {
            but.setAttribute("disabled", "disabled");
        }
        return null;
    }

    let features: any = [];
    chartsForPDF.forEach((el: any) => {
        features.push(el.elem);
    });

    let collection = featureCollection(features);
    let boundBox = bbox(collection);

    let cornersLongLat: any = [
        [boundBox[0], boundBox[1]],
        [boundBox[2], boundBox[3]],
    ];

    let viewport = new WebMercatorViewport({ width: 400, height: 300 }).fitBounds(cornersLongLat, { padding: 40 });
    let { longitude, latitude, zoom } = viewport;
    //pdf_print_block нужен для показа компонента только на генерации PDF

    return (
        <div className="pdf_print_block" style={{ width: "80%", margin: "60px auto" }} ref={ref}>
            <div>
                <div>
                    <h3>CANOPY REPORT</h3>
                    <p>
                        Data Extracted {new Date().toDateString()} from <b>{region}</b> using LEAF
                    </p>
                    <p>
                        <b>Name:</b> {chartsForPDF.map((c: any) => c.title).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Location:</b> {chartsForPDF.map((c: any) => `[${centroid(c.elem).geometry.coordinates}]`).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Area:</b>{" "}
                        {chartsForPDF.map((c: any, ind: number) => (
                            <span key={ind} style={{ marginLeft: 5 }}>
                                {Math.round(area(c.elem))} m<sup>2</sup>
                            </span>
                        ))}{" "}
                    </p>

                    <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                            <b>Filters:</b>
                        </div>
                        <div>
                            <div>
                                Heights:{" "}
                                {typeof filters.height === "string"
                                    ? filters.height
                                    : `${filters.height[0] === -1 ? 0 : filters.height[0]} - ${filters.height[1]} m`}
                            </div>
                            <div>Years: {years}</div>
                            <div>Land use: {filters.zones.join(", ")}</div>
                        </div>
                    </div>

                    <hr></hr>
                </div>

                <div>
                    <h3>Map</h3>
                    <div style={{ width: "534px", height: "400px" }}>
                        <img id={"poly_img"} src={undefined} alt={"map"} style={{ width: "100%", height: "100%" }} />
                        <StaticMap
                            width="100%"
                            height="100%"
                            visible={false}
                            attributionControl={false}
                            preserveDrawingBuffer={true}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            latitude={latitude}
                            longitude={longitude}
                            zoom={zoom}
                            onLoad={(e) => {
                                document.querySelector(`#print_butt`)?.removeAttribute("disabled");
                                (document.querySelector(`#poly_img`) as HTMLImageElement).src = e.target.getCanvas().toDataURL();
                            }}
                        >
                            {collection && (
                                <Source id={"poly"} type="geojson" data={collection as any}>
                                    <Layer {...treeStyle()} />
                                </Source>
                            )}
                        </StaticMap>
                    </div>
                </div>

                <div className="page-break" />

                {agregateGraph && (
                    <div>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Change in Canopy Cover</b>
                            </div>
                            <ScatterChart
                                data={agregateGraph.dataScatter}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>
                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The graph aggregates canopy cover to the corresponding year.
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Total Canopy Profit & Loss</b>
                            </div>

                            <ProfitAndLossTotal
                                data={agregateGraph.dataProfitAndLossTotal}
                                forPDF={true}
                                preview={false}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>
                                <p style={{ border: "1px solid black" }}>
                                    The following graph balances canopy addition to canopy subtraction, to show total net change.
                                    <br />
                                    Addition consists of gains made by growth of existing canopy and new plantings.
                                    <br />
                                    Subtraction consists of canopy loss due to loss of existing canopy and tree removal.
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Canopy Profit & Loss By Year</b>
                            </div>
                            <ChangeInCanopy
                                data={agregateGraph.dataChangeInCanopy}
                                forPDF={true}
                                preview={false}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div>
                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The following graph balances canopy addition to canopy subtraction, to show yearly net change.
                                    <br />
                                    Addition consists of gains made by growth of existing canopy and new plantings.
                                    <br />
                                    Subtraction consists of canopy loss due to loss of existing canopy and tree removal.",
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Canopy Profit & Loss By Landuse</b>
                            </div>
                            <ProfitAndLoss
                                data={agregateGraph.dataProfitAndLoss}
                                forPDF={true}
                                preview={false}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The following graph balances canopy addition to canopy subtraction, to show total net change.
                                    <br />
                                    Addition consists of gains made by growth of existing canopy and new plantings.
                                    <br />
                                    Subtraction consists of canopy loss due to loss of existing canopy and tree removal.",
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Yearly Net Canopy</b>
                            </div>
                            <WaterfallFoliage
                                data={agregateGraph.dataWaterfallFoliage}
                                forPDF={true}
                                preview={false}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The following graph balances canopy addition to canopy subtraction, to show total net change.
                                    <br />
                                    Addition consists of gains made by growth of existing canopy and new plantings.
                                    <br />
                                    Subtraction consists of canopy loss due to loss of existing canopy and tree removal.",
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Plantings VS Removals</b>
                            </div>
                            <LossGainsChart
                                data={agregateGraph.dataLossGains}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    Recorded planting and removal event are aggregated to the corresponding period which it occurred.
                                    <br />
                                    The graph is intended to show the overall trend in the number of trees planted vs removed over time. It helps to
                                    understand how planting and removal events have affected the size of the urban forest present and future.
                                    <br />A planting is detected when a tree has approximately 1 square meter of tree foliage, and will contribute to
                                    the future urban forest.
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Canopy Gained due to Plantings</b>
                            </div>
                            <TotalAreaChart
                                data={agregateGraph.dataTotalArea}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The graphs show proportionally the total canopy gained due to plantings.
                                    <br />
                                    The information is separated into land use categories.
                                    <br />
                                    The graph is an indicator of the amount of change occurring in the urban forest over the study period.
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Canopy Lost due to Removals</b>
                            </div>
                            <VegetationRemovalsChart
                                data={agregateGraph.dataVegetationRemovals}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The graphs show proportionally the total canopy lost due to removals.
                                    <br />
                                    The information is separated into land use categories, and is an indicator of the amount of change occurring in
                                    the urban forest over the study period.
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Canopy of Region by Height</b>
                            </div>
                            <TreeHeightsChart
                                data={agregateGraph.dataTreeHeights}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    The maximum height of a tree is used to aggregated canopy to a range of height intervals.{" "}
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                padding: "20px 0 70px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Canopy by Land Use Types</b>
                            </div>
                            <ChartPie data={props.agregateGraph.dataPie} large mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    A pie chart that shows distribution of canopy by landuse.
                                </p>
                            </div>
                        </div>

                        <div className="page-break" />

                        <h3 style={{ marginTop: "80px" }}>Total Urban Forest Cover</h3>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <b>Total Urban Forest Cover</b>
                            </div>

                            <ChartLineForPDF
                                data={agregateGraph.dataLine}
                                area
                                lines={[
                                    {
                                        key: "total",
                                        label: "Total vegetation",
                                        stroke: "#72D09C",
                                        help: "All observed vegetation including losses and gains.",
                                    },
                                    {
                                        key: "existing",
                                        label: "Baseline vegetation",
                                        stroke: "#68A673",
                                        help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                    },
                                ]}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                            <div style={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                <h4>Description</h4>

                                <p style={{ fontSize: "16px", border: "1px solid black", boxSizing: "border-box", padding: "5px" }}>
                                    A line chart where first line shows all observed vegetation that includes losses and gains and second line shows
                                    vegetation that excludes losses and gains.
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="page-footer">
                    Commercial-in-confidence | Player Piano Data Analytics | E: lab@ppdataanalytics.com | W: https://ppdataanalytics.com
                </div>
            </div>
        </div>
    );
});

export default PDFComponent;
