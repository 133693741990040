import React from "react";

import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const COLORS = ["#3499CF", "#BA456A", "#A2D8B9", "#356693", "#F4848D", "#5E8F66", "#9ED5E5", "#8A2040", "#A39B92"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default function ChartPie(props) {
    const { data, large = false } = props;

    if (!data) {
        return null;
    }

    const pieData = data.map((el) => {
        return {
            ...el,
            value: Math.ceil(el.value),
        };
    });
    let hx = Math.max(0, data.length - 6) * 10;

    return (
        <>
            <PieChart
                width={(large && 600) || 480}
                height={(large && 420 + hx) || 440 + hx}
                margin={{ top: large ? -70 : 0, right: 0, left: 0, bottom: 0 }}
            >
                <Tooltip
                    formatter={(v) => (
                        <span>
                            {v} <b>sqm</b>
                        </span>
                    )}
                />
                <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    isAnimationActive={false}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={(large && 130) || 90}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>

                <Legend
                    formatter={(v) => v.toLowerCase()}
                    wrapperStyle={{
                        fontSize: (large && "16px") || "13px",
                        position: "relative",
                        marginTop: large ? (data.length > 8 ? "-125px" : "-80px") : data.length > 8 ? "-125px" : "-65px",
                    }}
                />
            </PieChart>
            {/* {library === "plotly" && (
                <Plot
                    data={plotlyData}
                    type="pie"
                    layout={{ width: (large && 600) || 480, height: (large && 420 + hx) || 300 + hx }}
                    config={{ displaylogo: false }}
                />
            )} */}
        </>
    );
}
