import { CircularProgress, Grid } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { landingAPI } from "../../Redux/API/Api";

const Main = styled(Grid)`
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const HeadText = styled.div`
    color: #ef463b;
    font-size: 38px;
    font-weight: 1000;
    text-align: center;
`;

const SubHeadText = styled.div`
    margin: 15px auto;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    max-width: 560px;
    @media (max-width: 400px) {
        font-size: 17px;
        line-height: 24px;
    }
`;

const InputBlock = styled.div`
    width: 85%;
    max-width: 500px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    & input {
        width: inherit;
    }
`;

const InputLabel = styled.div`
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    @media (max-width: 400px) {
        font-size: 17px;
    }
`;

const CustomTextField = styled.input`
    border: 2px solid #d0d0d0;
    width: 100%;
    border-radius: 5px;
    padding: 12px 5px 12px 20px;
    font-size: 18px;
    margin-bottom: 10px;
    border-color: ${(props) => (props.error ? "#F44336 !important" : "#D0D0D0")};
    color: ${(props) => (props.error ? "#F44336" : "black")};

    @media (max-width: 400px) {
        font-size: 17px;
    }

    &:focus {
        color: black;
    }

    @media (max-width: 420px) {
        width: 80% !important;
    }
`;

const CustomTextArea = styled.textarea`
    border: 2px solid #d0d0d0;
    width: 100%;
    border-radius: 5px;
    padding: 12px 5px 12px 20px;
    font-size: 18px;
    margin-bottom: 30px;
    @media (max-width: 420px) {
        width: 80% !important;
    }
`;

const CustomButton = styled(Button)`
    background: #ef463b !important;
    color: white;
    border-radius: 30px !important;
    width: 50%;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 10px !important;
    font-size: 18px !important;
    min-width: 80px;
`;

const ErrorText = styled.div`
    color: #f44336;
`;

const isValidEmail = (value) => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value);
};

const initialState = {
    name: "",
    email: "",
    comment: "",
};

const SubscribeForm = ({ setSnackbar }) => {
    const [fields, setFields] = useState(initialState);
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [touched, setTouched] = useState(false);
    const handleOnchange = (ev) => setFields({ ...fields, [ev.target.name]: ev.target.value });

    const handleBlur = (e) => {
        if (!touched) {
            setTouched(true);
        }
        if (!isValidEmail(e.target.value)) {
            setEmailIsInvalid(true);
        } else {
            setEmailIsInvalid(false);
        }
    };

    const setNewFormSendDate = () => {
        localStorage.setItem("send_form", new Date());
    };

    const clearLocalStorageDate = () => {
        localStorage.removeItem("send_form");
    };

    const handleFormSend = async () => {
        try {
            await landingAPI.sendForm(fields);
            setSnackbar({ title: "Thanks! We received your message!", variant: "success" });
            setFields(initialState);
            setNewFormSendDate();
            setIsFetching(false);
        } catch (error) {
            setFields(initialState);
            setSnackbar({ title: "Error while sending a message!", variant: "error" });
            setIsFetching(false);
        }
    };

    const handleLocalStorageDateCheck = () => {
        const sendFormDate = localStorage.getItem("send_form");
        if (sendFormDate) {
            if (!isNaN(new Date(sendFormDate).getTime())) {
                handleFormSend();
            } else {
                clearLocalStorageDate();
            }
        } else {
            handleFormSend();
        }
    };

    const handleSubmit = async () => {
        if (!touched) {
            setSnackbar({ title: "Fill required field", variant: "warning" });
            return;
        } else if (emailIsInvalid) {
            setSnackbar({ title: "Email is invalid", variant: "warning" });
            return;
        } else if (!fields["name"].length) {
            setSnackbar({ title: "Name is required", variant: "warning" });
            return;
        }

        setIsFetching(true);

        handleLocalStorageDateCheck();
    };

    return (
        <Main container>
            <HeadText>Let’s talk</HeadText>
            <SubHeadText>Get in touch for a demonstration of LEAF or to know more about our services and custom datasets</SubHeadText>

            <InputBlock>
                <div style={{ width: "100%" }}>
                    <InputLabel>Name *</InputLabel>
                    <CustomTextField value={fields["name"]} onChange={handleOnchange} name="name" />
                </div>

                <div style={{ width: "100%" }}>
                    <InputLabel>Email Address *</InputLabel>
                    <CustomTextField value={fields["email"]} error={emailIsInvalid} onChange={handleOnchange} name="email" onBlur={handleBlur} />
                    {emailIsInvalid && <ErrorText>Incorrect Email</ErrorText>}
                </div>

                <div style={{ width: "100%" }}>
                    <InputLabel>Comment</InputLabel>
                    <CustomTextArea value={fields["comment"]} onChange={handleOnchange} name="comment" rows={3}></CustomTextArea>
                </div>

                <div style={{ width: "100%" }}>
                    {isFetching ? (
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <CustomButton onClick={handleSubmit} color="primary" variant="contained">
                            Submit
                        </CustomButton>
                    )}
                </div>
            </InputBlock>
        </Main>
    );
};

export default SubscribeForm;
