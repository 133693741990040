import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import styled from "styled-components";

import tree_ledger from "../assets/img/tree_ledger.jpg";
import LoginDialog from "./LoginDialog";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/UserReducer.ts";
import { useNavigate } from "react-router-dom";

const Main = styled.div`
    width: 100%;
    position: relative;
    /* max-height: 950px;
    min-height: 814px; */
    height: calc(100vh - 100px);
    background: url(${tree_ledger}) no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (min-width: 2000px) {
        background-size: 100%;
    }
`;

const GradientBlock = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(5, 5, 5, 0.75), transparent);
`;

const InfoBlock = styled.div`
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    color: white;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PreHeaderText = styled.div`
    text-align: center;
    font-size: 21px;

    margin-bottom: 10px;
    & b {
        font-weight: 400;
    }

    @media (max-width: 650px) {
        font-size: 18px;
    }
    @media (max-width: 460px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media (max-width: 360px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const HeaderText = styled.div`
    font-size: 65px;
    text-align: center;
    font-weight: 1000;
    margin-bottom: 25px;
    & span {
        color: #ef463b;
    }
    @media (max-width: 650px) {
        font-size: 52px;
    }
    @media (max-width: 460px) {
        font-size: 42px;
    }
    @media (max-width: 360px) {
        font-size: 32px;
    }
`;

const CustomButton = styled(Button)`
    background: #ef463b !important;
    color: white !important;
    border-radius: 30px !important;
    min-width: 160px !important;
    width: 50%;
    padding: 14px !important;
    text-transform: none !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    min-width: 70px;
    @media (max-width: 360px) {
        padding: 12px !important;
        font-size: 18px !important;
    }
`;

const SecondHeader = ({ setSnackbar }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const handleLogin = async (fields) => {
        let error = false;
        Object.keys(fields).forEach((key) => {
            if (!fields[key].length) {
                error = true;
            }
        });
        if (error) {
            setSnackbar({ title: "Fill Required Fields", variant: "warning" });
            return;
        }
        setIsFetching(true);
        dispatch(login(fields.login, fields.password));
        setIsFetching(false);
    };

    const launchLEAFHandler = () => {
        if (user) {
            navigate("/");
        } else {
            setModalOpen(true);
        }
    };

    return (
        <Main>
            <GradientBlock />
            <InfoBlock>
                <PreHeaderText>
                    <b>On demand Urban Forest data insights</b>
                    <br></br>
                    <b>powered by Tree Ledger</b>
                </PreHeaderText>

                <HeaderText>
                    <div>
                        Live Explorer <span>+</span>
                    </div>
                    <div style={{ whiteSpace: "nowrap" }}>Analytics Finder</div>
                </HeaderText>

                <CustomButton onClick={launchLEAFHandler}>Launch LEAF</CustomButton>
            </InfoBlock>

            <LoginDialog open={modalOpen} setOpen={setModalOpen} handleSubmit={handleLogin} isFetching={isFetching} />
        </Main>
    );
};

export default SecondHeader;
