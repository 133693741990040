import ChartLine from "./line";
import ChartPie from "./pie";
import { Grid, List, ListItem, ListItemText, IconButton, Card, CardContent, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ScatterChart from "./scatterGraph";
import LossGainsChart from "./lossGainsChart";
import TotalAreaChart from "./totalAreaChart";
import VegetationRemovalsChart from "./vegetationRemovalsChart";
import TreeHeightsChart from "./treeHeightsChart";
// import CombinedTreeHeightsChart from "./combinedTreeHeightChart";
import WaterfallFoliage from "./waterfallFoliageChart";
import ProfitAndLoss from "./profitAndLossChart";
import ProfitAndLossTotal from "./profitAndLossTotalChart";

import arrowRight from "../../assets/static/arrow-right.svg";
import arrowLeft from "../../assets/static/arrow-left.svg";
import arrowRightDisabled from "../../assets/static/arrow-right-disabled.svg";
import arrowLeftDisabled from "../../assets/static/arrow-left-disabled.svg";
import Info from "../../assets/static/info.svg";
import Send from "../../assets/static/send.svg";
import Loader from "../../assets/static/loader.svg";

import InfoTooltip from "../LeftSidebar/InfoTooltip";

import { useDispatch } from "react-redux";

import React, { useState } from "react";

import { getAiSummary } from "../../Redux/TreesReducer";
import ChangeInCanopy from "./changeInCanopyChart";

import pages from "./pages";
import { GraphProps, SummaryProps, TSummary } from "./types";

const titleStyles = {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
};

const messageStyles = {
    boxShadow: "6px 10px 61px -5px rgba(0,0,0,0.75)",
    borderRadius: "10px",
    width: "45%",
    minHeight: "50px",
    boxSizing: "border-box",
    padding: "10px",
};

const messageWrapperStyles = {
    width: "100%",
    display: "flex",
    position: "relative",
};

const messageInputStyles = {
    width: "100%",
    minWidth: "50%",
    height: "100%",
    border: "none",
    outline: "none",
    background: "white",
    textAlign: "contain",
    boxSizing: "border-box",
};

const GraphTitleHoc = ({ title = "title", children }: { title: string; children: any }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={titleStyles as any}>{title}</div>
            {children}
        </div>
    );
};

const Summary = ({ polygon, graph_type, example_question, summary }: SummaryProps) => {
    const dispatch = useDispatch();

    let currentGraphSummaries: TSummary = [];

    if (summary) {
        currentGraphSummaries = summary.filter((el) => el.graph_type === graph_type);
    }

    const [message, setMessage] = useState({
        text: "",
    });

    const [loading, setLoading] = useState(false);

    const handleGetSummary = async () => {
        setLoading(true);
        const result = await dispatch(getAiSummary(polygon[0].elem, message.text, graph_type));
        setMessage({ text: "" });
        setLoading(false);

        return result;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                <strong>AI generated data summary</strong>
                <InfoTooltip
                    text={
                        "This is an experimental feature. Please, make sure the prompt you enter describes what you want clearly, otherwise the result will be meaningless."
                    }
                    style={{ position: "absolute", right: "-20px", top: 0 }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "30px",
                    boxSizing: "border-box",
                    padding: "15px",
                }}
            >
                {currentGraphSummaries.length > 0 &&
                    currentGraphSummaries.map((el, id) => {
                        return (
                            <div style={{ width: "100%" }} key={id}>
                                <div style={{ ...messageWrapperStyles, justifyContent: "space-between" } as any}>
                                    <div style={messageStyles as any}>
                                        <div
                                            style={{ ...messageInputStyles, boxSizing: "border-box", padding: "5px" } as any}
                                            placeholder="Enter something to ask AI..."
                                            // disabled
                                        >
                                            {el.question}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...messageWrapperStyles, justifyContent: "flex-end" } as any}>
                                    {/* <input disabled style={{ messageInputStyles }} value={response.text} /> */}
                                    <div style={messageStyles as any}>
                                        <div style={messageInputStyles as any} placeholder="AI response...">
                                            {el.text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <div style={{ ...messageWrapperStyles, justifyContent: "space-between" } as any}>
                    <div style={messageStyles as any}>
                        <input
                            style={messageInputStyles as any}
                            placeholder="Enter something to ask AI..."
                            value={message.text}
                            onChange={(e) => setMessage({ text: e.target.value })}
                        />
                        <InfoTooltip text={`For example enter: ${example_question}`} style={{ position: "absolute", top: "-30px", left: "0" }} />
                    </div>
                    <Button onClick={handleGetSummary} color="primary" endIcon={<img src={Send} width="24" alt="send" />}>
                        Question AI
                    </Button>
                </div>
                <div style={{ ...messageWrapperStyles, justifyContent: "flex-end" } as any}>
                    {loading === true && <img src={Loader} width="24" alt="loading..." />}
                    <input disabled style={{ messageInputStyles } as any} placeholder="Response from AI..." />
                </div>
            </div>
        </div>
    );
};

export const Graph = (props: GraphProps) => {
    const {
        dataChart,
        dataPie,
        mode,
        polygonArea,
        regionArea,
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataTreeHeights,
        dataVegetationRemovals,
        dataWaterfallFoliage,
        dataProfitAndLoss,
        dataProfitAndLossTotal,
        dataChangeInCanopy,
        lastYear,
        status,
        contribution,
        currentCanopy,
        polygon,
        summary,
        changeRatio,
        firstYear,
    } = props;

    const [currentPage, setCurrentPage] = useState({
        title: "Menu",
        key: "menu",
        id: 0,
    });

    const handleNextGraph = () => {
        if (currentPage.id === Object.keys(pages).length - 1) {
            return;
        }
        setCurrentPage((prev) => pages[prev.id + 1]);
    };

    const handlePreviousGraph = () => {
        if (currentPage.id === 0) {
            return;
        }
        setCurrentPage((prev) => pages[prev.id - 1]);
    };

    const [modalOpen, setModalOpen] = useState({
        id: -1,
        status: false,
    });

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} className="total_area_graph" style={{ marginTop: "30px", width: "100%" }}>
                <div
                    className="graph-slider-handlers"
                    style={{
                        display: "flex",
                        gap: "30px",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                    }}
                >
                    <div onClick={handlePreviousGraph} style={{ cursor: currentPage.id === 0 ? "" : "pointer" }}>
                        <img src={currentPage.id === 0 ? arrowLeftDisabled : arrowLeft} alt="arrow-left" width="48" />
                    </div>
                    <div onClick={handleNextGraph} style={{ cursor: currentPage.id === Object.keys(pages).length - 1 ? "" : "pointer" }}>
                        <img src={currentPage.id === Object.keys(pages).length - 1 ? arrowRightDisabled : arrowRight} alt="arrow-right" width="48" />
                    </div>
                </div>

                {currentPage.key === "menu" && (
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "60px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", justifyContent: "space-around" }}>
                            <Card style={{ width: "300px", minHeight: "200px" }}>
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        gap: "30px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <p style={{ fontSize: "24px" }}>
                                        <strong>{currentCanopy.toFixed(2)}%</strong>
                                    </p>
                                    <p style={{ textAlign: "center" }}>Is the canopy cover in year {lastYear}</p>
                                </CardContent>
                            </Card>
                            <Card style={{ width: "300px", minHeight: "200px" }}>
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        gap: "30px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <p style={{ fontSize: "24px" }}>
                                        <strong>{contribution.toFixed(2)}%</strong>
                                    </p>
                                    <p style={{ textAlign: "center" }}>Is current polygon's contribution to the total case study canopy cover</p>
                                </CardContent>
                            </Card>
                            <Card style={{ width: "300px", minHeight: "200px", marginTop: "30px" }}>
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        gap: "30px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <p style={{ fontSize: "24px" }}>
                                        <strong>{changeRatio.toFixed(2)}%</strong>
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Is a {status} of canopy cover to what was measured in {firstYear}
                                    </p>
                                </CardContent>
                            </Card>
                        </div>
                        <h1 style={{ justifyContent: "center", marginTop: "30px" }}>
                            <strong>Menu</strong>
                        </h1>
                        <List style={{ display: "flex", flexWrap: "wrap", marginTop: "30px" }}>
                            {pages.map((el) => {
                                return el.key !== "menu" ? (
                                    <ListItem
                                        key={el.key}
                                        onClick={(e: any & { localName: string }) => {
                                            if (e.target.localName === "img") {
                                                return;
                                            }
                                            setCurrentPage({
                                                title: el.title,
                                                key: el.key,
                                                id: el.id,
                                            });
                                        }}
                                        dense
                                        button
                                        style={{ flex: "0 0 50%" }}
                                    >
                                        <ListItemText>{el.title}</ListItemText>
                                        <IconButton
                                            edge="end"
                                            onClick={() =>
                                                setModalOpen({
                                                    status: true,
                                                    id: el.id,
                                                })
                                            }
                                        >
                                            <img src={Info} width="24" alt="info" />
                                        </IconButton>
                                    </ListItem>
                                ) : null;
                            })}
                        </List>
                    </div>
                )}
                {currentPage.key === "pie" && dataPie && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ChartPie data={dataPie} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                    </GraphTitleHoc>
                )}
                {/* {currentPage.key === "bar" && dataBar && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ChartRadar data={dataBar} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                    </GraphTitleHoc>
                )} */}
                {currentPage.key === "chart" && dataChart && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ChartLine
                            data={dataChart}
                            area
                            withHelp
                            lines={[
                                {
                                    key: "total",
                                    label: "Total vegetation",
                                    stroke: "#72D09C",
                                    help: "All observed vegetation including losses and gains.",
                                },
                                {
                                    key: "existing",
                                    label: "Baseline vegetation",
                                    stroke: "#68A673",
                                    help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                },
                            ]}
                            mode={mode}
                            polygonArea={polygonArea}
                            regionArea={regionArea}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "scatter" && dataScatter && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ScatterChart data={dataScatter} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="scatter"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "loss_gain" && dataLossGains && (
                    <GraphTitleHoc title={currentPage.title}>
                        <LossGainsChart data={dataLossGains} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="loss_gain"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "total_area" && dataTotalArea && (
                    <GraphTitleHoc title="Canopy Gained due to Plantings">
                        <TotalAreaChart data={dataTotalArea} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="total_area"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "vegetation_removals" && dataVegetationRemovals && (
                    <GraphTitleHoc title={currentPage.title}>
                        <VegetationRemovalsChart data={dataVegetationRemovals} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="vegetation_removals"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "tree_height" && dataTreeHeights && (
                    <GraphTitleHoc title={currentPage.title}>
                        <TreeHeightsChart data={dataTreeHeights} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="tree_height"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {/* {currentPage.key === "waterfall" && dataWaterfall && (
                    <GraphTitleHoc title={currentPage.title}>
                        <WaterfallChart data={dataWaterfall} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="waterfall"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )} */}
                {currentPage.key === "waterfall_foliage" && dataWaterfallFoliage && (
                    <GraphTitleHoc title={`${currentPage.title} between ${firstYear} and ${lastYear}`}>
                        <WaterfallFoliage
                            preview={false}
                            forPDF={false}
                            data={dataWaterfallFoliage}
                            mode={mode}
                            polygonArea={polygonArea}
                            regionArea={regionArea}
                        />
                        {/* <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="waterfall_foliage"
                            summary={summary}
                        /> */}
                    </GraphTitleHoc>
                )}
                {currentPage.key === "profit_and_loss" && dataProfitAndLoss && (
                    <GraphTitleHoc title={`${currentPage.title} between ${firstYear} and ${lastYear}`}>
                        <ProfitAndLoss
                            data={dataProfitAndLoss}
                            preview={false}
                            forPDF={false}
                            mode={mode}
                            polygonArea={polygonArea}
                            regionArea={regionArea}
                        />
                        {/* <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="profit_and_loss"
                            summary={summary}
                        /> */}
                    </GraphTitleHoc>
                )}
                {currentPage.key === "profit_and_loss_total" && dataProfitAndLossTotal && (
                    <GraphTitleHoc title={`${currentPage.title} between ${firstYear} and ${lastYear}`}>
                        <ProfitAndLossTotal
                            preview={false}
                            data={dataProfitAndLossTotal}
                            mode={mode}
                            polygonArea={polygonArea}
                            forPDF={false}
                            regionArea={regionArea}
                        />
                        {/* <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="profit_and_loss"
                            summary={summary}
                        /> */}
                    </GraphTitleHoc>
                )}
                {currentPage.key === "change_in_canopy_cover" && dataChangeInCanopy && (
                    <GraphTitleHoc title={`${currentPage.title} between ${firstYear} and ${lastYear} by Landuse`}>
                        <ChangeInCanopy
                            preview={false}
                            forPDF={false}
                            data={dataChangeInCanopy}
                            mode={mode}
                            polygonArea={polygonArea}
                            regionArea={regionArea}
                        />
                        {/* <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="profit_and_loss"
                            summary={summary}
                        /> */}
                    </GraphTitleHoc>
                )}
            </Grid>
            <Dialog
                open={modalOpen.status}
                onClose={() =>
                    setModalOpen({
                        id: -1,
                        status: false,
                    })
                }
            >
                {modalOpen.id !== -1 && (
                    <>
                        <DialogTitle>{pages[modalOpen.id].title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {pages[modalOpen.id].description.split("<br/>").map((str) => (
                                    <p key={Math.random()}>{str}</p>
                                ))}
                            </DialogContentText>
                            <DialogContentText>For more detailed information navigate to page with the graph</DialogContentText>
                            <div>See the preview of the graph below:</div>

                            {pages[modalOpen.id].key === "pie" && dataPie && (
                                <ChartPie data={dataPie} mode={mode} preview={true} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {/* {pages[modalOpen.id].key === "bar" && dataBar && (
                                <ChartRadar data={dataBar} mode={mode} preview={true} polygonArea={polygonArea} regionArea={regionArea} />
                            )} */}
                            {pages[modalOpen.id].key === "chart" && dataChart && (
                                <ChartLine
                                    data={dataChart}
                                    area
                                    withHelp
                                    lines={[
                                        {
                                            key: "total",
                                            label: "Total vegetation",
                                            stroke: "#72D09C",
                                            help: "All observed vegetation including losses and gains.",
                                        },
                                        {
                                            key: "existing",
                                            label: "Baseline vegetation",
                                            stroke: "#68A673",
                                            help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                        },
                                    ]}
                                    mode={mode}
                                    preview={true}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "scatter" && dataScatter && (
                                <ScatterChart data={dataScatter} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "loss_gain" && dataLossGains && (
                                <LossGainsChart data={dataLossGains} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "total_area" && dataTotalArea && (
                                <TotalAreaChart data={dataTotalArea} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "vegetation_removals" && dataVegetationRemovals && (
                                <VegetationRemovalsChart
                                    data={dataVegetationRemovals}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "tree_height" && dataTreeHeights && (
                                <TreeHeightsChart
                                    data={dataTreeHeights}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {/* {pages[modalOpen.id].key === "waterfall" && dataWaterfall && (
                                <WaterfallChart data={dataWaterfall} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )} */}
                            {pages[modalOpen.id].key === "waterfall_foliage" && dataWaterfallFoliage && (
                                <WaterfallFoliage
                                    data={dataWaterfallFoliage}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                    forPDF={false}
                                />
                            )}
                            {pages[modalOpen.id].key === "profit_and_loss" && dataProfitAndLoss && (
                                <ProfitAndLoss
                                    data={dataChangeInCanopy}
                                    preview={true}
                                    forPDF={false}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "profit_and_loss_total" && dataProfitAndLossTotal && (
                                <ProfitAndLossTotal
                                    data={dataProfitAndLossTotal}
                                    forPDF={false}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "change_in_canopy_cover" && dataChangeInCanopy && (
                                <ChangeInCanopy
                                    data={dataChangeInCanopy}
                                    forPDF={false}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        const graphInfo = {
                                            title: pages[modalOpen.id].title,
                                            key: pages[modalOpen.id].key,
                                            id: modalOpen.id,
                                        };
                                        setModalOpen({
                                            id: -1,
                                            status: false,
                                        });
                                        setCurrentPage(graphInfo);
                                    }}
                                    color="primary"
                                >
                                    open graph
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </Grid>
    );
};
