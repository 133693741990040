import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { FilledInput, Grid, CircularProgress } from "@material-ui/core";

const LinkBlock = styled.div`
    width: 100%;
    text-align: center;
    padding-bottom: 20px;

    & a {
        text-decoration: none;
    }
    & a:hover {
        text-decoration: underline;
    }
`;

const CustomButton = styled(Button)`
    background: #ef463b !important;
    color: white;
    border-radius: 20px !important;
    width: 100%;
    font-weight: 600 !important;
    font-size: 16px !important;
    text-transform: none !important;
`;

const LoginDialog = ({ open, setOpen, handleSubmit, isFetching }) => {
    const [fields, setFields] = useState({
        login: "",
        password: "",
    });
    const handleOnchange = (ev) => setFields({ ...fields, [ev.target.name]: ev.target.value });

    return (
        <Dialog fullWidth={true} maxWidth={"xs"} open={open} onClose={() => setOpen(false)}>
            <DialogContent style={{ padding: "40px 30px 20px" }}>
                <Grid container alignItems="center">
                    <FilledInput
                        classes={{ root: "login-root", input: "login-input" }}
                        placeholder="Email"
                        onChange={handleOnchange}
                        disableUnderline={true}
                        name="login"
                        fullWidth={true}
                    />
                </Grid>
                <br></br>
                <Grid container alignItems="center">
                    <FilledInput
                        classes={{ root: "login-root", input: "login-input" }}
                        placeholder="Password"
                        type="password"
                        disableUnderline={true}
                        onChange={handleOnchange}
                        name="password"
                        fullWidth={true}
                    />
                </Grid>
            </DialogContent>

            <DialogActions style={{ padding: "6px 30px 20px" }}>
                {isFetching ? (
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <CustomButton onClick={() => handleSubmit(fields)} color="primary" variant="contained">
                        Sign in
                    </CustomButton>
                )}
            </DialogActions>

            <LinkBlock>
                <a href="mailto:lab@ppdataanalytics.com">Contact us to access LEAF</a>
            </LinkBlock>
        </Dialog>
    );
};

export default LoginDialog;
