import React, { useEffect } from "react";
import { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useDispatch, useSelector } from "react-redux";
import Popper from "@material-ui/core/Popper";
import { removeShape } from "../../Redux/LeftSidebarReducer";
import { getManyTrees, setMode, localChangeIsSelected, getTrees } from "../../Redux/TreesReducer";
import { DrawPolygonMode } from "react-map-gl-draw";
import draw from "../../assets/static/draw_t.png";
import filter from "../../assets/static/filter_t.png";
import layers from "../../assets/static/layers_t.png";
import { makeStyles, Typography } from "@material-ui/core";
import FiltersBlock from "./FiltersBlock";
import { useRef } from "react";
import InfoTooltip from "./InfoTooltip";
import UploadShpModal from "./UploadShpModal";
// import centroid from "@turf/center";
import CircularProgress from "@material-ui/core/CircularProgress";

import { DrawCircleFromCenterModeLabel } from "../../Pages/MapBox/MyDraw";
import UploadModal from "./UploadModal";

import Crop from "../../assets/static/crop_free.svg";
import Circle from "../../assets/static/circle.svg";
import Upload from "../../assets/static/upload.svg";

const useStyles = makeStyles((theme) => ({
    active: {
        background: "white !important",
        color: "rgb(252,66,54)",
    },
    activePopup: {
        background: "rgb(200,200,200,0.2) !important",
        color: "black",
    },
    paper: {
        borderRadius: "0 !important",
        boxShadow: "none",
    },
    popoverList: {
        padding: theme.spacing(2, 3),
    },
    popoverListLayers: {
        padding: "4px 15px",
        justifyContent: "space-between",
        "& .ttle": {
            fontSize: "13px",
        },
    },
    listMain: {
        padding: 0,
        background: "white",
    },
    badge: {
        position: "absolute",
        right: "6px",
        bottom: "32px",
        width: "18px",
        fontSize: "12px",
        color: "white",
        fontWeight: "500 !important",
        background: "rgb(252,66,54)",
        borderRadius: "50%",
        textAlign: "center",
    },
    analyseButtonDiv: {
        display: "flex",
        justifyContent: "center",
        padding: "15px 0",
    },
    margTop: {
        padding: 0,
        marginTop: 40,
        fontSize: 14,

        [theme.breakpoints.down("sm")]: {
            "& img": {
                width: 32,
            },
            marginTop: 0,
            fontSize: 10,
        },
    },
    popoverTitle: {
        fontSize: 18,
        [theme.breakpoints.down("md")]: {
            fontSize: 18,
        },
    },
    mainBar: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: 15,
        alignItems: "center",
        padding: "4px 0 4px 15px",
        borderBottom: "1px solid #C1C1C1",
        background: "#EDEDED",
    },
    mainBar__divider: {
        borderLeft: "1px solid #C1C1C1",
        width: 1,
        height: 20,
        paddingRight: 16,
    },
    small_checkbox: {
        "& svg": {
            fontSize: "19px",
            padding: 0,
        },
        "& span": {
            padding: 0,
        },
    },
}));

const LAYERS_HELP = "Use tick boxes to highlight layers for analysis, or hold [SHIFT] key to select multiple layers on map.";

const LeftSidebarMenu = React.memo(({ region, handleRightDrawerOpen, setViewport, editor }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(-1);
    const [subMenu, setSubMenu] = useState(null);
    // const [selectedPopupItem, setSelectedPopupItem] = useState(-1);
    const [checkedItems, setCheckedItems] = useState([]);
    const [openUpload, setOpenUpload] = useState(false);
    const [openTopModal, setOpenTopModal] = useState(false);
    const [fakeLoading, setFakeLoading] = useState(false);
    // const [fakeData, setFakeData] = useState(null);
    const [allVisible, setAllVisible] = useState(true);

    const shapes = useSelector((state) => state.leftSidebar.shapes);
    const selectedFeatures = useSelector((state) => state.leftSidebar.selectedFeatures);

    const popoverOpen = Boolean(anchorEl);
    const popoverRef = useRef(null);

    useEffect(() => {
        setCheckedItems(selectedFeatures);
    }, [selectedFeatures]);

    const popoverOnClick = (e, itemSubMenu, ind) => {
        if (ind === selectedItem) {
            setSelectedItem(-1);
            setSubMenu(null);
            setAnchorEl(null);
        } else {
            setSelectedItem(ind);
            setSubMenu(itemSubMenu);
            setAnchorEl(e.currentTarget);
        }
    };

    const onHandlePolygonClick = async () => {
        let modeInfo = {
            title: "polygon",
            text: "Select multiple points and close the polygon",
        };
        await dispatch(setMode(new DrawPolygonMode(), modeInfo));
        /*
        if(!sessionStorage.getItem("polygon_modal")) {
            setModalOpen("polygon")
            sessionStorage.setItem("polygon_modal", 1)
        }
        */
        //handleDrawerClose();
        popoverClose();
    };

    const onHandleCircleClick = async () => {
        let modeInfo = {
            title: "circle",
            text: "Choose a location for the center of the circle",
        };
        await dispatch(setMode(new DrawCircleFromCenterModeLabel(), modeInfo));
        /*
        if(!sessionStorage.getItem("circle_modal")) {
            setModalOpen("circle")
            sessionStorage.setItem("circle_modal", 1)
        }
        */
        //handleDrawerClose();
        popoverClose();
    };

    const onHandleUpload = () => {
        setOpenUpload(true);
    };

    const handleAddSelectedShapes = (e, shape, selected, newSelected) => {
        if (e.target.innerText === "delete" || e.target.innerText === "visibility" || e.target.innerText === "visibility_off") {
            return;
        }

        if (shape.properties.isSelected) {
            shape.properties.isSelected = false;
            setCheckedItems(selected.filter((s) => s !== newSelected));
        } else {
            // let cent = centroid(shape);
            /*
            setViewport({
                longitude: cent.geometry.coordinates[0],
                latitude: cent.geometry.coordinates[1],
                transitionDuration: 800
            })
            */
            shape.properties.isSelected = true;
            setCheckedItems([...selected, newSelected]);
        }
        //Функция изменяющая стейт для отрисовки mapbox
        dispatch(localChangeIsSelected());
    };

    const popoverClose = () => {
        setAnchorEl(null);
        setSelectedItem(-1);
        // setSelectedPopupItem(-1);
        setSubMenu(null);
    };

    const handleGetCharts = async () => {
        let mas = [];
        let titles = [];
        shapes.forEach((el) => {
            if (el.properties.isSelected) {
                mas.push(el);
                titles.push(el.properties.title);
            }
            el.properties.isActive = false;
        });
        popoverClose();

        if (titles.length === 1) {
            const result = await dispatch(getTrees(mas[0]));
            if (result) {
                setCheckedItems([]);
                handleRightDrawerOpen();
                shapes.forEach((el) => {
                    if (el.properties.isSelected) {
                        el.properties.isActive = true;
                    }
                    el.properties.isSelected = false;
                });
            }
        } else {
            const result = await dispatch(getManyTrees(mas, titles));
            if (result) {
                setCheckedItems([]);
                handleRightDrawerOpen();
                shapes.forEach((el) => {
                    if (el.properties.isSelected) {
                        el.properties.isActive = true;
                    }
                    el.properties.isSelected = false;
                });
            }
        }
    };

    const pickAllChange = (e) => {
        if (e.target.checked) {
            setCheckedItems([...Array(shapes.length).keys()]);
            shapes.forEach((s) => (s.properties.isSelected = true));
        } else {
            setCheckedItems([]);
            shapes.forEach((s) => (s.properties.isSelected = false));
        }
        dispatch(localChangeIsSelected());
    };

    const handleAllVisible = () => {
        setAllVisible((prev) => !prev);
        shapes.forEach((s) => (s.properties.isVisible = !allVisible));
        dispatch(localChangeIsSelected());
    };

    const menuItems = [
        {
            title: "DRAW",
            icon: draw,
            iconSize: 28,
            subMenu: [
                {
                    title: "POLYGON",
                    // icon: "crop_free",
                    icon: Crop,
                    help: "Click to draw points to form polygon. Double click to close shape.",
                    func: onHandlePolygonClick,
                },
                {
                    title: "CIRCLE",
                    // icon: "radio_button_unchecked",
                    icon: Circle,
                    help: "Click to select centre of circle. Drag and click to select extent of circle.",
                    func: onHandleCircleClick,
                },
                {
                    title: "UPLOAD",
                    // icon: "backup",
                    icon: Upload,
                    help: "Upload a shapefile from a local drive",
                    func: onHandleUpload,
                },
                // {
                //     title: "UPLOAD LAYER",
                //     icon: "backup",
                //     help: "Upload a .geojson from a local drive",
                //     func: () => setOpenTopModal(true),
                // },
            ],
        },
        {
            title: "LAYERS",
            icon: layers,
            iconSize: 42,
            subMenu: shapes.map((s, c) => ({
                title: s.properties.title || `Shape ${c}`,
                icon: s.properties.shape === "Circle" ? "radio_button_unchecked" : "crop_free",
                isSelected: s.properties.isSelected || false,
                isVisible: s.properties.isVisible,
                shape: s,
                func: (params) => handleAddSelectedShapes(params.e, s, params.checkedItems, c),
            })),
        },
        {
            title: "FILTERS",
            iconSize: 34,
            icon: filter,
        },
    ];

    const handleRemove = (index) => {
        setFakeLoading(true);
        setCheckedItems((prev) => prev.filter((v) => v !== index));
        let filt = shapes.filter((s, c) => c !== index);
        setSubMenu(
            filt.map((s, c) => ({
                title: s.properties.title || `Shape ${c}`,
                icon: s.properties.shape === "Circle" ? "radio_button_unchecked" : "crop_free",
                isSelected: s.properties.isSelected || false,
                shape: s,
                func: (params) => handleAddSelectedShapes(params.e, s, params.checkedItems, c),
            }))
        );
        editor.current.deleteFeatures(index);
        dispatch(removeShape(index));
        setTimeout(() => setFakeLoading(false), 700);
    };

    const handleVisible = (index) => {
        shapes[index].properties.isVisible = !shapes[index].properties.isVisible;
        setSubMenu(
            shapes.map((s, c) => ({
                title: s.properties.title || `Shape ${c}`,
                icon: s.properties.shape === "Circle" ? "radio_button_unchecked" : "crop_free",
                isSelected: s.properties.isSelected || false,
                isVisible: index === c ? !shapes[index].properties.isVisible : s.properties.isVisible,
                shape: s,
                func: (params) => handleAddSelectedShapes(params.e, s, params.checkedItems, c),
            }))
        );
        dispatch(localChangeIsSelected());
    };

    return (
        <>
            <List className={classes.margTop} ref={popoverRef}>
                {menuItems.map((item, index) => (
                    <ListItem
                        style={{ flexDirection: "column", width: "100%", padding: "10px 0" }}
                        button
                        selected={selectedItem === index}
                        classes={{ selected: classes.active }}
                        onClick={(e) => popoverOnClick(e, item.subMenu, index)}
                        key={index}
                    >
                        <div>
                            <img src={item.icon} width={item.iconSize} alt={"icon"} />
                        </div>
                        <div
                            className="title__txt"
                            style={{ fontSize: "14px", fontWeight: "700", letterSpacing: "0.3px", marginTop: index === 1 ? "-8px" : "0" }}
                        >
                            {item.title}
                        </div>
                        {item.title === "LAYERS" && shapes.length ? (
                            <ListItemText>
                                <div className={classes.badge}>{shapes.length}</div>
                            </ListItemText>
                        ) : (
                            ""
                        )}
                    </ListItem>
                ))}
            </List>

            <Popper
                open={popoverOpen}
                anchorEl={popoverRef.current}
                onClose={popoverClose}
                classes={{ paper: classes.paper }}
                placement={"right-start"}
            >
                <List className={classes.listMain}>
                    <div style={{ maxHeight: 300, overflow: "auto" }}>
                        {selectedItem === 1 && shapes.length ? (
                            <div className={classes.mainBar}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox className={classes.small_checkbox} onChange={pickAllChange} color="primary" />
                                    <div className={classes.mainBar__divider}></div>
                                    <div style={{ fontSize: 16, fontWeight: "500" }}>Name</div>
                                    <InfoTooltip text={LAYERS_HELP} style={{ marginTop: 7 }} />
                                </div>
                                <Icon onClick={handleAllVisible} style={{ cursor: "pointer", marginRight: 45, color: "grey", fontSize: 20 }}>
                                    {allVisible ? "visibility" : "visibility_off"}
                                </Icon>
                            </div>
                        ) : (
                            ""
                        )}

                        {fakeLoading ? (
                            <div style={{ minWidth: 197, display: "flex", justifyContent: "center", minHeight: 70, alignItems: "center" }}>
                                <CircularProgress />
                            </div>
                        ) : subMenu && subMenu.length ? (
                            subMenu.map((item, index) => (
                                <ListItem
                                    key={index}
                                    classes={{ selected: !item.shape ? classes.activePopup : "" }}
                                    className={!item.shape ? classes.popoverList : classes.popoverListLayers}
                                    button
                                    onClick={(e) => {
                                        // setSelectedPopupItem(index);
                                        item.func && item.func({ e, checkedItems, shape: item.shape });
                                    }}
                                >
                                    <ListItemIcon>
                                        {item.shape ? (
                                            <>
                                                <Checkbox className={classes.small_checkbox} color="primary" checked={checkedItems.includes(index)} />
                                            </>
                                        ) : (
                                            // <Icon style={{ color: "black" }}>{item.icon}</Icon>
                                            <img src={item.icon} width="24" alt={item.name} />
                                        )}
                                    </ListItemIcon>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            minWidth: selectedItem === 1 ? "197px" : "100px",
                                            maxWidth: "197px",
                                            wordBreak: "break-all",
                                            justifyContent: selectedItem === 1 ? "space-between" : "initial",
                                        }}
                                    >
                                        <div className={"ttle " + classes.popoverTitle} style={{ minWidth: item.help ? "80px" : "auto" }}>
                                            {selectedItem === 1 ? <Typography style={{ fontSize: 13 }}>{item.title}</Typography> : item.title}
                                        </div>
                                        {selectedItem === 1 && (
                                            <div style={{ display: "flex", gap: 10 }}>
                                                <Icon style={{ color: "grey", fontSize: 20 }} onClick={() => handleVisible(index)}>
                                                    {item.shape.properties.isVisible ? "visibility" : "visibility_off"}
                                                </Icon>
                                                <Icon style={{ color: "grey", fontSize: 20 }} onClick={() => handleRemove(index)}>
                                                    delete
                                                </Icon>
                                            </div>
                                        )}
                                        {item.help && <InfoTooltip text={item.help} />}
                                    </div>
                                </ListItem>
                            ))
                        ) : (
                            selectedItem === 1 && <ListItem>{<ListItemText primary={"No Layers"} />}</ListItem>
                        )}
                    </div>
                    {selectedItem === 1 && subMenu && subMenu.length ? (
                        <div className={classes.analyseButtonDiv}>
                            <Button
                                disabled={!checkedItems.length}
                                onClick={handleGetCharts}
                                className={classes.analyseButton}
                                size="small"
                                color="secondary"
                                variant="contained"
                            >
                                Analyse
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </List>
                {selectedItem === 2 && <FiltersBlock onClose={popoverClose} region={region} />}
            </Popper>

            <UploadShpModal open={openUpload} editor={editor} setViewport={setViewport} setOpen={setOpenUpload} popoverClose={popoverClose} />
            <UploadModal open={openTopModal} setOpen={setOpenTopModal} setViewport={setViewport} popoverClose={popoverClose} />
        </>
    );
});

export default LeftSidebarMenu;
