import { Button, CircularProgress, Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRegions } from "../../Redux/RegionReducer";

// const treeStyle = {
//     id: `poly22`,
//     type: "fill",
//     paint: {
//         "fill-color": "red",
//         "fill-antialias": false,
//         "fill-opacity": 1,
//     },
// };

// const geojson = {
//     type: "FeatureCollection",
//     features: [
//         {
//             type: "Feature",
//             properties: {},
//             geometry: {
//                 type: "Polygon",
//                 coordinates: [
//                     [
//                         [145.13368606567383, -38.09998264736479],
//                         [145.12922286987305, -38.11484063839788],
//                         [145.15780448913574, -38.12301124530993],
//                         [145.16767501831055, -38.10801984500079],
//                         [145.15377044677734, -38.094849184564104],
//                         [145.13368606567383, -38.09998264736479],
//                     ],
//                 ],
//             },
//         },
//     ],
// };

const RegionsMenu = () => {
    const dispatch = useDispatch();

    const regions = useSelector((state) => state.regions.regions);
    const isFetching = useSelector((state) => state.trees.isFetching);

    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        dispatch(getRegions());
    }, [dispatch]);

    // const [open, setOpen] = useState(false);

    return (
        <Card style={{ width: "400px", margin: "80px" }}>
            <h3 style={{ margin: "20px 10px" }}>Regions</h3>
            <List style={{ padding: "0" }}>
                {isFetching ? (
                    <CircularProgress style={{ margin: "-10px 20px 10px" }} />
                ) : regions.length ? (
                    regions.map((region) => (
                        <div key={region.id}>
                            <ListItem
                                selected={selectedItem === region.id}
                                button
                                onClick={() => (selectedItem === region.id ? setSelectedItem(null) : setSelectedItem(region.id))}
                            >
                                <ListItemText>{region.title}</ListItemText>
                                {region.id === selectedItem ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={selectedItem === region.id}>
                                <Button component={Link} variant="contained" color="primary" to={`/region/${region.title}`}>
                                    Open
                                </Button>
                            </Collapse>
                        </div>
                    ))
                ) : (
                    <em>-No Regions Available-</em>
                )}
            </List>

            {/*
            <h3>Map</h3>
            <div style={{ width:"534px", height:"400px" }}>
                <StaticMap 
                    width="100%"
                    height="100%"
                    visible={true}
                    attributionControl={false}
                    preserveDrawingBuffer={true}
                    mapboxApiAccessToken={process.env.MAPBOX_TOKEN}  
                    latitude={-38.09998264736479}     
                    longitude={145.13368606567383}                                
                    zoom={12}
                    onLoad={(e) => {
                        //document.querySelector(`#print_butt_ind`).removeAttribute("disabled")                                                
                        //document.querySelector(`#poly_img_ind`).src = e.target.getCanvas().toDataURL()
                    }}
                >   
                    {
                        <Source id={"poly22"} type="geojson" data={geojson}>
                            <Layer {...treeStyle} />
                        </Source>
                    }
                    
                    
                </StaticMap>
            </div>
			*/}
        </Card>
    );
};

export default RegionsMenu;
