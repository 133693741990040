import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import NavBar from "../Modules/NavBar";
import AnalystProfile from "../Pages/AnalystProfile/index.tsx";
import MainContent from "../Pages/MainContent";
import RegionsMenu from "../Pages/RegionsMenu/RegionsMenu";
import UploadRegions from "../Pages/UploadRegion/UploadRegions";
import { initializeApp } from "../Redux/AppReducer";
import { hideSnackbar } from "../Redux/SnackbarReducer";
import { getAuthUserData } from "../Redux/UserReducer.ts";
import LandingPage from "../Landing/Landing";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        if (window.localStorage.getItem("auth_token")) {
            Promise.allSettled([dispatch(getAuthUserData())]).then(() => dispatch(initializeApp(true)));
        } else {
            dispatch(initializeApp(true));
        }
    }, [dispatch]);

    const isAuth = useSelector((state) => state.user.user);
    const isInitialized = useSelector((state) => state.app.isInitialized);

    const open = useSelector((state) => state.snackbar.isShow);
    const title = useSelector((state) => state.snackbar.title);
    const variant = useSelector((state) => state.snackbar.variant);
    const duration = useSelector((state) => state.snackbar.duration);

    const location = useLocation();
    const split = location.pathname.split("/");

    const handleSnackClose = () => {
        dispatch(hideSnackbar());
    };

    if (!isInitialized) {
        return null;
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={duration || 3000}
                open={open}
                onClose={handleSnackClose}
            >
                <Alert severity={variant || "info"} onClose={handleSnackClose}>
                    {title}
                </Alert>
            </Snackbar>

            {split[1] !== "region" && split[1] !== "landing" && (split[1] === "" && !isAuth) === false && <NavBar />}
            {isAuth ? (
                <Routes>
                    <Route exact path="/" element={<RegionsMenu />} />
                    <Route path="/region/:region" element={<MainContent />} />
                    <Route path="/landing" element={<LandingPage />} />

                    {isAuth.role === 2 && (
                        <>
                            <Route exact path="/profile" element={<Navigate to="/profile/regions" />} />
                            <Route exact path="/profile/regions" element={<AnalystProfile />} />
                            <Route exact path="/profile/users" element={<AnalystProfile />} />
                            <Route exact path="/profile/analytics" element={<AnalystProfile />} />
                            <Route exact path="/upload/:region" element={<UploadRegions />} />
                        </>
                    )}

                    <Route path="*" element={<RegionsMenu />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            )}
        </>
    );
}

export default App;
