import React from "react";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/UserReducer.ts";
import { LoginForm } from "../../Modules";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        display: "flex",
        top: "50%",
        left: "50%",
        marginRight: "-50%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px 0px 40px 0",
        background: "#ffff",
        borderRadius: "18px",
        transform: "translate(-50%, -50%)",
        boxShadow: "0 0 25px rgba(0, 0, 0, 0.04)",
    },
}));

const Auth = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const onSubmit = (login1, pass) => {
        dispatch(login(login1, pass));
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h4">
                    Sign in
                </Typography>
                <LoginForm name="Sign in" onSubmit={onSubmit} />
            </div>
        </Container>
    );
};

export default Auth;
