import { Button, LinearProgress } from "@material-ui/core";
import jszip from "jszip";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getRegionInfo, uploadRegion } from "../../Redux/RegionReducer";
import { showSnackbar } from "../../Redux/SnackbarReducer";

const yearRegex = /^\d{4}$/;
const firstProperties = /ies":(.*}),\s"ge/;

const parseFile = (file, callback) => {
    var chunkSize = 64 * 1024; // bytes
    var offset = 0;
    var chunkReaderBlock = null;

    var readEventHandler = function (evt) {
        if (evt.target.error == null) {
            offset += evt.target.result.length;
            return callback(evt.target.result); // callback for handling read chunk
        }
    };

    chunkReaderBlock = function (_offset, length, _file) {
        var r = new FileReader();
        var blob = _file.slice(_offset, length + _offset);
        r.onload = readEventHandler;
        r.readAsText(blob);
    };

    // now let's start the read with the first block
    chunkReaderBlock(offset, chunkSize, file);
};

const UploadRegions = () => {
    const dispatch = useDispatch();
    const { region } = useParams();

    useEffect(() => {
        dispatch(getRegionInfo(region));
    }, [dispatch, region]);

    const uploadProgress = useSelector((state) => state.regions.uploadProgress);
    const regionInfo = useSelector((state) => state.regions.regionInfo);

    const handleUploadFile = async (ev) => {
        try {
            let file = ev.target.files[0];

            const cb = async (e) => {
                try {
                    let r = firstProperties.exec(e);
                    console.log(r);
                    const data = JSON.parse(r[1], function (prop, value) {
                        var lower = prop.toLowerCase();
                        if (prop === lower) {
                            return value;
                        } else {
                            this[lower] = value;
                        }
                    });

                    const isValid = ({ veg_id, type, shape_area, landuse, li_2017_ma, year, first_yr, last_yr }) => {
                        if (typeof veg_id !== "string") {
                            throw new Error(`Invalid "veg_id" Not String`);
                        }
                        if (typeof type !== "string") {
                            throw new Error(`Invalid "type" Not String`);
                        }
                        if (typeof shape_area !== "number") {
                            throw new Error(`Invalid "shape_area" Not Number`);
                        }
                        if (typeof landuse !== "string") {
                            throw new Error(`Invalid "landuse" Not String`);
                        }
                        if (typeof li_2017_ma !== "number") {
                            throw new Error(`Invalid "li_2017_ma" Not Number`);
                        }
                        if (!yearRegex.test(parseInt(year))) {
                            throw new Error(`Invalid "year" Not mask "xxxx"`);
                        }
                        if (!yearRegex.test(parseInt(first_yr))) {
                            throw new Error(`Invalid "first_yr" Not mask "xxxx"`);
                        }
                        if (!yearRegex.test(parseInt(last_yr))) {
                            throw new Error(`Invalid "last_yr" Not mask "xxxx"`);
                        }
                        return true;
                    };

                    if (isValid(data)) {
                        let filename = region;
                        console.log(file);
                        const zip = jszip();
                        zip.file(filename, file);

                        zip.generateAsync({
                            type: "blob",
                            compression: "DEFLATE",
                            compressionOptions: {
                                level: 4,
                            },
                        }).then(async (content) => {
                            //dispatch(uploadTest(regionInfo.title, content))
                            await dispatch(uploadRegion(regionInfo.id, regionInfo.title, content));
                            dispatch(showSnackbar("Success load", "success"));
                        });
                    }
                } catch (e) {
                    console.log(e);
                    dispatch(showSnackbar(e.message, "error"));
                    ev.target.value = "";
                }
            };
            parseFile(file, cb);
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar(e.message, "error"));
            ev.target.value = "";
        }
    };

    return (
        <div style={{ maxWidth: "1080px", margin: "0 auto" }}>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                <Button
                    disabled={uploadProgress !== -1 && uploadProgress !== 100}
                    onChange={(e) => handleUploadFile(e)}
                    component="label"
                    variant="contained"
                    style={{ float: "right" }}
                >
                    Upload Region <input type="file" hidden />
                </Button>
                <hr></hr>
                <LinearProgress variant="determinate" value={uploadProgress} style={{ width: "100%", padding: "5px 0" }} />
            </div>

            <div style={{ marginTop: 40 }}>
                <h4>Region ID: {regionInfo?.id}</h4>
                Steps:
                <ul>
                    <li>Uploading a file to the client</li>
                    <li>Compression to .zip archive</li>
                    <li>Upload to server (progress bar)</li>
                    <li>Unzip archive</li>
                    <li>Running script to add centroids to postgres</li>
                    <li>Running script to add all data to postgres</li>
                    <li>launching a script that generates Current, Gains, Losses files</li>
                    <li>Show snackbar "Success load"</li>
                </ul>
            </div>

            <div style={{ marginTop: 40 }}>
                Required fields:
                <ul>
                    <li>
                        <b>veg_id</b> - String
                    </li>
                    <li>
                        <b>type</b> - String
                    </li>
                    <li>
                        <b>landuse</b> - String
                    </li>
                    <li>
                        <b>shape_area</b> - Float or Int
                    </li>
                    <li>
                        <b>li_2017_ma</b> - Float or int
                    </li>
                    <li>
                        <b>year</b> - mask "xxxx" where x is number
                    </li>
                    <li>
                        <b>first_yr</b>- mask "xxxx" where x is number
                    </li>
                    <li>
                        <b>last_yr</b> - mask "xxxx" where x is number
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default UploadRegions;
