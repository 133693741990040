import React from "react";
import Plot from "react-plotly.js";

type TTotalProfitAndLoss = {
    NewPlanting: {
        x: number;
        y: string;
        text: string;
    };
    FoliageGain: {
        x: number;
        y: string;
        text: string;
    };
    Net: {
        x: number;
        y: string;
        text: string;
    };
    Retained: {
        x: number;
        y: string;
        text: string;
    };
    TreeRemoval: {
        x: number;
        y: string;
        text: string;
    };
    FoliageLoss: {
        x: number;
        y: string;
        text: string;
    };
};

const TotalProfitAndLoss: React.FC<{
    data: TTotalProfitAndLoss;
    mode: "polygon_area" | "total_area" | "sqm";
    polygonArea: number;
    regionArea: number;
    preview: boolean;
    forPDF: boolean;
}> = ({ data, mode, polygonArea, regionArea, preview = false, forPDF = false }) => {
    const modifiedProfitAndLossData = (() => {
        if (mode === "polygon_area") {
            return {
                newPlantingX: (data.NewPlanting.x / polygonArea) * 100,
                newPlantingText: `${((parseFloat(data.NewPlanting.text) / polygonArea) * 100).toFixed(2)} %`,

                foliageGainX: (data.FoliageGain.x / polygonArea) * 100,
                foliageGainText: `${((parseFloat(data.FoliageGain.text) / polygonArea) * 100).toFixed(2)} %`,

                netX: (data.Net.x / polygonArea) * 100,
                netText: `${((parseFloat(data.Net.text) / polygonArea) * 100).toFixed(2)} %`,

                retainedText: `${((parseFloat(data.Retained.text) / polygonArea) * 100).toFixed(2)} %`,

                treeRemovalX: (data.TreeRemoval.x / polygonArea) * 100,
                treeRemovalText: `-${((parseFloat(data.TreeRemoval.text) / polygonArea) * 100).toFixed(2)} %`,

                foliageLossX: (data.FoliageLoss.x / polygonArea) * 100,
                foliageLossText: `-${((parseFloat(data.FoliageLoss.text) / polygonArea) * 100).toFixed(2)} %`,
            };
        }
        if (mode === "total_area") {
            return {
                newPlantingX: (data.NewPlanting.x / regionArea) * 100,
                newPlantingText: `${((parseFloat(data.NewPlanting.text) / regionArea) * 100).toFixed(2)} %`,

                foliageGainX: (data.FoliageGain.x / regionArea) * 100,
                foliageGainText: `${((parseFloat(data.FoliageGain.text) / regionArea) * 100).toFixed(2)} %`,

                netX: (data.Net.x / regionArea) * 100,
                netText: `${((parseFloat(data.Net.text) / regionArea) * 100).toFixed(2)} %`,

                retainedText: `${((parseFloat(data.Retained.text) / regionArea) * 100).toFixed(2)} %`,

                treeRemovalX: (data.TreeRemoval.x / regionArea) * 100,
                treeRemovalText: `-${((parseFloat(data.TreeRemoval.text) / regionArea) * 100).toFixed(2)} %`,

                foliageLossX: (data.FoliageLoss.x / regionArea) * 100,
                foliageLossText: `-${((parseFloat(data.FoliageLoss.text) / regionArea) * 100).toFixed(2)} %`,
            };
        } else if (mode === "sqm") {
            return {
                newPlantingX: data.NewPlanting.x,
                newPlantingText: data.NewPlanting.text,

                foliageGainX: data.FoliageGain.x,
                foliageGainText: data.FoliageGain.text,

                netX: data.Net.x,
                netText: data.Net.text,

                retainedText: data.Retained.text,

                treeRemovalX: data.TreeRemoval.x,
                treeRemovalText: `-${data.TreeRemoval.text}`,

                foliageLossX: data.FoliageLoss.x,
                foliageLossText: `-${data.FoliageLoss.text}`,
            };
        }
    })();

    const sum =
        Math.abs(data.NewPlanting.x) +
        Math.abs(data.FoliageGain.x) +
        Math.abs(data.Net.x) +
        Math.abs(data.TreeRemoval.x) +
        Math.abs(data.FoliageLoss.x);

    const domains = {
        x1: ((Math.abs(data.NewPlanting.x) + Math.abs(data.FoliageGain.x)) / sum) * 0.85,
        x2: ((Math.abs(data.FoliageLoss.x) + Math.abs(data.TreeRemoval.x)) / sum) * 0.85,
        x3: (Math.abs(data.Net.x) / sum) * 0.85,
        x4: 0.15,
    };

    const profitAndLossData = [
        {
            marker: { color: "rgb(0, 117, 94)" },
            name: "New Planting",
            offsetgroup: 1,
            orientation: "h",
            type: "bar",
            x: [modifiedProfitAndLossData?.newPlantingX],
            y: [mode === "sqm" ? "sqm" : "%"],
            text: [modifiedProfitAndLossData?.newPlantingText],
            xaxis: "x1",
        },
        {
            marker: { color: "rgb(41, 171, 136)" },
            name: "Foliage Gain",
            offsetgroup: 1,
            orientation: "h",
            type: "bar",
            x: [modifiedProfitAndLossData?.foliageGainX],
            y: [mode === "sqm" ? "sqm" : "%"],
            xaxis: "x1",
            text: modifiedProfitAndLossData?.foliageGainText,
        },

        {
            marker: { color: "rgb(166, 42, 42)" },
            name: "Tree Removal",
            offsetgroup: 1,
            orientation: "h",
            type: "bar",
            x: [modifiedProfitAndLossData?.treeRemovalX],
            y: [mode === "sqm" ? "sqm" : "%"],
            text: [modifiedProfitAndLossData?.treeRemovalText],
            xaxis: "x2",
        },
        {
            marker: { color: "rgb(227, 66, 52)" },
            name: "Foliage Loss",
            offsetgroup: 1,
            orientation: "h",
            type: "bar",
            x: [modifiedProfitAndLossData?.foliageLossX],
            y: [mode === "sqm" ? "sqm" : "%"],

            xaxis: "x2",
            text: modifiedProfitAndLossData?.foliageLossText,
        },
        {
            marker: { color: "rgb(173, 216, 230)" },
            name: "Net Change",
            offsetgroup: 1,
            orientation: "h",
            type: "bar",
            x: [modifiedProfitAndLossData?.netX],
            y: [mode === "sqm" ? "sqm" : "%"],

            xaxis: "x3",
            text: modifiedProfitAndLossData?.netText,
        },
        {
            marker: { color: "rgb(173, 216, 230)" },
            name: "Canopy Cover",
            offsetgroup: 1,
            orientation: "h",
            type: "bar",
            x: [data.Retained.x],
            y: [mode === "sqm" ? "sqm" : "%"],
            xaxis: "x4",
            text: modifiedProfitAndLossData?.retainedText,
            textposition: "outside",
            showlegend: false,
        },
    ];

    const layout: Partial<Plotly.Layout> = {
        barmode: "stack",
        font: {
            size: 14,
        },
        width: (preview === true && 900) || (forPDF === true && 900) || 1100,
        height: (preview === true && 300) || 350,
        margin: { t: 140, r: 10, b: 80, l: 120 },
        paper_bgcolor: "rgb(255, 255, 255)",
        plot_bgcolor: "rgb(255, 255, 255)",
        showlegend: true,
        legend: {
            traceorder: "normal",
            xanchor: "right",
            yanchor: "bottom",
            y: -0.3,
            x: 0.85,
            orientation: "h",
        },
        yaxis: {
            showticklabels: true,
            tickangle: 270,
        },
        xaxis: {
            domain: [0, domains.x1],
            anchor: "x",
            title: "<b>Canopy Addition</b>",
            side: "top",
            showline: false,
            showgrid: false,
            showticklabels: false,
            zeroline: false,
        },
        xaxis2: {
            domain: [domains.x1, domains.x1 + domains.x2],
            anchor: "x2",
            title: "<b>Canopy Subtraction</b>",
            side: "top",

            showline: false,
            showgrid: false,
            showticklabels: false,
            zeroline: false,
        },
        xaxis3: {
            domain: [domains.x1 + domains.x2, domains.x1 + domains.x2 + domains.x3],
            anchor: "x3",
            title: "<b>Net Change</b>",
            side: "top",

            showline: false,
            showgrid: false,
            showticklabels: false,
            zeroline: false,
        },
        xaxis4: {
            domain: [domains.x1 + domains.x2 + domains.x3, 1.0],
            anchor: "x3",
            title: "<b>Canopy Cover</b>",
            side: "top",

            showline: false,
            showgrid: false,
            showticklabels: false,
            zeroline: false,
        },
    };

    return (
        <div>
            <Plot data={profitAndLossData as any} layout={layout} config={{ displaylogo: false }} useResizeHandler />
        </div>
    );
};

export default TotalProfitAndLoss;
