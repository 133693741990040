import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";
import FileInfo from "./FileInfo";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../Redux/SnackbarReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import { DropzoneArea } from "material-ui-dropzone";
import UploadHelpModal from "./UploadHelpModal";
import { setCustomDataset } from "../../Redux/TreesReducer";
import { setHeightFilterZoneCustom, setYearsDiapozonCustom } from "../../Redux/FilterReducer";
import { useSelector } from "react-redux";

const jsgradient = {
    inputA: "",
    inputB: "",
    inputC: "",
    gradientElement: "",

    // Convert a hex color to an RGB array e.g. [r,g,b]
    // Accepts the following formats: FFF, FFFFFF, #FFF, #FFFFFF
    hexToRgb: function (hex) {
        var r, g, b;
        // Remove the hash if given
        hex = hex.replace("#", "");
        // If invalid code given return white
        if (hex.length !== 3 && hex.length !== 6) {
            return [255, 255, 255];
        }
        // Double up charaters if only three suplied
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        // Convert to [r,g,b] array
        r = parseInt(hex.substr(0, 2), 16);
        g = parseInt(hex.substr(2, 2), 16);
        b = parseInt(hex.substr(4, 2), 16);

        return [r, g, b];
    },

    // Converts an RGB color array e.g. [255,255,255] into a hexidecimal color value e.g. 'FFFFFF'
    rgbToHex: function (color) {
        // Set boundries of upper 255 and lower 0
        color[0] = color[0] > 255 ? 255 : color[0] < 0 ? 0 : color[0];
        color[1] = color[1] > 255 ? 255 : color[1] < 0 ? 0 : color[1];
        color[2] = color[2] > 255 ? 255 : color[2] < 0 ? 0 : color[2];

        return this.zeroFill(color[0].toString(16), 2) + this.zeroFill(color[1].toString(16), 2) + this.zeroFill(color[2].toString(16), 2);
    },

    // Pads a number with specified number of leading zeroes
    zeroFill: function (number, width) {
        width -= number.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
        }
        return number;
    },

    // Generates an array of color values in sequence from 'colorA' to 'colorB' using the specified number of steps
    generateGradient: function (colorA, colorB, steps) {
        var result = [];

        colorA = this.hexToRgb(colorA); // [r,g,b]
        colorB = this.hexToRgb(colorB); // [r,g,b]
        steps -= 1; // Reduce the steps by one because we're including the first item manually

        // Calculate the intervals for each color
        var rStep = (Math.max(colorA[0], colorB[0]) - Math.min(colorA[0], colorB[0])) / steps;
        var gStep = (Math.max(colorA[1], colorB[1]) - Math.min(colorA[1], colorB[1])) / steps;
        var bStep = (Math.max(colorA[2], colorB[2]) - Math.min(colorA[2], colorB[2])) / steps;

        result.push("#" + this.rgbToHex(colorA));

        // Set the starting value as the first color value
        var rVal = colorA[0],
            gVal = colorA[1],
            bVal = colorA[2];

        // Loop over the steps-1 because we're includeing the last value manually to ensure it's accurate
        for (var i = 0; i < steps - 1; i++) {
            // If the first value is lower than the last - increment up otherwise increment down
            rVal = colorA[0] < colorB[0] ? rVal + Math.round(rStep) : rVal - Math.round(rStep);
            gVal = colorA[1] < colorB[1] ? gVal + Math.round(gStep) : gVal - Math.round(gStep);
            bVal = colorA[2] < colorB[2] ? bVal + Math.round(bStep) : bVal - Math.round(bStep);
            result.push("#" + this.rgbToHex([rVal, gVal, bVal]));
        }

        result.push("#" + this.rgbToHex(colorB));

        return result;
    },
};

const SELECTS = [
    { name: "landuse", label: "Landuse" },
    { name: "height", label: "Height" },
    { name: "year", label: "Year" },
];

const yearRegex = /^\d{4}$/g;

const ExampleFeature = ({ data, selectedFields, isConfigured }) => {
    if (!data) {
        return null;
    }
    const elem = data.features[0];
    let object = {
        type: "Feature",
        properties: {},
    };

    if (isConfigured && Object.keys(selectedFields).length) {
        let el = data.features.find((f) => f.properties[selectedFields["height"]] > 0);
        if (selectedFields["landuse"]) {
            object.properties["landuse"] = elem.properties[selectedFields["landuse"]];
        }
        if (selectedFields["height"]) {
            object.properties["height"] = el ? el.properties[selectedFields["height"]] : 0;
        }
        if (selectedFields["year"]) {
            object.properties["year"] = elem.properties[selectedFields["year"]];
        }
    } else {
        object.properties = {
            ...elem.properties,
        };
    }

    return (
        <div style={{ maxHeight: "200px", marginTop: "20px", background: "#EAEAEA", borderRadius: "4px" }}>
            <div style={{ padding: "10px 0 0 10px" }}>{isConfigured ? "Customizable feature" : "Your feature"}</div>
            <pre style={{ background: "#EAEAEA", padding: "0 10px", overflow: "hidden" }}>{JSON.stringify(object, null, "   ")}</pre>
        </div>
    );
};

const getRandomHex = () => "#" + Math.floor(Math.random() * 16777216).toString(16);

const UploadModal = ({ open, setOpen, setViewport, popoverClose }) => {
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [helpOpen, setHelpOpen] = useState(false);
    const [color, setColor] = useState(getRandomHex());
    const [title, setTitle] = useState("");
    const [file, setFile] = useState(null);
    const [data, setData] = useState(null);
    const [fields, setFields] = useState(null);
    const [isConfigured, setIsConfigured] = useState(false);

    const [isHexagon, setIsHexagon] = useState(false);
    const [fromHexColor, setFromHexColor] = useState("#dcf5e9");
    const [toHexColor, setToHexColor] = useState("#226633");

    const [selectedFields, setSelectedFields] = useState({});
    const [selectedFieldsHex, setSelectedFieldsHex] = useState({});

    const customDatasets = useSelector((state) => state.trees.customDatasets);

    const handleRemoveFile = () => {
        setFile(null);
        setData(null);
        setIsConfigured(false);
        setSelectedFields({});
        setFields(null);
        setSelectedFields({});
        setTitle("");
        setColor(getRandomHex());
        popoverClose();
    };

    const handleFileChange = (input) => {
        if (!input.length) {
            return;
        }
        setIsUploading(true);
        let file = input[0];
        let reader = new FileReader();
        reader.readAsText(file);

        reader.onload = function () {
            try {
                let d = JSON.parse(reader.result);
                let poss_landuses = [];
                let poss_years = [];
                let poss_height = [];

                Object.keys(d.features[0].properties).forEach((key) => {
                    let val = d.features[0].properties[key];
                    let type = typeof val;
                    if (yearRegex.test(val)) {
                        poss_years.push(key);
                    } else if (type === "string") {
                        poss_landuses.push(key);
                    } else if (type === "number") {
                        poss_height.push(key);
                    }
                });

                setFields({
                    landuse: poss_landuses,
                    year: poss_years,
                    height: poss_height,
                });
                setFile(file);
                setData(d);
                setIsUploading(false);
            } catch (e) {
                dispatch(showSnackbar(e.message, "error"));
                setIsUploading(false);
            }
        };

        reader.onerror = function () {
            dispatch(showSnackbar("Something went wrong", "error"));
            console.log(reader.error);
            setIsUploading(false);
        };
    };

    const handleAddToMap = () => {
        let isExist = Object.keys(customDatasets).find((key) => key === title);
        if (isExist) {
            dispatch(showSnackbar(`Title "${title}" already exist`, "warning"));
            return;
        }

        const landuses = new Map();
        const years = new Map();

        let max_height = 0;

        const dataset = {
            [title]: {
                color,
                isShow: true,
                isFiltered: false,
                years: [],
                landuses: [],
                maxHeight: 0,
            },
        };

        if (Object.keys(selectedFields).length) {
            data.features.forEach((el) => {
                let landuse = el.properties[selectedFields["landuse"]];
                let year = parseInt(el.properties[selectedFields["year"]]);
                let height = el.properties[selectedFields["height"]];
                if (!landuses.has(landuse)) {
                    landuses.set(landuse, 1);
                }
                if (!years.has(year)) {
                    years.set(year, 1);
                }
                if (height > max_height) {
                    max_height = height;
                }

                el.properties = {
                    landuse,
                    li_2017_ma: height,
                    year,
                };
            });
            let land = [...landuses.keys()].filter((k) => k !== "");
            let sorted_years = [...years.keys()].sort((a, b) => a - b);

            dispatch(setHeightFilterZoneCustom(land));
            dispatch(setYearsDiapozonCustom(sorted_years));

            dataset[title] = {
                ...dataset[title],
                isFiltered: true,
                landuses: land,
                years: sorted_years,
                maxHeight: Math.ceil(max_height),
            };
        }

        if (selectedFieldsHex["hex"]) {
            delete dataset[title].color;
            const field = selectedFieldsHex["hex"];
            let max_value = 0,
                min_value = 1000;
            let colorSteps = 6;
            //'#dcf5e9' to '#226633'
            const gradient = jsgradient.generateGradient(fromHexColor, toHexColor, colorSteps);

            data.features.forEach((el) => {
                let hexValue = el.properties[field];

                if (hexValue > max_value) {
                    max_value = hexValue;
                } else if (hexValue < min_value) {
                    min_value = hexValue;
                }
            });

            const step = max_value / colorSteps;
            const obj = {};
            let sm = 0;
            gradient.forEach((g, c) => {
                obj[c] = {
                    value: g,
                    min: sm,
                    max: sm + step,
                };
                obj[c].check = (v) => v >= obj[c].min && v <= obj[c].max;
                sm += step;
            });

            const isOnlyHex = Boolean(!Object.keys(selectedFields).length);

            data.features.forEach((el) => {
                let hexValue = el.properties[field];
                Object.keys(obj).every((key) => {
                    const item = obj[key];
                    if (item.check(hexValue)) {
                        if (isOnlyHex) {
                            el.properties = { hex: item.value };
                        } else {
                            el.properties = { ...el.properties, hex: item.value };
                        }
                        return false;
                    }
                    return true;
                });
            });
        }

        if (!Object.keys(selectedFields).length && !selectedFieldsHex["hex"]) {
            data.features.forEach((el) => {
                el.properties = {};
            });
        }

        dataset[title].geom = data;

        const regex = /\[\d+\.\d+,.\d+\.\d+.\]/;
        let coords = JSON.stringify(data.features[0].geometry.coordinates);
        coords = JSON.parse(coords.match(regex)[0]);

        const viewport = {
            longitude: coords[0],
            latitude: coords[1],
            zoom: 13,
        };

        let markup = (
            <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
                Dataset "{title}" was added successfully.
                <Button variant="contained" size="small" color="primary" onClick={() => setViewport(viewport)}>
                    GO TO
                </Button>
            </div>
        );

        dispatch(showSnackbar(markup, "success", 10000));
        dispatch(setCustomDataset(dataset));
        handleRemoveFile();
        setOpen(false);
    };

    return (
        <>
            <Dialog fullWidth={true} maxWidth="xs" transitionDuration={300} open={open} onClose={() => setOpen(false)}>
                <div style={{ padding: "20px 30px 0 20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>Upload file</div>
                    <Button variant="contained" color="secondary" size="small" startIcon={<LiveHelpIcon />} onClick={() => setHelpOpen(true)}>
                        Help
                    </Button>
                </div>

                <DialogContent>
                    {!file && (
                        <>
                            {isUploading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <DialogContentText>Choose your geojson file</DialogContentText>
                                    <DropzoneArea
                                        onChange={handleFileChange}
                                        filesLimit={1}
                                        dropzoneClass="dropzone_my"
                                        dropzoneText="Drag and Drop your dataset file"
                                        maxFileSize={1073741824}
                                        acceptedFiles={[".json", ".geojson"]}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <FileInfo
                        title={title}
                        setTitle={setTitle}
                        color={color}
                        setColor={setColor}
                        fields={fields}
                        selects={SELECTS}
                        file={file}
                        handleRemoveFile={handleRemoveFile}
                        data={data}
                        isConfigured={isConfigured}
                        setIsConfigured={setIsConfigured}
                        isHexagon={isHexagon}
                        setIsHexagon={setIsHexagon}
                        fromHexColor={fromHexColor}
                        setFromHexColor={setFromHexColor}
                        toHexColor={toHexColor}
                        setToHexColor={setToHexColor}
                        selectedFields={selectedFields}
                        setSelectedFields={setSelectedFields}
                        selectedFieldsHex={selectedFieldsHex}
                        setSelectedFieldsHex={setSelectedFieldsHex}
                    />

                    <ExampleFeature isConfigured={isConfigured} selectedFields={selectedFields} data={data} />
                </DialogContent>

                <DialogActions style={{ margin: "5px 15px" }}>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Close
                    </Button>
                    <Button
                        disabled={
                            !(
                                (file && !isConfigured && title.length) ||
                                (isConfigured && Object.keys(selectedFields).length === SELECTS.length && title.length)
                            )
                        }
                        variant="contained"
                        onClick={handleAddToMap}
                        color="primary"
                    >
                        Add to map
                    </Button>
                </DialogActions>
            </Dialog>
            <UploadHelpModal open={helpOpen} setOpen={setHelpOpen} />
        </>
    );
};

export default UploadModal;
