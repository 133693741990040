import Plot from "react-plotly.js";

const TotalAreaChart = (props) => {
    const { data, preview = false, mode, polygonArea, regionArea } = props;

    const firstType = data.type2[0];
    const secondType = data.type2[data.type2.length - 1];

    const modifiedData = (() => {
        if (mode === "polygon_area") {
            return data.shape_area.map((el) => (el / polygonArea) * 100);
        } else if (mode === "total_area") {
            return data.shape_area.map((el) => (el / regionArea) * 100);
        } else {
            return data.shape_area;
        }
    })();

    const modifiedXAxisTitle = (() => {
        if (mode === "polygon_area") {
            return "% of Polygon Area";
        } else if (mode === "total_area") {
            return "% of Region Area";
        } else {
            return "sqm";
        }
    })();

    const chartData = [];

    chartData.push({
        name: firstType,
        type: "bar",
        orientation: "h",
        marker: { color: "rgb(121, 145, 99)" },
        textposition: "inside",

        x: modifiedData
            .map((el, id) => {
                if (data.type2[id] === firstType) {
                    return el;
                } else {
                    return null;
                }
            })
            .filter((el) => el !== null),
        y: data.landuse
            .map((el, id) => {
                if (data.type2[id] === firstType) {
                    return el;
                } else {
                    return null;
                }
            })
            .filter((el) => el !== null),
        text: modifiedData
            .map((el, id) => {
                if (data.type2[id] === firstType && mode !== "sqm") {
                    return el.toFixed(1) + "%";
                } else if (data.type2[id] === firstType && mode === "sqm") {
                    return el.toFixed(1);
                } else {
                    return null;
                }
            })
            .filter((el) => el !== null),
    });

    chartData.push({
        name: secondType,
        type: "bar",
        orientation: "h",
        marker: { color: "rgb(80, 123, 88)" },
        textposition: "inside",

        x: modifiedData
            .map((el, id) => {
                if (data.type2[id] === secondType) {
                    return el;
                } else {
                    return null;
                }
            })
            .filter((el) => el !== null),
        y: data.landuse
            .map((el, id) => {
                if (data.type2[id] === secondType) {
                    return el;
                } else {
                    return null;
                }
            })
            .filter((el) => el !== null),
        text: modifiedData
            .map((el, id) => {
                if (data.type2[id] === secondType && mode !== "sqm") {
                    return el.toFixed(1) + "%";
                } else if (data.type2[id] === secondType && mode === "sqm") {
                    return el.toFixed(1);
                } else {
                    return null;
                }
            })
            .filter((el) => el !== null),
    });

    const layout = {
        barmode: "relative",
        xaxis: {
            showgrid: true,
            showline: true,
            linewidth: 3,
            title: modifiedXAxisTitle,
        },

        yaxis: {
            // tickangle: -70,
        },

        font: {
            size: 14,
        },

        legend: {
            orientation: "h",
            yanchor: "bottom",
            y: 1.02,
            xanchor: "right",
            x: 1,
        },

        // showlegend: false,

        width: (preview === true && 500) || 850,
        height: (preview === true && 250) || 500,
    };

    return <Plot data={chartData} layout={layout} config={{ displaylogo: false }} />;
};

export default TotalAreaChart;
