import React from "react";
import { Layer, Source } from "react-map-gl";
import { API_BASE_URL } from "../../config";

const SourceLayer = ({ id, dataUrl, styles, hide = false }) => {
    let queryStr = "";
    if (id === "gains") {
        queryStr = "?gains=true";
    } else if (id === "losses") {
        queryStr = "?losses=true";
    } else if (id === "foliageGain") {
        queryStr = "?foliageGain=true";
    } else if (id === "foliageLoss") {
        queryStr = "?foliageLoss=true";
    }

    return (
        <Source id={id} type="geojson" data={`${API_BASE_URL}/region/${dataUrl}${queryStr}`}>
            {!hide && <Layer {...styles} />}
        </Source>
    );
};

export default SourceLayer;
