import { analyticsAPI } from "../Redux/API/Api";

const AnalyticsService = {
    track: async (eventName, fields) => {
        try {
            const { login, role } = fields;
            if (!login || !role) {
                console.log("Login and role must be specified");
                return;
            }
            const data = Object.keys(fields).reduce((acc, key) => {
                if (key !== "login" && key !== "role") {
                    acc[key] = fields[key];
                }
                return acc;
            }, {});
            await analyticsAPI.trackEvent(eventName, login, role, data);
        } catch (error) {
            console.log(error);
        }
    },
    getAnalytics: async () => {
        try {
            const result = await analyticsAPI.getAnalytics();
            if (result.analytics) {
                return result.analytics;
            }
            return [];
        } catch (error) {
            console.log(error);
        }
    },
};

export default AnalyticsService;
