import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import logo from "../assets/img/logo_2.png";

import mail from "../assets/img/mail.svg";
import phone from "../assets/img/phone.svg";

const Footer = () => {
    return (
        <FooterMain>
            <FlexBlock container>
                <LeftLogo item xs={12} sm={5}>
                    <a href="https://ppdataanalytics.com/">
                        <img src={logo} alt="logo" width="180" />
                    </a>
                </LeftLogo>

                <RightLinks item xs={12} sm={5}>
                    <a href="mailto:lab@ppdataanalytics.com">
                        lab@ppdataanalytics.com
                        <img src={mail} alt="mail" width="20" />
                    </a>

                    <a href="tel:+61421759782">
                        +61 4 2175 9782
                        <img src={phone} alt="phone" width="20" />
                    </a>
                </RightLinks>
            </FlexBlock>
        </FooterMain>
    );
};

const FooterMain = styled.footer`
    margin-top: 50px;
    height: 205px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
`;

const FlexBlock = styled(Grid)`
    display: flex;
    width: 100%;
    max-width: 1260px;
    padding: 50px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
        padding: 20px;
    }
`;

const LeftLogo = styled(Grid)``;

const RightLinks = styled(Grid)`
    & a {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 20px 0;
        gap: 15px;
        @media (max-width: 582px) {
            justify-content: flex-start;
        }
    }
`;

export default Footer;
