import { SIDEBAR_RESET, SET_SHAPE, REMOVE_SHAPE, SET_SELECTED_FEATURES, SET_MANY_SHAPES, SET_VISIBLE_SHAPE } from "./types";

let initialState = {
    shapes: [],
    selectedFeatures: [],
};

const leftSidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIDEBAR_RESET:
            return initialState;
        case SET_SHAPE:
            return { ...state, shapes: [...state.shapes, action.shape] };
        case SET_MANY_SHAPES:
            return { ...state, shapes: [...action.shapes] };
        case REMOVE_SHAPE: {
            let newShapes = [...state.shapes];
            newShapes.splice(action.index, 1);
            return { ...state, shapes: newShapes };
        }
        case SET_VISIBLE_SHAPE:
            return { ...state };
        case SET_SELECTED_FEATURES:
            return { ...state, selectedFeatures: action.selectedFeatures ? action.selectedFeatures : [] };
        default:
            return state;
    }
};

export const setManyShapes = (shapes) => ({ type: SET_MANY_SHAPES, shapes });
export const setShape = (shape) => ({ type: SET_SHAPE, shape });
export const removeShape = (index) => ({ type: REMOVE_SHAPE, index });
export const setVisibleShape = (isVisible) => ({ type: SET_VISIBLE_SHAPE, isVisible });
export const setSelectFeatures = (selectedFeatures) => ({ type: SET_SELECTED_FEATURES, selectedFeatures });

export default leftSidebarReducer;
