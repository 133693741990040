import {
    FILTERS_RESET,
    SET_DIAPOZON_HEIGHT,
    SET_DIAPOZON_HEIGHT_CUSTOM,
    SET_YEARS_DIAPOZON_CUSTOM,
    SET_HEIGHT_FILTER_ZONE_CUSTOM,
    SET_YEARS_DIAPOZON,
    SET_POLYGONS_LOADED,
    SET_HEIGHT_FILTER_ZONE,
    SET_LANDUSES,
} from "./types";

let initialState = {
    polygonsLoaded: false,
    diapozonHeight: [
        [">=", "li_2017_ma", -1],
        ["<=", "li_2017_ma", 100],
    ],
    yearsDiapozon: [],
    heightFilterZone: [],
    landuses: null,

    diapozonHeightCustom: [
        [">=", "li_2017_ma", -1],
        ["<=", "li_2017_ma", 100],
    ],
    yearsDiapozonCustom: [],
    heightFilterZoneCustom: [],
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FILTERS_RESET:
            return initialState;
        case SET_POLYGONS_LOADED:
            return { ...state, polygonsLoaded: true };
        case SET_DIAPOZON_HEIGHT:
            return { ...state, diapozonHeight: action.diapozonHeight };
        case SET_YEARS_DIAPOZON:
            return { ...state, yearsDiapozon: action.yearsDiapozon };
        case SET_HEIGHT_FILTER_ZONE:
            return { ...state, heightFilterZone: action.heightFilterZone };

        case SET_DIAPOZON_HEIGHT_CUSTOM:
            return { ...state, diapozonHeightCustom: action.diapozonHeightCustom };
        case SET_YEARS_DIAPOZON_CUSTOM:
            return { ...state, yearsDiapozonCustom: action.yearsDiapozonCustom };
        case SET_HEIGHT_FILTER_ZONE_CUSTOM:
            return { ...state, heightFilterZoneCustom: action.heightFilterZoneCustom };

        case SET_LANDUSES:
            return { ...state, landuses: action.landuses };
        default:
            return state;
    }
};

export const setPolygonsLoaded = () => ({ type: SET_POLYGONS_LOADED });
export const setDiapozonHeight = (diapozonHeight) => ({ type: SET_DIAPOZON_HEIGHT, diapozonHeight });
export const setYearsDiapozon = (yearsDiapozon) => ({ type: SET_YEARS_DIAPOZON, yearsDiapozon });
export const setHeightFilterZone = (heightFilterZone) => ({ type: SET_HEIGHT_FILTER_ZONE, heightFilterZone });

export const setDiapozonHeightCustom = (diapozonHeightCustom) => ({ type: SET_DIAPOZON_HEIGHT_CUSTOM, diapozonHeightCustom });
export const setYearsDiapozonCustom = (yearsDiapozonCustom) => ({ type: SET_YEARS_DIAPOZON_CUSTOM, yearsDiapozonCustom });
export const setHeightFilterZoneCustom = (heightFilterZoneCustom) => ({ type: SET_HEIGHT_FILTER_ZONE_CUSTOM, heightFilterZoneCustom });

export const setLanduses = (landuses) => ({ type: SET_LANDUSES, landuses });

export default filterReducer;
