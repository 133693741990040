import React, { useState } from "react";
import styled from "styled-components";
import DoubleBlock from "./components/DoubleBlock";
import FeatureList from "./components/FeatureList";
import Header from "./components/Header";
import SecondHeader from "./components/SecondHeader";

import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

import compare from "./assets/img/Compare.png";
import filter from "./assets/img/Filter.png";
import report from "./assets/img/Report.png";
import track from "./assets/img/Track.png";
import Footer from "./components/Footer";
import SubscribeForm from "./components/SubscribeForm";
import YoutubeVideo from "./components/YoutubeVideo";
import "./landing.css";

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 942px) {
        padding: 0 50px;
    }
    @media (max-width: 420px) {
        padding: 0 10px;
    }
`;

const FEATURES = [
    { title: "Track", icon: track, description: "Analyse tree growth, gains and removals down to the individual tree" },
    { title: "Compare", icon: compare, description: "Draw or load custom areas of analysis" },
    { title: "Filter", icon: filter, description: "Filter tree observations by height, year and land use type" },
    { title: "Report", icon: report, description: "Create fast on demand custom reporting" },
];

const App = () => {
    const [snackbar, setSnackbar] = useState(null);

    const open = Boolean(snackbar);
    return (
        <div style={{ position: "relative" }} className="landing">
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3500} open={open} onClose={() => setSnackbar(null)}>
                {snackbar && (
                    <Alert severity={snackbar.variant || "info"} onClose={() => setSnackbar(null)}>
                        {snackbar.title}
                    </Alert>
                )}
            </Snackbar>

            <Header />

            <SecondHeader setSnackbar={setSnackbar} />
            <Wrapper>
                <DoubleBlock />
                <FeatureList items={FEATURES} />
                <SubscribeForm setSnackbar={setSnackbar} />
                <YoutubeVideo />
            </Wrapper>

            <Footer />
        </div>
    );
};

export default App;
