import _ from 'lodash';

const CommonService = {
    copyToClipboard: async (text: any) => {
        if ((window as any)?.clipboardData?.setData) {
            return (window as any)?.clipboardData?.setData?.('Text', text);
        }
        if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            const textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand('copy');
            } catch (ex) {
                console.warn('Copy to clipboard failed.', ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    },

    objectFlatten: (obj: any): any => {
        Object.keys(obj)
            ?.filter?.((key) => typeof obj[key] === `object`)
            .forEach?.((key) => {
                Object.keys(obj?.[key] ?? {})?.forEach?.((innerKey) => {
                    obj[`${key}.${innerKey}`] = obj[key][innerKey];
                });
                obj = _.omit(obj, key);
            });
        return Object.keys(obj)?.filter?.((key) => typeof obj[key] === `object`).length > 0 ? CommonService.objectFlatten(obj) : obj;
    },

    objectNested: (obj: any): any => {
        let newObj = {};
        Object.keys(obj).forEach((key) => {
            _.set(newObj, key, obj[key]);
        });
        return newObj;
    },

    fileToBase64: (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    },

    createSyntheticEvent: <T extends Element, E extends Event>(event: E): React.SyntheticEvent<T, E> => {
        let isDefaultPrevented = false;
        let isPropagationStopped = false;
        const preventDefault = () => {
            isDefaultPrevented = true;
            event.preventDefault();
        };
        const stopPropagation = () => {
            isPropagationStopped = true;
            event.stopPropagation();
        };
        return {
            nativeEvent: event,
            currentTarget: event.currentTarget as EventTarget & T,
            target: event.target as EventTarget & T,
            bubbles: event.bubbles,
            cancelable: event.cancelable,
            defaultPrevented: event.defaultPrevented,
            eventPhase: event.eventPhase,
            isTrusted: event.isTrusted,
            preventDefault,
            isDefaultPrevented: () => isDefaultPrevented,
            stopPropagation,
            isPropagationStopped: () => isPropagationStopped,
            persist: () => {
                return;
            },
            timeStamp: event.timeStamp,
            type: event.type,
        };
    },

    readImages: (callback: (urls: FileList) => void, accept: string = 'image/*,.heic') => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.type = 'file';
        input.multiple = true;
        input.accept = accept;
        input.onchange = async (e: any) => {
            callback(e.target.files as FileList);
            input.remove();
            document.body.removeChild(input);
        };
        input.click();
    },

    readImagesAsync: (accept: string = 'image/*,.heic') => {
        return new Promise((resolve: (urls: FileList) => void, reject) => {
            try {
                CommonService.readImages(resolve, accept);
            } catch (error) {
                reject('Error while reading images');
            }
        });
    },

    QSToObject: (search: string): Record<string, string> => {
        const str = (
            '{"' +
            decodeURI(search.replace(/^\?&/, '').replace(/^\?/, '')).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
            '"}'
        )
            .replace(/""/g, '')
            .replace(/:,/g, ':"",');

        try {
            const result = JSON.parse(str);
            for (const key in result) {
                result[key] = decodeURIComponent(result[key]);
            }
            return result;
        } catch (e: any) {
            console.error('Cannot parse query string:', search);
            return {};
        }
    },

    objectToQS: (obj = {}) => (_.isEmpty(obj) ? '' : '?') + new URLSearchParams(obj).toString(),

    filesArrayToFileList: (files: File[]) => {
        const dataTransfer = new DataTransfer();
        files.forEach((file) => dataTransfer.items.add(file));
        return dataTransfer.files;
    },

    getCurrentGeoLocation: () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            } else {
                reject('Geolocation is not supported by this browser.');
            }
        });
    },
};

export default CommonService;