import { Dispatch } from "redux";
import { analyticsAPI } from "./API/Api";
import { SET_ANALYTICS_DATA } from "./types";

export type TAnalyticsRow = {
    eventName: string;
    id: string;
    login: string;
    role: string;
    createdAt: string;
    data: any;
};

type TInitialState = {
    analytics: TAnalyticsRow[];
};

const initialState: TInitialState = {
    analytics: [],
};

const analyticsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_ANALYTICS_DATA:
            return { ...state, analytics: action.analytics };

        default:
            return state;
    }
};

export const setAnalyticsData = (analytics: TAnalyticsRow[]) => ({ type: SET_ANALYTICS_DATA, analytics });

export const getAnalytics = () => async (dispatch: Dispatch) => {
    try {
        const result = await analyticsAPI.getAnalytics();
        dispatch(setAnalyticsData(result.analytics));
    } catch (error) {
        console.log(error);
    }
};

export default analyticsReducer;
