import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const UploadHelpModal = ({ open, setOpen }) => {
    const geojson = {
        type: "Feature",
        properties: {
            landuse: "Open Space",
            height: 7.6443,
            year: 2020,
        },
    };

    return (
        <Dialog fullWidth={true} maxWidth="sm" transitionDuration={300} open={open} onClose={() => setOpen(false)}>
            <DialogTitle id="alert-dialog-title">File Upload Help</DialogTitle>

            <DialogContent>
                <b>Dataset requirements</b>
                <br></br>
                <ul>
                    <li>
                        Support only <b>FeatureCollection</b> geojson object
                    </li>
                    <li>Your data must be in WGS84 coordinate system</li>
                    <li>
                        Dataset size no more than <b>1 GB</b>
                    </li>
                </ul>
                <b>Filtering Datasets</b>
                <br></br>
                <span>You can filter data by:</span>
                <ul>
                    <li>
                        <b>landuse</b> - district or state (String param)
                    </li>
                    <li>
                        <b>height</b> - filtering by tree altitude (Float param in meters)
                    </li>
                    <li>
                        <b>year</b> - data in a specific year or interval (String or Number)
                    </li>
                </ul>
                <p>if you want to filter your data, you must specify the fields above, in file upload mode</p>
                <b>Example for configuring filters</b>
                <pre style={{ background: "#EAEAEA", padding: "5px 10px", overflow: "hidden" }}>{JSON.stringify(geojson, null, "   ")}</pre>
            </DialogContent>
        </Dialog>
    );
};

export default UploadHelpModal;
