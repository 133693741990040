import React from "react";
import styled from "styled-components";

const YoutubeVideo = () => {
    return (
        <Main>
            <iframe
                width="560"
                height="315"
                title="piano"
                src="https://www.youtube.com/embed/gIWfXpS3tzI?feature=oembed"
                // src="https://www.youtube.com/embed/gIWfXpS3tzI?feature=oembed" 
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </Main>
    );
};

const Main = styled.div`
    position: relative;
    margin-top: 100px;
    padding-bottom: ${(9 / 16) * 100}%;
    height: 0;
    width: 90%;
    overflow: hidden;

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 0;
        outline-width: 0;
    }
`;

export default YoutubeVideo;
