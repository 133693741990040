import { TFiltes, TPlotlyChartsData } from "./types";
import area from "@turf/area";

export function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export const parseRawCharts = (charts: any) => {
    if (!charts) {
        return { dataPie: null, lineGraphData: null, dataBar: null };
    }
    const dataPie = Object.keys(charts.landuse_pie_chart).map((item) => ({
        name: item,
        value: charts.landuse_pie_chart[item]["properties.Shape_Area"].toFixed(2),
    }));

    const dataLine = Object.keys(charts.veg_linegraph).map((item) => ({
        name: item,
        existing: charts.veg_linegraph[item]["existing_vegetaion"],
        total: charts.veg_linegraph[item]["total_vegetation"],
    }));

    const dataBar = Object.keys(charts.loss_gain_graph).map((item) => ({
        name: item,
        Gain: charts.loss_gain_graph[item]["Gain"],
        Loss: -charts.loss_gain_graph[item]["Loss"],
    }));

    let sum = 0;

    let dataBarWithNet = JSON.parse(JSON.stringify(dataBar));

    dataBarWithNet.forEach((el: any) => {
        Object.keys(el).forEach((key) => {
            if (key === "name") {
                return;
            }
        });
        sum += el["Gain"] + el["Loss"];
        el["Net"] = sum;
    });

    return {
        dataPie,
        dataLine,
        dataBar: dataBarWithNet,
    };
};

export const parsePlotlyCharts = (data: TPlotlyChartsData) => {
    if (!data || Object.keys(data).length === 0) {
        return { dataScatter: null, dataBar: null };
    }

    const status = data.status;

    const lastYear = data.last_year;

    const currentCanopy = data.current_canopy;

    const startingCanopy = data.starting_canopy;

    const contribution = data.contribution;

    const changeRatio = data.change_ratio;

    const firstYear = data.first_year;

    const summary = data.summary;

    const dataWaterfallFoliage = JSON.parse(JSON.stringify(data.waterfall_foliage_graph || {}));

    const dataProfitAndLoss = JSON.parse(JSON.stringify(data.profit_and_loss_graph || {}));

    const dataProfitAndLossTotal = JSON.parse(JSON.stringify(data.profit_and_loss_total_graph || {}));

    const dataChangeInCanopy = JSON.parse(JSON.stringify(data.change_in_canopy_graph || {}));

    const dataScatter = JSON.parse(JSON.stringify(data.scatter_graph || {}));

    const dataLossGains = JSON.parse(JSON.stringify(data.council_graph || {}));

    const dataTotalArea = JSON.parse(JSON.stringify(data.total_area_graph || {}));

    const dataVegetationRemovals = JSON.parse(JSON.stringify(data.removals_graph || {}));

    const dataTreeHeights = JSON.parse(JSON.stringify(data.tree_heights_graph || {}));

    const sortedTreeHeightsPrank = dataTreeHeights.prank.sort((a: number, b: number) => a - b);

    const sortedTreeHeightsData: {
        li_class: string[];
        shape_area: number[];
        prank: number[];
        text_area: string[];
    } = {
        li_class: [],
        shape_area: [],
        prank: [],
        text_area: [],
    };

    sortedTreeHeightsPrank.forEach((i: number, id: number) => {
        const oldId = dataTreeHeights.prank.findIndex((el: any) => el === i);

        sortedTreeHeightsData.li_class.push(dataTreeHeights.li_class[oldId]);
        sortedTreeHeightsData.shape_area.push(dataTreeHeights.shape_area[oldId]);
        sortedTreeHeightsData.text_area.push(dataTreeHeights.text_area[oldId]);
        sortedTreeHeightsData.prank.push(dataTreeHeights.prank[oldId]);
    });

    return {
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataVegetationRemovals,
        dataTreeHeights: sortedTreeHeightsData,
        dataWaterfallFoliage,
        dataProfitAndLoss,
        dataProfitAndLossTotal,
        dataChangeInCanopy,
        status,
        lastYear,
        currentCanopy,
        startingCanopy,
        contribution,
        summary,
        changeRatio,
        firstYear,
    };
};

export const getFilters = (filters: TFiltes) => {
    let diapozonHeight = filters.diapozonHeight;
    let heightFilterZone = filters.heightFilterZone;
    let yearsDiapozon = filters.yearsDiapozon;

    //Если равны инициализационным значениям то не отправлять фильтр
    let heightFilter = [diapozonHeight[0][2], diapozonHeight[1][2]];
    if (!yearsDiapozon.length) {
        //хардкод для current так как нет годов
        yearsDiapozon = `${2009} - ${2020}`;
    }

    return {
        height: heightFilter,
        zones: heightFilterZone,
        years: yearsDiapozon,
    };
};

export const getIndCsv = (data: { year: number; value: number }[], veg_id: string) => {
    if (!data?.length) {
        return [];
    }
    let csvData = [];
    csvData.push(["year", ...data.map((d) => d.year)]);
    csvData.push([`${veg_id} (sqm)`, ...data.map((d) => d.value)]);
    return csvData;
};

export const processRow = function (row: string | any[]) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"';
        }
        if (j > 0) {
            finalVal += ",";
        }
        finalVal += result;
    }
    return finalVal + "\n";
};

export const getCsvBlob = (array: string | any[]) => {
    let csvFile = "";
    for (var i = 0; i < array.length; i++) {
        csvFile += processRow(array[i]);
    }
    return new Blob([csvFile], { type: "text/csv;charset=utf-8" });
};

export const getTotalArea = (data: any[]) => {
    let totalArea = 0;
    data.forEach((item) => {
        if (item.elem.geometry.coordinates[0]) {
            totalArea += area(item.elem);
        } else {
            console.log(item.elem.geometry.coordinates[0]);
        }
    });
    return totalArea;
};
