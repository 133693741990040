import Plot from "react-plotly.js";
import { SimpleLinearRegression } from "ml-regression";
import { FC } from "react";

type TIndividualTreeChartProps = {
    data: {
        x: number[];
        y: number[];
    };
    handleSetActive?: (year: string | null) => void;
};

const IndividualTreeChart: FC<TIndividualTreeChartProps> = ({ data, handleSetActive }) => {
    const regression = new SimpleLinearRegression(
        data.x.map((el) => +el),
        data.y
    );

    const slope = regression.slope;
    const yIntercept = regression.intercept;

    const chartData = [
        {
            x: data.x,
            y: data.y,
            text: data.y.map((el) => el.toFixed(1)),
            labels: {
                x: "Year",
                y: "% of Total Area",
            },
            marker: {
                color: "#68A673",
            },
            orientation: "h",
            type: "scatter",
            line: {
                shape: "spline",
            },
            mode: "lines+markers+text",
            textposition: "auto",
            name: "canopy cover",
        },
        {
            x: [data.x[0], data.x[data.x.length - 1]],
            y: [+data.x[0] * slope + yIntercept, +data.x[data.x.length - 1] * slope + yIntercept],
            marker: {
                color: "#000",
            },
            line: {
                dash: "dash",
            },
            name: "trendline",
            trendline: "ols",
        },
    ];

    const layout = {
        annotations: {
            font: {
                size: 10
            }
        },
        yaxis: {
            tickangle: 0,
            ticks: "outside",
            tickwidth: 1,
            ticklen: 15,
            tickson: "boundaries",
            showgrid: false,
            // gridwidth: 0.5,
            gridcolor: "grey",
            range: [0, data.y[data.y.length]],
            rangemode: "tozero",
            title: {
                text: `<b>sqm</b>`,
                font: {
                    size: 14,
                },
            },
        },
        xaxis: {
            name: "123",
            tickangle: 0,
            ticks: "outside",
            tickwidth: 1,
            ticklen: 15,
            tickson: "boundaries",
            dtick: "linear",
            showgrid: true,
            gridwidth: 0.5,
            gridcolor: "grey",
            title: {
                text: "<b>Year</b>",
                font: {
                    size: 14,
                },
            },
        },
        legend: {
            orientation: "h",
            yanchor: "bottom",
            y: 1.02,
            xanchor: "right",
            x: 1,
        },
        template: "plotly_white",
        width: 800,
        height: 500,
        font: {
            size: 14,
        },
    };

    const hoverHandler = (e: Plotly.PlotHoverEvent) => {
        if (handleSetActive && typeof e.points[0]?.x === "number") {
            handleSetActive(String(Math.floor(e.points[0].x)));
        }
    };

    const unHoverHandler = () => {
        if (handleSetActive) {
            handleSetActive(null);
        }
    };

    return <Plot data={chartData as any} layout={layout as any} config={{ displaylogo: false }} onHover={hoverHandler} onUnhover={unHoverHandler} />;
};

export default IndividualTreeChart;
