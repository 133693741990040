import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import FiltersBasic from "./FiltersBasic";
import FiltersCustom from "./FiltersCustom";

const useStyles = makeStyles(() => ({
    arrow_back: {
        position: "absolute",
        right: "15px",
        top: "5px",
        zIndex: "10",
    },
    appBar: {
        width: "100%",
        height: "30px",
    },
    rootTab: {
        minWidth: "50%",
        padding: 0,
        minHeight: "30px",
    },
    rootTabs: {
        minHeight: "auto",
    },
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {value === index && (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const FiltersBlock = () => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    const customDatasets = useSelector((state) => state.trees.customDatasets);
    const isCustomDatasets = Boolean(Object.keys(customDatasets).length);

    return (
        <div className={"filters_block"} style={{ marginTop: isCustomDatasets ? "30px" : 0, background: "white" }}>
            {isCustomDatasets && (
                <AppBar color="default" classes={{ root: classes.appBar }}>
                    <Tabs
                        classes={{ root: classes.rootTabs }}
                        value={tab}
                        onChange={(e, v) => setTab(v)}
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab classes={{ root: classes.rootTab }} label="Basic" {...a11yProps(0)} />
                        <Tab classes={{ root: classes.rootTab }} label="Custom" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            )}

            <TabPanel value={tab} index={0}>
                <FiltersBasic />
            </TabPanel>

            <TabPanel value={tab} index={1}>
                <FiltersCustom data={customDatasets} />
            </TabPanel>
        </div>
    );
};

export default FiltersBlock;
