import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formToObject } from "../helpers/form-helper";
import { LoginValidate as validate } from "../helpers/validate";

const LoginForm = (props) => {
    const { onSubmit } = props;

    const isFetching = useSelector((state) => state.user.isFetching);
    const [errors, setErrors] = useState(null);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        try {
            e.preventDefault();
            const { email, password } = validate(formToObject(e));
            onSubmit(email, password);
            setErrors(null);
        } catch (error) {
            setErrors(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4 w-screen max-w-sm">
            <input name="email" value={login} onChange={(e) => setLogin(e.target.value)} label="Email" placeholder="Email" />
            {errors && errors.email && <span className="text-red-500">{errors.email}</span>}
            <input
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                placeholder="Password"
            />
            {errors && errors.password && <span className="text-red-500">{errors.password}</span>}
            <button type="submit">{isFetching ? "Loading..." : "Login"}</button>
        </form>
    );
};

export default LoginForm;
