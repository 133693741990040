// const validate = (values) => {
//     const errors = {};
//     const requiredFields = ["email", "password"];
//     requiredFields.forEach((field) => {
//         if (!values[field]) {
//             errors[field] = "Required";
//         }
//     });
//     /*if (
//         values.email &&
//         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
//     ) {
//         errors.email = 'Invalid email address'
//     }*/
//     return errors;
// };

// export default validate;

import { z } from "zod";
import CommonService from "../common-helper";

const LoginZod = z.object({
    email: z.string(),
    password: z.string().min(8),
});

const validate = (values) => {
    try {
        return LoginZod.parse(values);
    } catch (err) {
        throw CommonService.objectNested(Object.fromEntries(err.issues.map((i) => [i.path.join("."), i.message])));
    }
};
export default validate;
