import { makeStyles, withStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(() => ({
    info: {
        position: "relative",
        top: "-8px",
        color: "#999999",
        "& svg": {
            fontSize: "17px",
        },
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FFF1E5",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const InfoTooltip = ({ text, style = {} }) => {
    const classes = useStyles();

    return (
        <div className={classes.info} style={style}>
            <HtmlTooltip title={text} placement="top">
                <InfoIcon />
            </HtmlTooltip>
        </div>
    );
};

export default InfoTooltip;
