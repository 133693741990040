import Plot from "react-plotly.js";
import { SimpleLinearRegression } from "ml-regression";

const ScatterChart = (props) => {
    const { data, preview = false, mode, polygonArea, regionArea } = props;

    const modifiedYData = (() => {
        if (mode === "polygon_area") {
            return data.y.map((el) => (el / polygonArea) * 100);
        } else if (mode === "total_area") {
            return data.y.map((el) => (el / regionArea) * 100);
        } else {
            return data.y;
        }
    })();

    const modifiedYAxisTitle = (() => {
        if (mode === "polygon_area") {
            return "% of Polygon Area";
        } else if (mode === "total_area") {
            return "% of Region Area";
        } else {
            return "sqm";
        }
    })();

    const regression = new SimpleLinearRegression(data.x, modifiedYData);

    const slope = regression.slope;
    const yIntercept = regression.intercept;

    const chartData = [
        {
            x: data.x,
            y: modifiedYData,
            // text: data.y.map((el) => el.toFixed(1) + "%"),
            text: mode === "sqm" ? data.y.map((el) => el.toFixed(1)) : modifiedYData.map((el) => el.toFixed(1) + "%"),
            labels: {
                x: "Year",
                y: "% of Total Area",
            },
            marker: {
                color: "#5D69B1",
            },
            orientation: "h",
            type: "scatter",
            line: {
                dash: "dash",
            },
            mode: "lines+markers+text",
            textposition: "top right",
            name: "canopy cover",
        },
        {
            x: [data.x[0], data.x[data.x.length - 1]],
            y: [data.x[0] * slope + yIntercept, data.x[data.y.length - 1] * slope + yIntercept],
            marker: {
                color: "rgb(121, 145, 99)",
            },
            name: "trendline",
            trendline: "ols",
        },
    ];

    const layout = {
        yaxis: {
            tickangle: 0,
            ticks: "outside",
            tickwidth: 1,
            ticklen: 15,
            tickson: "boundaries",
            showgrid: false,
            // gridwidth: 0.5,
            gridcolor: "grey",
            range: [data.y[0], data.y[data.y.length]],
            title: {
                text: `<b>${modifiedYAxisTitle}</b>`,
                font: {
                    size: 14,
                },
            },
        },
        xaxis: {
            name: "123",
            tickangle: 0,
            ticks: "outside",
            tickwidth: 1,
            ticklen: 15,
            tickson: "boundaries",
            showgrid: true,
            gridwidth: 0.5,
            gridcolor: "grey",
            title: {
                text: "<b>Year</b>",
                font: {
                    size: 14,
                },
            },
        },
        legend: {
            orientation: "h",
            yanchor: "bottom",
            y: 1.02,
            xanchor: "right",
            x: 1,
        },
        template: "plotly_white",
        width: (preview === true && 500) || 850,
        height: (preview === true && 300) || 500,
        font: {
            size: 14,
        },
    };

    return <Plot data={chartData} layout={layout} config={{ displaylogo: false }} />;
};

export default ScatterChart;
