import React, { useMemo } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Slider } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDiapozonHeightCustom, setHeightFilterZoneCustom, setYearsDiapozonCustom } from "../../Redux/FilterReducer";
import Switch from "@material-ui/core/Switch";
import { setLayerVisibleCustom } from "../../Redux/TreesReducer";
import InfoTooltip from "./InfoTooltip";

const useStyles = makeStyles(() => ({
    main: {
        width: "260px",
        padding: "15px 20px",
    },
    filter: {
        position: "relative",
    },
    filter__head: {
        fontSize: 16,
        paddingBottom: "10px",
        fontWeight: "500",
        display: "flex",
    },
    filter__divider: {
        margin: "8px 0 15px",
        borderBottom: "1px solid #BBBBBB",
    },
    land__item: {
        display: "flex",
        alignItems: "center",
        "& span": {
            fontSize: "12px",
        },
    },
    land__checkbox: {
        "& svg": {
            fontSize: "19px",
            padding: 0,
        },
        "& span": {
            padding: 0,
        },
    },
    timeline_block: {
        display: "flex",
        justifyContent: "center",
    },
    timeline: {
        width: "90%",
        color: "#505050",
        "& span": {
            fontSize: "10px",
        },
    },
    timeline_label_text: {
        width: "90%",
        color: "#505050",
        "& span": {
            fontSize: "12px",
        },
    },
    height_min: {
        position: "absolute",
        left: "0",
        bottom: "2px",
        fontSize: "13px",
    },
    height_max: {
        position: "absolute",
        right: "0",
        bottom: "2px",
        fontSize: "13px",
    },
    zones: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

const LANDUSE_HELP = "Use tick boxes to filter by land use type. Classifications as supplied by Council. ";
const LENSES_HELP = `“Tree canopy” defined as 
vegetation with a max height 
of over 2 metres. Gains are 
new plantings observed during 
the study period. Losses are 
complete removals observed 
during the study period.`;
const HEIGHT_HELP = `Use sliders to define heights of 
interest. Max height extracted 
from the latest supplied aerial 
LiDAR survey`;
const YEAR_HELP = `Use sliders to define years of interest`;

const SwitchCreator = (color) =>
    withStyles({
        switchBase: {
            top: 3,
            color: "white",
            "&$checked": {
                color: color,
            },
            "&$checked + $track": {
                backgroundColor: color,
            },
        },
        checked: {},
        track: {
            marginTop: 3,
            backgroundColor: "grey",
        },
    })(Switch);

const getUniqLanduses = (data) => {
    let hash = new Map();
    Object.keys(data).forEach((key) => {
        if (!data[key].landuses) {
            return;
        }
        data[key].landuses.forEach((z) => hash.set(z, 1));
    });
    return [...hash.keys()];
};

const getUniqYears = (data) => {
    let hash = new Map();
    Object.keys(data).forEach((key) => {
        if (!data[key].years) {
            return;
        }
        data[key].years.forEach((z) => hash.set(z, 1));
    });
    let years = [...hash.keys()].sort((a, b) => a - b);
    return years.map((y, ind) => ({ label: y, value: ind }));
};

const FiltersCustom = ({ data }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [landusesCheckbox, setLandusesCheckbox] = useState({});
    const [selectedHeight, setSelectedHeight] = useState([0, 1]);
    const [selectedYear, setSelectedYear] = useState([0, 0]);

    const heightFilterZone = useSelector((state) => state.filters.heightFilterZoneCustom);
    const diapozonHeight = useSelector((state) => state.filters.diapozonHeightCustom);
    const yearsDiapozon = useSelector((state) => state.filters.yearsDiapozonCustom);

    const maxHeight = useMemo(() => {
        let max = 0;
        Object.keys(data).forEach((key) => (data[key].maxHeight > max ? (max = data[key].maxHeight) : null));
        setSelectedHeight([0, max]);
        return max;
    }, [data]);

    const uniqLanduses = useMemo(() => getUniqLanduses(data), [data]);
    const years = useMemo(() => getUniqYears(data), [data]);

    const isFiltered = useMemo(() => {
        let isFil = false;
        Object.keys(data).forEach((key) => (data[key].isFiltered ? (isFil = true) : null));
        return isFil;
    }, [data]);

    useEffect(() => {
        let mas = {};
        Object.keys(data).forEach((key) => data[key].landuses.forEach((l) => (mas[l] = false)));
        heightFilterZone.forEach((zone) => (mas[zone] = true));

        let min = diapozonHeight[0][2];
        if (min === -1) {
            min = 0;
        }

        setSelectedHeight([min, diapozonHeight[1][2]]);
        setLandusesCheckbox(mas);
    }, [data, diapozonHeight, heightFilterZone, maxHeight]);

    useEffect(() => {
        if (yearsDiapozon.length === years.length) {
            let mas = new Map();
            Object.keys(data).forEach((key) => data[key].years.forEach((l) => mas.set(l, 1)));
            setSelectedYear([0, [...mas.keys()].length - 1]);
        } else {
            let y0 = years.find((y) => y.label === yearsDiapozon[0]).value;
            let y1 = years.find((y) => y.label === yearsDiapozon[yearsDiapozon.length - 1]).value;
            setSelectedYear([y0, y1]);
        }
    }, [data, years, yearsDiapozon]);

    const handleChangeCheckbox = (event) => {
        setLandusesCheckbox({ ...landusesCheckbox, [event.target.name]: event.target.checked });
        let mas = [];
        if (event.target.checked) {
            mas.push(event.target.name);
        }
        Object.keys(landusesCheckbox).forEach((key) => {
            if (key === event.target.name && !event.target.checked) {
                return;
            } else if (Boolean(landusesCheckbox[key])) {
                mas.push(key);
            }
        });
        dispatch(setHeightFilterZoneCustom(mas));
    };

    const handleHeightChange = (e, v) => {
        let min = -1;
        if (v[0] !== 0) {
            min = v[0];
        }
        let mas = [
            [">=", "li_2017_ma", min],
            ["<", "li_2017_ma", v[1]],
        ];

        dispatch(setDiapozonHeightCustom(mas));
    };

    const handleSwitchChange = (event) => {
        dispatch(setLayerVisibleCustom(event.target.name, event.target.checked));
    };

    const getHeightSliderText = (v) => `${v}m`;

    const handleYearChange = (e, v) => {
        let mas = [];
        years.forEach((el, ind) => {
            if (ind >= v[0] && ind <= v[1]) {
                mas.push(el.label);
            }
        });
        dispatch(setYearsDiapozonCustom(mas));
    };

    return (
        <div>
            {isFiltered && (
                <div className={classes.filter}>
                    <div className={classes.filter__head}>
                        Land use
                        <InfoTooltip text={LANDUSE_HELP} />
                    </div>

                    <div className={classes.zones}>
                        {uniqLanduses.map((z) => (
                            <div className={classes.land__item} style={{ width: "fit-content" }} key={z}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={heightFilterZone.includes(z)}
                                            onChange={handleChangeCheckbox}
                                            className={classes.land__checkbox}
                                            style={{ padding: 5 }}
                                            name={z}
                                            color="primary"
                                        />
                                    }
                                    label={z}
                                />
                            </div>
                        ))}
                    </div>

                    <div className={classes.filter__divider}></div>
                </div>
            )}

            <div className={classes.filter}>
                <div className={classes.filter__head}>
                    Lenses
                    <InfoTooltip text={LENSES_HELP} />
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {Object.keys(data).map((key) => {
                        const CutomSwitch = SwitchCreator(data[key].color);
                        return (
                            <div key={key} className={classes.land__item}>
                                <CutomSwitch
                                    size="small"
                                    checked={data[key].isShow}
                                    onChange={handleSwitchChange}
                                    name={key}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                <span style={{ fontSize: 15 }}>{key}</span>
                            </div>
                        );
                    })}
                </div>

                <div className={classes.filter__divider}></div>
            </div>

            {isFiltered && (
                <div className={classes.filter}>
                    <div className={classes.filter__head}>
                        Height
                        <InfoTooltip text={HEIGHT_HELP} />
                    </div>

                    <div className={classes.height_min}>0m</div>

                    <div className={classes.timeline_block}>
                        <Slider
                            value={selectedHeight}
                            onChange={(e, v) => setSelectedHeight(v)}
                            onChangeCommitted={handleHeightChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={getHeightSliderText}
                            className={classes.timeline_label_text}
                            step={1}
                            min={0}
                            max={Math.ceil(maxHeight)}
                        />
                    </div>

                    <div className={classes.height_max}>{Math.ceil(maxHeight)}m</div>

                    <div className={classes.filter__divider}></div>
                </div>
            )}

            {isFiltered && (
                <div className={classes.filter}>
                    <div className={classes.filter__head}>
                        Year
                        <InfoTooltip text={YEAR_HELP} />
                    </div>

                    <div className={classes.timeline_block}>
                        <Slider
                            disabled={!years.length}
                            value={selectedYear}
                            onChange={(e, v) => setSelectedYear(v)}
                            onChangeCommitted={handleYearChange}
                            className={classes.timeline}
                            step={null}
                            min={0}
                            max={years.length - 1}
                            marks={years}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FiltersCustom;
