import { SESSIONS_RESET, SET_SESSIONS, SET_MY_SESSIONS, REMOVE_SESSION, UPDATE_SESSION, ADD_SESSION, SET_SESSION_FETCHING } from "./types";
import { sessionAPI } from "./API/Api";
import { showSnackbar } from "./SnackbarReducer";

let initialState = {
    sessions: [],
    mySessions: [],
    isFetching: false,
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SESSIONS_RESET:
            return initialState;
        case SET_SESSIONS:
            return { ...state, sessions: action.sessions };
        case UPDATE_SESSION: {
            let mas = state.mySessions;
            mas.find((el) => el.id === action.id).description = action.description;
            return { ...state, mySessions: mas };
        }
        case SET_MY_SESSIONS:
            return { ...state, mySessions: action.sessions };
        case REMOVE_SESSION:
            return { ...state, mySessions: state.mySessions.filter((s) => s.id !== action.id) };
        case ADD_SESSION:
            return { ...state, mySessions: [...state.mySessions, action.session] };
        case SET_SESSION_FETCHING:
            return { ...state, isFetching: action.isFetching };
        default:
            return state;
    }
};

export const setSessions = (sessions) => ({ type: SET_SESSIONS, sessions });
export const setMySessions = (sessions) => ({ type: SET_MY_SESSIONS, sessions });
export const removeSession = (id) => ({ type: REMOVE_SESSION, id });
export const createSession = (session) => ({ type: ADD_SESSION, session });
export const setSessionFetching = (isFetching) => ({ type: SET_SESSION_FETCHING, isFetching });
export const updateLocalSession = (id, description) => ({ type: UPDATE_SESSION, id, description });

export const getMySessions = (regionId) => {
    return async (dispatch) => {
        try {
            dispatch(setSessionFetching(true));
            let response = await sessionAPI.getMySessions(regionId);

            dispatch(setMySessions(response.data.data));

            dispatch(setSessionFetching(false));
        } catch (e) {
            dispatch(setSessionFetching(false));
            dispatch(showSnackbar(e.response.data.message, "error"));
        }
    };
};

export const addSession = (userId, regionId, description, indexDBHash, polygons, editor) => {
    return async (dispatch) => {
        try {
            if (!polygons.length && !editor.length) {
                throw new ReferenceError("You must to select at least 1 polygon");
            }

            dispatch(setSessionFetching(true));
            let response = await sessionAPI.create(userId, regionId, description, indexDBHash, JSON.stringify(polygons), JSON.stringify(editor));

            let date = new Date();
            dispatch(
                createSession({
                    id: response.data.data.id,
                    regionId,
                    description,
                    userId,
                    polygons,
                    indexDBHash,
                    editor,
                    createdAt: date.toISOString(),
                })
            );

            dispatch(setSessionFetching(false));
            dispatch(showSnackbar("Session saved successfully", "success"));
            return true;
        } catch (e) {
            if (e.name === "ReferenceError") {
                dispatch(showSnackbar(e.message, "error"));
            } else {
                dispatch(showSnackbar(e.response.data.message, "warning"));
            }

            dispatch(setSessionFetching(false));
            return false;
        }
    };
};

export const updateSession = (id, description) => {
    return async (dispatch) => {
        try {
            await sessionAPI.update(id, description);

            dispatch(updateLocalSession(id, description));
            dispatch(showSnackbar("Session updated successfully", "success"));
            return true;
        } catch (e) {
            if (e.name === "Error") {
                dispatch(showSnackbar(e.message, "error"));
            } else {
                dispatch(showSnackbar(e.response.data.message, "warning"));
            }
            return false;
        }
    };
};

export const deleteMySession = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setSessionFetching(true));
            await sessionAPI.delete(id);

            dispatch(removeSession(id));
            dispatch(setSessionFetching(false));
            dispatch(showSnackbar("Session deleted successfully", "success"));
        } catch (e) {
            dispatch(setSessionFetching(false));
            dispatch(showSnackbar(e.response.data.message, "error"));
        }
    };
};

export default sessionReducer;
