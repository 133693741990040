import { REGION_RESET, SET_REGIONS, SET_UPLOAD_PROGRESS, ADD_REGION, UPDATE_REGION, SET_REGION_INFO, REMOVE_REGION, SET_FILES } from "./types";
import { RegionAPI } from "./API/Api";
import { showSnackbar } from "./SnackbarReducer";
import { setFetching, setMaxHeight } from "./TreesReducer";
import { setDiapozonHeight, setLanduses, setHeightFilterZone } from "./FilterReducer";

let initialState = {
    regionInfo: null,
    regions: [],
    uploadProgress: -1,
};

const regionReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGION_RESET:
            return initialState;
        case SET_REGIONS:
            return { ...state, regions: action.regions };
        case SET_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.progress };
        case ADD_REGION:
            return { ...state, regions: [...state.regions, action.region] };
        case REMOVE_REGION:
            return { ...state, regions: state.regions.filter((r) => r.id !== action.id) };
        case SET_REGION_INFO:
            return { ...state, regionInfo: action.regionInfo };
        case UPDATE_REGION:
            return { ...state, regions: state.regions.map((r) => (r.id === action.id ? { ...r, ...action.data } : r)) };
        case SET_FILES:
            return { ...state, files: action.files };
        default:
            return state;
    }
};

export const setRegions = (regions) => ({ type: SET_REGIONS, regions });
export const updateRegionInfo = (id, data) => ({ type: UPDATE_REGION, id, data });
export const setUploadProgress = (progress) => ({ type: SET_UPLOAD_PROGRESS, progress });
export const pushRegion = (region) => ({ type: ADD_REGION, region });
export const removeRegionAction = (id) => ({ type: REMOVE_REGION, id });
export const setRegionInfo = (regionInfo) => ({ type: SET_REGION_INFO, regionInfo });
export const setFiles = (files) => ({ type: SET_FILES, files });

export const getRegionInfo = (region) => {
    return async (dispatch) => {
        try {
            let response = await RegionAPI.getRegion(region);
            let data = response.data.data;
            let landuses = data.polygons.landuses.filter((l) => l && l !== "");
            dispatch(setMaxHeight(data.polygons.maxHeight));
            dispatch(
                setDiapozonHeight([
                    [">=", "li_2017_ma", -1],
                    ["<=", "li_2017_ma", data.polygons.maxHeight],
                ])
            );
            dispatch(setLanduses(landuses));
            dispatch(setHeightFilterZone(landuses));
            dispatch(setRegionInfo(response.data.data));
            return response.data.data;
        } catch (e) {
            console.log(e);
            //dispatch(setFetching(false))
            dispatch(showSnackbar("Region not found", "error"));
            return false;
        }
    };
};

export const updateRegion = (id, data) => {
    return async (dispatch) => {
        try {
            await RegionAPI.update(id, data);
            dispatch(updateRegionInfo(id, data));
            dispatch(showSnackbar("Region was updated", "success"));
        } catch (e) {
            dispatch(showSnackbar(e.message, "error"));
        }
    };
};

export const getRegions = () => {
    return async (dispatch) => {
        try {
            dispatch(setFetching(true));
            let response = await RegionAPI.getAll();
            dispatch(setRegions(response.data.data));
            const files = (await RegionAPI.getFiles()).data.files;
            dispatch(setFiles(files));
            dispatch(setFetching(false));
        } catch (e) {
            dispatch(showSnackbar(e.message, "error"));
            dispatch(setFetching(false));
        }
    };
};

export const fillRegionDatabase = (fileName, filePath, regionId, regionTitle) => {
    return async (dispatch) => {
        try {
            dispatch(setFetching(true));
            dispatch(showSnackbar("Started updating region data"));

            const response = await RegionAPI.fillDatabase(fileName, filePath, regionId, regionTitle);
            console.log(response);

            const regionsOld = await RegionAPI.getAll();
            dispatch(setRegions(regionsOld.data.data));

            const regions = await RegionAPI.getAll();
            dispatch(setRegions(regions.data.data));
            dispatch(showSnackbar(`Successfuly updated region ${regionTitle} `, "success"));
            setFetching(false);
        } catch (e) {
            dispatch(showSnackbar(e.message, "error"));
            dispatch(setFetching(false));
        }
    };
};

export const addRegion = (title) => async (dispatch) => {
    try {
        let response = await RegionAPI.addRegion(title);
        dispatch(pushRegion(response.data.data));
        dispatch(showSnackbar("Region was added successfully", "success"));
    } catch (e) {
        dispatch(showSnackbar(e.message, "error"));
    }
};

export const removeRegion = (id) => async (dispatch) => {
    try {
        await RegionAPI.removeRegion(id);
        dispatch(removeRegionAction(id));
        dispatch(showSnackbar("Region was removed successfully", "success"));
    } catch (e) {
        dispatch(showSnackbar(e.message, "error"));
    }
};

const handleUpload = (d) => (progressEvent) => {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    d(setUploadProgress(percentCompleted));
};

//Аплоад не региона, а polygons gains losses отдельно
export const uploadFile = (id, title, file) => {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append(title, file);
            let response = await RegionAPI.upload(id, formData, handleUpload(dispatch));
            dispatch(setUploadProgress(-1));
            dispatch(showSnackbar("Success upload", "success"));
            await dispatch(getRegions());
            return response;
        } catch (e) {
            dispatch(setUploadProgress(-1));
            dispatch(showSnackbar("Upload was failed", "error"));
        }
    };
};

export const uploadRegion = (id, title, file) => {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append(title, file);
            let response = await RegionAPI.uploadRegion(id, formData, handleUpload(dispatch));
            dispatch(setUploadProgress(-1));
            dispatch(showSnackbar("Success upload", "success"));
            return response;
        } catch (e) {
            dispatch(setUploadProgress(-1));
            dispatch(showSnackbar("Upload was failed", "error"));
        }
    };
};

export const uploadTest = (title, file) => {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append(title, file);
            let response = await RegionAPI.uploadTest(formData);

            dispatch(setUploadProgress(-1));
            dispatch(showSnackbar("Success upload", "success"));
            return response;
        } catch (e) {
            dispatch(setUploadProgress(-1));
            dispatch(showSnackbar("Upload was failed", "error"));
        }
    };
};

export const downloadFile = (file) => {
    return async (dispatch) => {
        try {
            let response = await RegionAPI.download(file);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file);
            document.body.appendChild(link);
            link.click();

            return true;
        } catch (e) {
            dispatch(showSnackbar(e.message, "error"));
        }
    };
};

export default regionReducer;
