/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, MenuItem, Select, SvgIcon, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getAnalytics, TAnalyticsRow } from "../../Redux/AnalyticsReducer";
import { CSVLink } from "react-csv";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    FilterFn,
    flexRender,
    createColumnHelper,
    getExpandedRowModel,
    Row,
    SortingState,
} from "@tanstack/react-table";

import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { timeDifference } from "../../helpers/date/dateHelper";
declare module "@tanstack/table-core" {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

// const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
//     let dir = 0;

//     // Only sort by rank if the column has ranking information
//     if (rowA.columnFiltersMeta[columnId]) {
//         dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
//     }

//     // Provide an alphanumeric fallback for when the item ranks are equal
//     return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
// };

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    tr: {
        "& td:nth-child(odd)": {
            borderRight: "1px solid #E6E4E4",
            borderLeft: "1px solid #E6E4E4",
        },
        "& td:first-child": {
            borderLeft: "none",
        },
        "& td:last-child": {
            borderRight: "none",
        },
    },
    td: {
        padding: " 20px .625em .625em .625em",
        height: "60px",
        verticalAlign: "middle",
        textAlign: "center",
        boxSizing: "border-box",
        overflowX: "hidden",
        overflowY: "auto",
        width: "120px",
        fontSize: "13px",
        textOverflow: "ellipsis",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            background: "#F8F8F8",
        },
        "& tr:nth-child(even)": {
            background: "transparent",
        },
    },
    table: {},
}));

const columnHelper = createColumnHelper<TAnalyticsRow>();

const columns = [
    {
        id: "expander",
        header: () => null,
        cell: ({ row }: { row: any }) => {
            return row.getCanExpand() ? (
                <button
                    {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: "pointer" },
                    }}
                >
                    {row.getIsExpanded() ? (
                        <SvgIcon>
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                <path
                                    d="M10.03 7.47a.75.75 0 10-1.06 1.06l1.06-1.06zM12.5 11l.53.53a.75.75 0 000-1.06l-.53.53zm-3.53 2.47a.75.75 0 101.06 1.06l-1.06-1.06zm0-4.94l3 3 1.06-1.06-3-3-1.06 1.06zm3 1.94l-3 3 1.06 1.06 3-3-1.06-1.06z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </SvgIcon>
                    ) : (
                        <SvgIcon>
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                <path
                                    transform="translate(0 1)"
                                    d="M14.53 10.03a.75.75 0 10-1.06-1.06l1.06 1.06zM11 12.5l-.53.53a.75.75 0 001.06 0L11 12.5zM8.53 8.97a.75.75 0 00-1.06 1.06l1.06-1.06zm4.94 0l-3 3 1.06 1.06 3-3-1.06-1.06zm-1.94 3l-3-3-1.06 1.06 3 3 1.06-1.06z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </SvgIcon>
                    )}
                </button>
            ) : (
                "🔵"
            );
        },
    },
    columnHelper.accessor("eventName", {
        cell: (info) => info.getValue(),
        header: () => <b>Event</b>,
    }),
    columnHelper.accessor("createdAt", {
        cell: (info) => timeDifference(info.getValue()),
        header: () => <b>Time</b>,
    }),
    columnHelper.accessor("login", {
        cell: (info) => info.getValue(),
        header: () => <b>Login</b>,
    }),
    columnHelper.accessor("role", {
        cell: (info) => info.getValue(),
        header: () => <b>Role</b>,
    }),
];

const renderSubComponent = ({ row }: { row: Row<TAnalyticsRow> }) => {
    return (
        <pre style={{ fontSize: "10px", maxWidth: "50vw" }}>
            <code>{JSON.stringify(row.original.data, null, 2)}</code>
        </pre>
    );
};

const UsersAnalytics = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { user } = useTypedSelector((state) => state.user);
    const { analytics } = useTypedSelector((state) => state.analytics);

    const [csvData, setCsvData] = useState<string[][]>([[]]);

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const table = useReactTable({
        data: analytics,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: (row: Row<TAnalyticsRow>) => true,

        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
            sorting,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    const getCSVData = () => {
        const data = [["Event", "Time", "Login", "Role"]];
        table.getCoreRowModel().rows.forEach((row) => {
            data.push([row.original.eventName, row.original.createdAt, row.original.login, row.original.role]);
        });
        setCsvData(data);
    };

    useEffect(() => {
        dispatch(getAnalytics());
    }, [dispatch]);

    useEffect(() => {
        getCSVData();
    }, [analytics]);

    return (
        <Container component="main" className={classes.heroContent}>
            <div style={{ overflow: "hidden" }}>
                {user.role === 2 ? (
                    <>
                        <table>
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th key={header.id} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <>
                                                            <div
                                                                style={{
                                                                    cursor: "Pointer",
                                                                }}
                                                                {...{
                                                                    className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                                    onClick: header.column.getToggleSortingHandler(),
                                                                }}
                                                            >
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                                {{
                                                                    asc: " 🔼",
                                                                    desc: " 🔽",
                                                                }[header.column.getIsSorted() as string] ?? null}
                                                            </div>
                                                            {header.column.getCanFilter() ? (
                                                                <div>
                                                                    <Filter column={header.column} table={table} />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className={classes.tbody}>
                                {table.getRowModel().rows.map((row) => {
                                    return (
                                        <Fragment key={row.id}>
                                            <tr className={classes.tr}>
                                                {row.getVisibleCells().map((cell) => {
                                                    return (
                                                        <td className={classes.td} key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                            {row.getIsExpanded() && (
                                                <tr>
                                                    {/* 2nd row is a custom 1 cell row */}
                                                    <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                        <CSVLink data={csvData} filename={`LEAF-USERS-ANALYTICS-REPORT-${new Date().toUTCString()}.csv`}>
                            Export table CSV
                        </CSVLink>
                        <div className="h-2" />
                        <div className="flex items-center gap-2" style={{ marginTop: "50px" }}>
                            <Button className="border rounded p-1" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
                                {"<<"}
                            </Button>
                            <Button className="border rounded p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                                {"<"}
                            </Button>
                            <Button className="border rounded p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                                {">"}
                            </Button>
                            <Button
                                className="border rounded p-1"
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                {">>"}
                            </Button>
                            <span className="flex items-center gap-1">
                                <div>Current page</div>
                                <strong>
                                    {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                                </strong>
                            </span>
                            <span className="flex items-center gap-1">
                                | Go to page:
                                <input
                                    type="number"
                                    defaultValue={table.getState().pagination.pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        table.setPageIndex(page);
                                    }}
                                    className="border p-1 rounded w-16"
                                />
                            </span>
                            <Select
                                value={table.getState().pagination.pageSize}
                                onChange={(e) => {
                                    table.setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <MenuItem key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
                    </>
                ) : null}
            </div>
        </Container>
    );
};

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(
        () => (typeof firstValue === "number" ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
        [column.getFacetedUniqueValues()]
    );

    return typeof firstValue === "number" ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                    value={(columnFilterValue as [number, number])?.[0] ?? ""}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""}`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                    value={(columnFilterValue as [number, number])?.[1] ?? ""}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""}`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? "") as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                className="w-36 border shadow rounded"
                list={column.id + "list"}
            />
            <div className="h-1" />
        </>
    );
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}

export default UsersAnalytics;
