const pages = [
    {
        title: "Menu",
        key: "menu",
        id: 0,
        description: "",
    },
    {
        title: "Change in Canopy Cover",
        key: "scatter",
        id: 1,
        description: "The graph aggregates canopy cover to the corresponding year.",
    },
    {
        title: "Total Canopy Profit & Loss",
        key: "profit_and_loss_total",
        id: 2,
        description:
            "The following graph balances canopy addition to canopy subtraction, to show total net change.\nAddition consists of gains made by growth of existing canopy and new plantings.\nSubtraction consists of canopy loss due to loss of existing canopy and tree removal.",
    },
    {
        title: "Canopy Profit & Loss by Year ",
        key: "change_in_canopy_cover",
        id: 3,
        description:
            "The following graph balances canopy addition to canopy subtraction, to show yearly net change.\nAddition consists of gains made by growth of existing canopy and new plantings.\nSubtraction consists of canopy loss due to loss of existing canopy and tree removal.",
    },
    {
        title: "Canopy Profit & Loss By Landuse",
        key: "profit_and_loss",
        id: 4,
        description:
            "The following graph balances canopy addition to canopy subtraction, to show total net change by land use.\nAddition consists of gains made by growth of existing canopy and new plantings.\nSubtraction consists of canopy loss due to loss of existing canopy and tree removal.",
    },
    {
        title: "Yearly Net Canopy",
        key: "waterfall_foliage",
        id: 5,
        description:
            "The following graph balances canopy addition to canopy subtraction, to show total net change.\nAddition consists of gains made by growth of existing canopy and new plantings.\nSubtraction consists of canopy loss due to loss of existing canopy and tree removal.",
    },
    {
        title: "Plantings VS Removals",
        key: "loss_gain",
        id: 6,
        description: `Recorded planting and removal event are aggregated to the corresponding period which it occurred.<br/>The graph is intended to show the overall trend in the number of trees planted vs removed over time. It helps to understand how planting and removal events have affected the size of the urban forest present and future. <br/>A planting is detected when a tree has approximately 1 square meter of tree foliage, and will contribute to the future urban forest.`,
    },
    {
        title: "Canopy Gained due to Plantings",
        key: "total_area",
        id: 7,
        description:
            "The graphs show proportionally the total canopy gained due to plantings.<br/>The information is separated into land use categories.<br/>The graph is an indicator of the amount of change occurring in the urban forest over the study period.",
    },
    {
        title: "Canopy Lost due to Removals",
        key: "vegetation_removals",
        id: 8,
        description:
            "The graphs show proportionally the total canopy lost due to removals.<br/>The information is separated into land use categories, and is an indicator of the amount of change occurring in the urban forest over the study period.",
    },
    {
        title: "Canopy of Region by Height",
        key: "tree_height",
        id: 9,
        description: "The maximum height of a tree is used to aggregated canopy to a range of height intervals. ",
    },
    {
        title: "Land Use Type",
        key: "pie",
        id: 10,
        description: "A pie chart that shows distribution of canopy by landuse.",
    },
    {
        title: "Canopy Cover",
        key: "chart",
        id: 11,
        description:
            "A line chart where first line shows all observed vegetation that includes losses and gains and second line shows vegetation that excludes losses and gains.",
    },
];

export default pages;
