import circle from "@turf/circle";
import distance from "@turf/distance";
import { DrawCircleFromCenterMode } from "react-map-gl-draw";

export class DrawCircleFromCenterModeLabel extends DrawCircleFromCenterMode {
    getGuides(props) {
        const { lastPointerMoveEvent, modeConfig } = props;
        const clickSequence = this.getClickSequence();

        const guides = {
            type: "FeatureCollection",
            features: [],
        };

        if (clickSequence.length === 0) {
            // nothing to do yet
            return guides;
        }

        const corner1 = clickSequence[0];
        const corner2 = lastPointerMoveEvent.mapCoords;

        const polygon = this.getTwoClickPolygon(corner1, corner2, modeConfig);
        if (polygon) {
            guides.features.push({
                type: "Feature",
                properties: {
                    shape: polygon.properties && polygon.properties.shape,
                    guideType: "tentative",
                },
                geometry: polygon.geometry,
            });

            guides.features.push({
                type: "Feature",
                properties: {
                    guideType: "tentative",
                },
                geometry: {
                    type: "LineString",
                    coordinates: [corner1, corner2],
                },
            });
        }

        return guides;
    }

    getTwoClickPolygon(coord1, coord2, modeConfig) {
        // Default turf value for circle is 64
        const { steps = 64 } = modeConfig || {};
        const options = { steps };
        if (steps < 4) {
            console.warn(`Minimum steps to draw a circle is 4 `); // eslint-disable-line no-console,no-undef
            options.steps = 4;
        }

        const radius = Math.max(distance(coord1, coord2), 0.001);
        const geometry = circle(coord1, radius, options);

        if (!window.isCircle) {
            window.isCircle = true;
        }
        window.circleRadius = radius * 1000;
        window.circleSettings = {
            radius,
            long: coord1[0],
            lati: coord1[1],
        };

        geometry.properties = geometry.properties || {};
        geometry.properties.shape = "Circle";

        return geometry;
    }
}
